import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { useTranslation } from "react-i18next";
import Copyright from "../Home/HomeSections/Section5/Copyright";

const options = {
  complexity: 0.3,
  contrast: 0.6,
  guides: true,
  size: 1000,
  color: "hsl(6.13, 73.23%, 49.8%)",
  stroke: 0,
};


function PIThirdScreen() {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="PI-container" style={{ minHeight: "calc(100vh - 100px)", marginTop: "50px" }}>
        <div style={{ textAlign: "start" }}>
          <h2>
            <b>{t("pi-heading-secondpage")}®</b>
          </h2>
          <label className="form-label my-5">
            <div style={{ color: "gray" }}>{t("pi-p1-secondpage")}</div>
          </label>
        </div>

        <div>
          <p>
            <b>{t("pi-guideline-secondpage")}</b>
          </p>
          <ul>
            <li>{t("pi-guideline-li1")}</li>
            <li>{t("pi-guideline-li2")}</li>
            <li>{t("pi-guideline-li3")}</li>
            <li>{t("pi-guideline-li4")}</li>
            <li>{t("pi-guideline-li5")}</li>
          </ul>
          <div>
            <p>
              <b>{t("pi-pleaseNote")}</b> {t("pi-pleaseNote2")}
            </p>
          </div>

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Link
              className="login-form-button text-decoration-none"
              to="/PISecondScreen"
              style={{ backgroundColor: "gray" }}
            >
              {t("back-btn")}
            </Link>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Link type="submit" className="login-form-button text-decoration-none" to="/PIFourthScreen">
                {t("continue-btn")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>

  );
}

export default PIThirdScreen;
