import React from "react";
import CDS from "../../../../assets/img/onbLogoPng.png";
import Copyright from "./Copyright";
import Info from "./Info";
import { useTranslation } from "react-i18next";
import MailIcon from "@mui/icons-material/MailOutline";
import { colors } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Info />
      <div id="footer">
        <div className="bg-cds py-5 ms-md-4 ms-0">
          <div className="d-flex flex-md-row flex-column align-items-md-start align-items-center justify-content-center gap-3">
            {/* Onboardiq logo */}
            <div className=" col-md-3 col-sm-10 col-10 text-start align-items-center justify-content-center">
              <img src={CDS} className="login-form-logo navbar-onb-logo" alt="Onboardiq" />
              <p className="m-0 mt-2 col-sm-9 col-11" style={{ fontSize: "14px" }}>
               {t("footer-info-paragraph")}
              </p>
            </div>

            {/* We Offer Section */}
            <div className="mt-md-0 col-md-3 col-sm-10 col-10 flex-column ps-md-5 ps-0 ">
              <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>{t("we-offer")}</h3>
              <ul
                className="navbar-nav d-flex flex-column gap-2 fs-6"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              >
                <li className="footer-items">{t("customer-center")}</li>
                <li className="footer-items">{t("data-collection")}</li>
                <li className="footer-items">{t("back-office-support")}</li>
                <li className="footer-items">{t("brand-experience")}</li>
                <li className="footer-items">{t("traning-delivery")}</li>
              </ul>
            </div>

            {/* Contact us Section */}
            <div className="col-md-3 col-sm-10 col-10  ps-lg-5 ps-0">
              <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>{t("contact-us")}</h3>
              <ul className="navbar-nav" style={{ fontWeight: "normal", fontSize: "16px" }}>
                <li className="footer-items">
                  <i className="bi bi-telephone "></i> +(5999) 738 4986
                </li>
                <li className="footer-items mt-2">
                  <MailIcon className="mail-icon" />: info@caribglobaldataservices.com
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <Copyright />
      </div>
    </>
  );
};

export default Footer;
