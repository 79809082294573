import React from "react";
import AuthProvider from "./context/AuthContext";
import Stack from "./MainStack/Stack";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <AuthProvider>
        {/* <TourProvider steps={steps} showBadge={false} showTooltip={false} showTooltipArrow={false}> */}
          <Stack />
        {/* </TourProvider> */}
      </AuthProvider>
    </Router>
  );
}

export default App;
