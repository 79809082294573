import React from "react";
import "./graph.css";
import piegraph from "../../../assets/img/piegraph.png";
import graph from "../../../assets/img/graph.png";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const Graph = () => {
  return (
    <div id="graph">
      <div className="graph-content d-flex flex-column align-items-center ">
        <Fade top appear>
          <p className="stats-paragraph text-center mx-sm-0 mx-2 "> SOME SNAPS OF THE STATS SHOWN BY ONBOARDIQ</p>
        </Fade>
        <div className="graph-images  mt-3 d-flex flex-lg-row flex-column">
          <div className="image-wrapper col-lg-6 col-12 p-0 mt-3">
            <Zoom appear>
              <img loading="lazy" src={piegraph} alt="Pie Graph" />
            </Zoom>
          </div>
          <div className="image-wrapper col-lg-6 col-12 p-0 pb-5">
            <Zoom appear>
              <img loading="lazy" src={graph} alt="Graph" />
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
