import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import axios from "axios";
import { USER_PROFILE_URL } from "../../../Auth_API";
import Swal from "sweetalert2";
import audioRecord from "../../../assets/img/audio-record-png.png";
import audioStop from "../../../assets/img/audio-stop-png.png";
import getBlobDuration from "get-blob-duration";
import { useTranslation } from "react-i18next";
function InQsSection3() {
  let navigation = useNavigate();
  const { t } = useTranslation();
  const {
    setIsLoading,
    userData,
    setIsTimerRunning,
    timerValue,
    setTimerValue,
    steps,
    selectedInterview,
    showToast,
    setIsErrorOpen,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    setTimeSpentOnCurrentQuestion,
    questionTimers,
    interviewQuestions,
    showEndButton,
    setShowEndButton,
    showSubmitButton,
    setShowSubmitButton,
    isRecording,
    setIsRecording,
    isPaused,
    setIsPaused,
    recordedAudio,
    setRecordedAudio,
    mediaRecorderRef,
    chunksRef,
    timeSpentOnCurrentQuestion,
    recordedAudioDuration,
    setRecordedAudioDuration,
    currentQuestionId,
    toggleModal,
    setBarStatus,
    setIsRecordingClicked,
    setQuestionTimers,
    isNextClicked,
    setIsNextClicked,
  } = UserLogin();

  const { token } = userData || {};
  const [mediaStream, setMediaStream] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isScreenSmall = windowWidth <= 500;
  const [audioDuration, setAudioDuration] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  // const handleStartRecording = async () => {
  //   if (currentQuestionIndex === 0) {
  //     setIsTimerRunning(true);
  //   }
  
  //   try {
  //     Reset any previous recording state
  //     setRecordedAudio(null);
  //     setIsRecordingClicked(true);
  //     setQuestionTimers((prevTimers) => ({
  //       ...prevTimers,
  //       [currentQuestionIndex]: 0,
  //     }));
  
  //     Request microphone access
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setMediaStream(stream);
  
  //     Create a new MediaRecorder instance
  //     const mediaRecorder = new MediaRecorder(stream);
  //     mediaRecorderRef.current = mediaRecorder;
  
  //     Event listener for when audio data is available
  //     mediaRecorder.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         chunksRef.current.push(event.data);
  //       }
  //     };
  
  //     Event listener for when the recording stops
  //     mediaRecorder.onstop = async () => {
  //       const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       setRecordedAudio(audioUrl);
  //       chunksRef.current = [];
  
  //       try {
  //         const duration = await getBlobDuration(audioBlob);
  //         const roundedDuration = Math.round(duration);
  
  //         if (roundedDuration === 29 || roundedDuration === 30) {
  //           console.log(`${roundedDuration} seconds`);
  //         } else {
  //           console.log(`Duration not within expected range: ${roundedDuration} seconds`);
  //         }
  
  //         setAudioDuration(duration);
  //         setRecordedAudioDuration(duration);
  //       } catch (err) {
  //         console.error("Error calculating audio duration:", err);
  //         alert("An error occurred while calculating the audio duration. Please try again.");
  //       }
  
  //       Release the media stream when done
  //       stream.getTracks().forEach(track => track.stop());
  //     };
  
  //     Start recording
  //     mediaRecorder.start();
  //     setIsRecording(true);
  //   } catch (err) {
  //     Handle errors, such as when the user denies microphone access
  //     console.error("Error accessing media devices:", err);
  
  //     if (err.name === "NotAllowedError") {
  //       alert("Microphone access was denied. Please enable it in your browser settings and try again.");
  //     } else if (err.name === "NotFoundError") {
  //       alert("No microphone found on this device. Please connect a microphone and try again.");
  //     } else {
  //       alert(err,"An unexpected error occurred while trying to access your microphone. Please try again.");
  //     }
  //   }
  // };
  

  // previos function to start recording without error handling and alerts

  const handleStartRecording = async () => {
    if (currentQuestionIndex === 0) {
      setIsTimerRunning(true);
    }
    try {
      setRecordedAudio(null);
      setIsRecordingClicked(true);
      setQuestionTimers((prevTimers) => ({
        ...prevTimers,
        [currentQuestionIndex]: 0,
      }));
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
        setRecordedAudio(URL.createObjectURL(audioBlob));
        chunksRef.current = [];
        const duration = await getBlobDuration(audioBlob);
        const roundedDuration = Math.round(duration);
        if (roundedDuration === 29 || roundedDuration === 30) {
          console.log(roundedDuration + " seconds");
        } else {
          console.log("Duration not within expected range: " + roundedDuration + " seconds");
        }
        setAudioDuration(duration);
        setRecordedAudioDuration(duration);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };



  const handlePauseRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "paused") {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const handleStopRecording = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      setIsRecordingClicked(false);
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      setIsNextDisabled(false); // Enable the next button
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeSpentOnCurrentQuestion((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
      setTimeSpentOnCurrentQuestion(0);
    };
  }, [currentQuestionIndex]);

  const fetchBlobFromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  const handleNextQuestion = async () => {
    if (isNextDisabled) {
      return;
    }

    setIsNextClicked(true);

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
    if (mediaStream instanceof MediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    } else {
      console.error("The object obtained is not a MediaStream.");
    }
    if (
      (timeSpentOnCurrentQuestion === 30 && recordedAudioDuration === 30) ||
      (timeSpentOnCurrentQuestion > 30 && recordedAudioDuration >= 30)
    ) {
      try {
        setIsLoading(true);
        if (!recordedAudio) {
          showToast("You cannot move to next question until you answer the question below.");
          setIsErrorOpen(true);
          setIsLoading(false);
          console.error("No audio recorded.");
          return;
        } else {
          console.log("file recorded", recordedAudio);
        }

        const blob = await fetchBlobFromUrl(recordedAudio);
        const formData = new FormData();
        formData.append("audio_file", blob, "audio.wav");

        const result = await axios.post(
          `${USER_PROFILE_URL}/audio_response/${currentQuestionId}/`,
          formData,
          {
            headers: {
              "Content-Type": "audio/wav",
              Authorization: `Token ${token}`,
            },
          }
        );
        console.log("getting response", result.data);
        setIsLoading(false);
        setRecordedAudio(null);
        if (currentQuestionIndex < interviewQuestions.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          setIsNextDisabled(true);
        } else {
          console.log("Interview qs ended!");
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      toggleModal();
    }

    setTimeout(() => setIsNextClicked(false), 5000); // Reset the flag after 5 seconds
  };

  /* Function to handle end interview */
  const handleEndButton = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
    if (mediaStream instanceof MediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    } else {
      console.error("The object obtained is not a MediaStream.");
    }
    if (
      (timeSpentOnCurrentQuestion === 30 && recordedAudioDuration === 30) ||
      (timeSpentOnCurrentQuestion > 30 && recordedAudioDuration >= 30)
    ) {
      try {
        setIsLoading(true);
        if (!recordedAudio) {
          showToast("You cannot move to next question until you answer the question below.");
          setIsErrorOpen(true);
          setIsLoading(false);
          console.error("No audio recorded.");
          return;
        } else {
          console.log("file recorded", recordedAudio);
        }

        const blob = await fetchBlobFromUrl(recordedAudio);
        const formData = new FormData();
        formData.append("audio_file", blob, "audio.wav");

        const result = await axios.post(
          `${USER_PROFILE_URL}/audio_response/${currentQuestionId}/`,
          formData,
          {
            headers: {
              "Content-Type": "audio/wav",
              Authorization: `Token ${token}`,
            },
          }
        );
        console.log("getting response", result.data);
        setIsLoading(false);
        setShowEndButton(false);
        setShowSubmitButton(true);
        console.log("Interview qs ended!");
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      toggleModal();
    }
  };

  /* Function to update progress */
  const updateApplicationProgress = async () => {
    try {
      const progressResponse = await fetch(`${USER_PROFILE_URL}/application-progress/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          progress: 2,
        }),
      });

      if (progressResponse.ok) {
        const progressData = await progressResponse.json();
        console.log("Progress data:", progressData);
        setBarStatus(progressData.progress);
      } else {
        console.error("Failed to update application progress:", progressResponse.statusText);
      }
    } catch (error) {
      console.error("Error updating application progress:", error);
    }
  };

  /* Function to handle submit interview */
  const handleSubmit = async (msg) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${USER_PROFILE_URL}/submit_test/${selectedInterview}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setIsLoading(false);

      console.log("POST request successful", response);
      if (msg === "timeup") {
        Swal.fire({
          icon: "success",
          title: t("timeup"),
          text: t("test-submitted-msg"),
        });
      } else {
        Swal.fire({
          icon: "success",
          title: t("test-submitted"),
          text: t("test-submitted-msg"),
        });
      }
      setTimerValue(0);
      setIsTimerRunning(false);
      setIsRecording(false);
      navigation("/Main");
      await updateApplicationProgress();
    } catch (error) {
      console.error("Error making POST request:", error);
      showToast("Error submit the test");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (timerValue >= 3600) {
      handleSubmit("timeup");
    }
  });

  return (
    <>
      <div className="interview-qs-audio-buttons mb-4">
        {isRecording ? (
          <div className="d-flex gap-1 align-items-center">
            {isPaused ? (
              <>
                {/* Resume Button */}
                {isScreenSmall ? (
                  <button
                    className="login-form-button shadow-none mx-1"
                    onClick={handleResumeRecording}
                  >
                    <i className="fa fa-play" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="login-form-button shadow-none mx-2"
                    onClick={handleResumeRecording}
                  >
                    <i className="fa fa-play mx-1" aria-hidden="true"></i>
                    {t("resume")}
                  </button>
                )}
              </>
            ) : (
              <>
                {/* Pause button */}
                {isScreenSmall ? (
                  <button
                    className="login-form-button shadow-none mx-2"
                    onClick={handlePauseRecording}
                  >
                    <i className="fa fa-pause-circle" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="login-form-button shadow-none mx-2"
                    onClick={handlePauseRecording}
                  >
                    <i className="fa fa-pause-circle mx-1" aria-hidden="true"></i>
                    {t("pause")}
                    
                  </button>
                )}
              </>
            )}
            {/* Stop Button */}
            {isScreenSmall ? (
              <button className="login-form-button shadow-none mx-1" onClick={handleStopRecording}>
                <i className="fa fa-stop mx-1" aria-hidden="true"></i>
              </button>
            ) : (
              <button className="login-form-button shadow-none mx-2" onClick={handleStopRecording}>
                <img src={audioStop} alt="" width={20}/> {t("stop")}
              </button>
            )}
          </div>
        ) : (
          <button className="login-form-button tour-step-one shadow-none mx-2 d-flex align-items-center gap-2" onClick={handleStartRecording}>
            <img src={audioRecord} alt="" width={20}/>
            {t("record")}
          </button>
        )}

        {/* Next and Submit Button */}
        {interviewQuestions && currentQuestionIndex < interviewQuestions.length - 1 ? (
          <button
            className={`login-form-button shadow-none mx-2 ${isNextDisabled ? "disabled" : ""}`}
            onClick={handleNextQuestion}
            disabled={isNextDisabled}
          >
            {t("btn-next")}
            <i className="fa fa-angle-double-right mx-1" aria-hidden="true"></i>
          </button>
        ) : (
          <>
            {showEndButton && (
              <button className="login-form-button shadow-none" onClick={handleEndButton}>
                <span>{t("end")}</span>
              </button>
            )}
            {showSubmitButton && (
              <button
                className="login-form-button shadow-none "
                onClick={handleSubmit}
              >
                {isScreenSmall ? (
                  <i className="fa fa-location-arrow mx-1" aria-hidden="true"></i>
                ) : (
                  <>
                    <span>{t("btn-submit")}</span>
                    <i className="fa fa-location-arrow mx-1" aria-hidden="true"></i>
                  </>
                )}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default InQsSection3;
