import React, { useState } from "react";
import { useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { AUTH_API_URL } from "../../../Auth_API";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";
import { Link } from "react-router-dom";

function FP_Screen1() {
  let navigation = useNavigate();
  const { isLoading, setIsLoading, error, showToast, setIsErrorOpen, updateError, setError } =
    UserLogin();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const { email } = credentials;

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const isValidEmail = (value) => {
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regx.test(value);
  };

  /* Function to reset password */
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!isValidEmail(email)) {
        return updateError("Enter a valid email!", setError);
      }
      setIsLoading(true);
      const response = await fetch(`${AUTH_API_URL}/request_forget_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        showToast("Password reset OTP sent at your mail!");
        showToast("If you did not recieve an OTP, please check your spam!");
        navigation("/forget_otp_password", {
          state: { forget_pass_email: email },
          replace: true,
        });
        setIsLoading(false);
        console.log("OTP message:", data);
      } else {
        const data = await response.json();
        if (response.status === 403 && data.needs_verification) {
          showToast("You are not a verified user. Please verify your account first!");
          setIsErrorOpen(true);
        } else {
          console.error("OTP not received!", data.message);
          showToast("Password reset OTP not sent!");
          setIsErrorOpen(true);
        }
      }
    } catch (error) {
      console.error("Password reset OTP not sent!", error);
      showToast("Password reset OTP not sent!");
      setIsErrorOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-center">
              <div className="col-9 ps-sm-4 ps-0">
                <img
                  src={onbLogo}
                  alt="logo"
                  className="login-form-logo"
                />
              </div>
              <div className="login-form-container col-sm-9 col-11 mt-1 shadow-none">
                <div className="login-form-body">
                  <div className="d-flex align-items-center mb-2">
                    <ArrowBack onClick={() => navigation("/")} style={{cursor:"pointer"}}/>
                    <p className="m-0 p-0" onClick={() => navigation("/")} style={{cursor:"pointer",color:"grey"}}> Back to log in</p>
                  </div>
                  <h4 className="fw-bold text-capitalize mt-4">Reset your password</h4>
                  <p className="m-0 p-0 text-secondary">
                    Please enter your email address to search or your account
                  </p>
                  <hr />
                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Email Address (Required)</label>
                    <input
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={onChange}
                      margin="normal"
                      required
                      autoComplete="off"
                      type="text"
                      className="col-12"
                    />
                    {error && !isValidEmail(email) && (
                      <span className="error-fields text-danger ms-2">Enter a valid email!</span>
                    )}
                  </div>

                  <button
                     onClick={handleForgetPassword}
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    disabled={isLoading}
                  >
                    Next
                  </button>
                  <p className="m-0 mt-3 p-0 d-flex gap-2 text-secondary">
                  Don't have an account please
                  <span>
                    <Link
                      // onClick={() => setIsLoginScreen(false)}
                      to="/register"
                      className="text-decoration-underline"
                      style={{ color: "#56367f", fontWeight: "600" }}
                    >
                      Sign up
                    </Link>
                  </span>
                </p>
                </div>
              </div>
            </div>
          </div>
          <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
            <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={loginStatsImg} alt="stats" className="login-stats" />
          </div>
        </div>
      </div>

    </>
  );
}

export default FP_Screen1;
