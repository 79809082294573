import React, { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { USER_PROFILE_URL } from "../../../Auth_API";
import WorkExperienceTable from "./WorkExperienceTable";
import { useNavigate } from "react-router";
import Progress from "../../Loader/Progress";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import SwalQs from '../../../assets/img/swalQs.png'
import axios from "axios";
import SwalSuccess from '../../../assets/img/swalSuccess.png'

function WorkExperience() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [workExperienceCount, setWorkExperienceCount] = useState(1);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  // Function to toggle the currentlyWorking state
  const handleCurrentlyWorkingToggle = () => {
    setCurrentlyWorking(!currentlyWorking);
  };

  const {
    userData,
    cvCredentials: {
      company_name,
      job_title,
      employment_type,
      work_method,
      work_start_date,
      work_end_date,
      description,
    },
    onChangeCVCredentials,
    fieldValidations,
    setFieldValidations,
    workData,
    setWorkData,
    employmentTypeOptions,
    workMethodOptions,
    isLoading,
    setIsLoading,
    isValidDate,
    fileSelected,
    showToast,
    setIsErrorOpen,
    setActiveCV,
    setBarStatus,
    setCVCredentials,
    setShowWorkExperienceForm,
    setShowEducationForm,
    handleEnterKeyPress,
    cvSkimWorkData,
  } = UserLogin();

  const { id, token } = userData || {};
  const [selectedStartDate, setSelectedStartDate] = useState("");

  const handleStartDateChange = (e) => {
    onChangeCVCredentials(e);
    setSelectedStartDate(e.target.value);
  };

  const handleAddWorkExperience = async (e) => {
    e.preventDefault();

    // Check if work_end_date is empty and set it to "in progress" if so
    const updatedWorkEndDate = currentlyWorking ? "in progress" : work_end_date;

    const newFieldValidations = {
      ...fieldValidations,
      company_name: !(
        !company_name ||
        !/^[a-zA-Z\s.()-]+$/.test(company_name.trim()) ||
        company_name.length < 3
      ),
      job_title: !(
        !job_title ||
        !/^[a-zA-Z\s.,()\-]+$/.test(job_title.trim()) ||
        job_title.length < 3
      ),
      employment_type: employmentTypeOptions.some((option) => option.value === employment_type),
      work_method: workMethodOptions.some((option) => option.value === work_method),
      work_start_date: isValidDate(work_start_date),
      work_end_date: updatedWorkEndDate,
      description:
        description && description.trim().length > 0
          ? /^(?=.*[a-zA-Z])[a-zA-Z0-9\s.,!@#$^&*()-_+=<>?:"{}|[\]\\/]+$/g.test(
              description.trim()
            ) && description.trim().length >= 5
          : true, // Validate only if description is provided
    };

    const isFormValid = Object.values(newFieldValidations).every((valid) => valid);

    if (!isFormValid) {
      setFieldValidations(newFieldValidations);
      setIsLoading(false);
      console.log("not valid work form");
      console.log("field validations:", fieldValidations);
      console.log("new field validations:", newFieldValidations);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${USER_PROFILE_URL}/work-experience/`, {
        company_name,
        job_title,
        employment_type,
        work_method,
        work_start_date,
        work_end_date: updatedWorkEndDate === "in progress" ? null : updatedWorkEndDate, // Send updated work_end_date value
        description,
        user: id,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        console.log("Request succeeded!");
        setIsLoading(false);
        setSelectedStartDate("");
        setCurrentlyWorking(false);
        setFieldValidations(newFieldValidations);
        setCVCredentials({
          company_name: "",
          job_title: "",
          employment_type: "",
          work_method: "",
          work_start_date: "",
          work_end_date: "",
          description: "",
        });
  
        // Fetch updated data after successful submission
        const updatedDataResponse = await axios.get(`${USER_PROFILE_URL}/work-experience/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
  
        if (updatedDataResponse.status === 200) {
          const updatedData = updatedDataResponse.data;
          setWorkData(updatedData);
          console.log("Updated data:", updatedData);
        } else {
          console.error("Failed to fetch updated data:", updatedDataResponse.statusText);
        }
      }
    } catch (error) {
      console.error("Error making request:", error);
      setIsErrorOpen(true);
      showToast(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cvSkimWorkData) {
      const datePatternRegex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (datePatternRegex.test(cvSkimWorkData.work_start_date)) {
        setCVCredentials({
          work_start_date: new Date(cvSkimWorkData.work_start_date),
        });
      } else {
        setCVCredentials({
          work_start_date: "",
        });
      }
      if (datePatternRegex.test(cvSkimWorkData.work_end_date)) {
        setCVCredentials({
          work_end_date: new Date(cvSkimWorkData.work_end_date),
        });
      } else {
        setCVCredentials({
          work_end_date: "",
        });
      }
      const cleanedCompanyName = cvSkimWorkData.company_name
        ? cvSkimWorkData.company_name.replace(/[^\w\s]/gi, "")
        : "";
      const matchingOption = workMethodOptions.find(
        (option) => option.value === cvSkimWorkData.work_method
      );

      setCVCredentials({
        company_name: cleanedCompanyName,
        job_title: cvSkimWorkData.job_title ? cvSkimWorkData.job_title : "",
        employment_type: cvSkimWorkData.employment_type ? cvSkimWorkData.employment_type : "",
        work_method: matchingOption ? matchingOption.value : "",
        description: cvSkimWorkData.description ? cvSkimWorkData.description : "",
      });
    }
  }, [token, cvSkimWorkData]);

  /* Function to update progress */
  const updateApplicationProgress = async () => {
    try {
      const progressResponse = await fetch(`${USER_PROFILE_URL}/application-progress/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          progress: 1,
        }),
      });

      if (progressResponse.ok) {
        const progressData = await progressResponse.json();
        setBarStatus(progressData.progress);
        console.log("Progress data:", progressData.progress);
      } else {
        console.error("Failed to update application progress:", progressResponse.statusText);
      }
    } catch (error) {
      console.error("Error updating application progress:", error);
    }
  };

  /* Function to handle without auth work info */
  const handleWorkSubmit = async (e) => {
    e.preventDefault();

    if (!fileSelected) {
      console.error("No CV file selected");
      Swal.fire({
        icon: "error",
        title: "CV not uploaded",
        text: "Please upload your CV then try again!",
      });
      return;
    }

    const confirmationResult = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to submit your personal information. Do you want to submit?",
      iconHtml: `<img src="${SwalQs}" style="width: auto; height: 100px;" />`,
      customClass: {
        icon: 'swal-icon'
      },
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#43ADDB",
      confirmButtonText: "Yes submit it",
      cancelButtonText: "No, cancel",
    });

    if (confirmationResult.isConfirmed) {
      // showToast("You cannot edit this info before 7 days!");
      Swal.fire({
        iconHtml: `<img src="${SwalSuccess}" style="width: auto; height: 120px;" />`,
        customClass: {
          icon: 'swal-icon'
        },
        title: "Step 1 Complete",
        text: "Information has been stored.",
        confirmButtonText: "OK",
      });
      window.scrollTo(0, 0);
      setActiveCV(3);
      await updateApplicationProgress();
      navigate("/Main");
    }
  };

  /* Function to move back */
  const handleMoveBack = (e) => {
    e.preventDefault();
    setShowEducationForm(true);
    setShowWorkExperienceForm(false);
  };

  return (
    <>
      <WorkExperienceTable />

      <form className="my-5" id="education">
        <div className="row" style={{ marginTop: "5%" }}>
          <h4>
            <b>{t("work-heading")}</b>
          </h4>

          {Array.from({ length: workExperienceCount }).map((_, index) => (
            <>
              <div className="col-md-6">
                <label htmlFor={`company_name${index}`} className="form-label">
                  {t("work-company")}
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Innovative Tech Solutions"
                    name="company_name"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius:"5px"
                    }}
                    value={company_name || ""}
                    onChange={onChangeCVCredentials}
                    required
                    id="company_name"
                    variant="outlined"
                    fullWidth
                    error={!fieldValidations.company_name}
                    helperText={!fieldValidations.company_name && "Enter valid company name!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "job_title")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`job_title`} className="form-label">
                  {t("work-job")}
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Web Developer"
                    id="job_title"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius:"5px"
                    }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="job_title"
                    htmlFor="job_title"
                    fullWidth
                    value={job_title || ""}
                    onChange={onChangeCVCredentials}
                    error={!fieldValidations.job_title}
                    helperText={!fieldValidations.job_title && "Enter valid job title!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "employment_type")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor={`employment_type`} className="form-label">
                  {t("work-employment")}
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Web Developer"
                    name="employment_type"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius:"5px"
                    }}
                    value={employment_type || ""}
                    onChange={onChangeCVCredentials}
                    required
                    id="employment_type"
                    htmlFor={`employment_type`}
                    variant="outlined"
                    fullWidth
                    select
                    error={!fieldValidations.employment_type}
                    helperText={!fieldValidations.employment_type && "Select employment type!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "work_method")}
                  >
                    {employmentTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`work_method`} className="form-label">
                  {t("work-method")}
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Remote"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius:"5px"
                    }}
                    id="work_method"
                    select
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="work_method"
                    fullWidth
                    htmlFor={`work_method`}
                    value={work_method || ""}
                    onChange={onChangeCVCredentials}
                    error={!fieldValidations.work_method}
                    helperText={
                      !fieldValidations.work_method &&
                      // !work_method.trim() &&
                      "Select work method!"
                    }
                    onKeyDown={(event) => handleEnterKeyPress(event, "work_start_date")}
                  >
                    {workMethodOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor={`work_start_date`} className="form-label">
                  {t("education-startDate")}
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. BS"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                      "& input::-webkit-calendar-picker-indicator": {
                        filter: "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                      },
                      "& input[type='date']::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type='date']::-webkit-clear-button": {
                        display: "none",
                      }
                    }}
                    name="work_start_date"
                    value={work_start_date || ""}
                    onChange={handleStartDateChange}
                    id="work_start_date"
                    htmlFor={`work_start_date`}
                    variant="outlined"
                    fullWidth
                    type="date"
                    error={!fieldValidations.work_start_date}
                    helperText={!fieldValidations.work_start_date && "Enter start date!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "work_end_date")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`work_end_date`} className="form-label">
                  {t("education-endDate")}
                </label>
                <div className="mb-3">
                  <TextField
                    name="work_end_date"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                      "& input::-webkit-calendar-picker-indicator": {
                        filter: "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                      },
                      "& input[type='date']::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type='date']::-webkit-clear-button": {
                        display: "none",
                      }
                    }}
                    value={work_end_date || ""}
                    onChange={onChangeCVCredentials}
                    id="work_end_date"
                    htmlFor={`work_end_date`}
                    variant="outlined"
                    fullWidth
                    type="date"
                    error={!fieldValidations.work_end_date}
                    helperText={
                      !fieldValidations.work_end_date &&
                      // !work_end_date?.trim() &&
                      "Enter end date!"
                    }
                    onKeyDown={(event) => handleEnterKeyPress(event, "description")}
                    disabled={!selectedStartDate || currentlyWorking}
                    inputProps={{
                      min: selectedStartDate,
                    }}
                  />

                  <label className="d-flex gap-2 mx-2">
                    <input
                      type="checkbox"
                      checked={currentlyWorking}
                      onChange={handleCurrentlyWorkingToggle}
                    />
                  {t("currently-working")}
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor={`description`} className="form-label">
                  {t("education-jobDesc")}
                </label>
                <div className="mb-3">
                  <TextField
                    type="text"
                    name="description"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius:"5px"
                    }}
                    value={description || ""}
                    onChange={onChangeCVCredentials}
                    id="description"
                    htmlFor={`description`}
                    variant="outlined"
                    fullWidth
                    error={!fieldValidations.description}
                    helperText={
                      !fieldValidations.description &&
                      // !job_title.trim() &&
                      "Enter a valid and meaningful description!"
                    }

                    // onKeyDown={(event) =>
                    //   handleEnterKeyPress(event, hasEducationData ? "nextButton" : "addButton")
                    // }
                  />
                </div>
              </div>
            </>
          ))}

          <hr />

          <div className="d-flex align-items-md-center gap-2 align-items-start justify-content-between mt-2 flex-md-row flex-column ">
            <button
              type="submit"
              // variant="outlined"
              className="login-form-button"
              onClick={handleMoveBack}
            >
              {t("back-btn")}
            </button>

            <div className="d-flex align-items-sm-center align-items-start flex-sm-row flex-column gap-2">
              <button
                type="submit"
                id="addButton"
                className="login-form-button"
                onClick={handleAddWorkExperience}
              >
                {t("btn-add")}
              </button>

              <button
                onClick={handleWorkSubmit}
                type="submit"
                id="nextButton"
                variant="outlined"
                disabled={!workData.length}
                className={`login-form-button ${!workData.length ? "disabled" : ""}`}
              >
               {t("btn-submit")}
              </button>
            </div>
          </div>
        </div>
      </form>
      {isLoading ? <Progress /> : null}
    </>
  );
}

export default WorkExperience;
