import React, { useEffect, useState } from "react";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import { UserLogin } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import onbLogo from "../../assets/img/onbLogoPng.png";
import { AUTH_API_URL, USER_PROFILE_URL } from "../../Auth_API";
import { useTranslation } from "react-i18next";

function InterviewOrgTable() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { userData, showToast } = UserLogin();

  const { token } = userData || {};

  useEffect(() => {
    fetch(`${USER_PROFILE_URL}/available-organizations-list`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setOrganizations(data);
        } else {
          console.error("Data received is not an array:", data);
          setOrganizations([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
        setOrganizations([]);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAdd = (orgId) => {
    fetch(`${AUTH_API_URL}/append-org-user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        org_id: orgId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Organization added successfully:", data);
        showToast("Organization has been added. You can now give test to this organization.");
        navigate("/InterviewHome");
      })
      .catch((error) => {
        console.error("Error adding organization:", error);
      });
  };

  return (
    <div className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
      <Navbar />
      <div className="interview-home-container my-4">
        <div className="interview-home-card card" style={{ padding: "10px 40px" }}>
          <div className="card-body">
            <div className=" org-table-head gap-2 d-flex flex-column align-items-center">
              <div style={{ flexGrow: 0, flexShrink: 0 }}>
                <img src={onbLogo} alt="" style={{ width: "200px" }} />
              </div>
              <h2 className="mb-2">{t("list-of-organizations")}</h2>
            </div>

            <TableContainer component={Paper} style={{}}>
              <Table aria-label="custom pagination table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#56367f" }}>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>#</strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>
                        {t("organization-name")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>
                        {t("personal-city")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>
                        {t("personal-state")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>
                        {t("contact-country")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>Website</strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>
                        {t("personal-phone")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontWeight: "700", color: "white" }}>{t("action")}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? organizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : organizations
                  ).map((org, index) => (
                    <TableRow key={org.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{org.name || "N/A"}</TableCell>
                      <TableCell>{org.city || "N/A"}</TableCell>
                      <TableCell>{org.state || "N/A"}</TableCell>
                      <TableCell>{org.country || "N/A"}</TableCell>
                      <TableCell>{org.website || "N/A"}</TableCell>
                      <TableCell>{org.phone_number || "N/A"}</TableCell>
                      <TableCell>
                        <button
                          className="interivew-screen-button btn"
                          style={{ borderRadius: "7px", background: "#50B482" }}
                          onClick={() => handleClickAdd(org.id)}
                        >
                          Enroll
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All" }]}
                  colSpan={8}
                  count={organizations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  slotProps={{
                    select: {
                      classes: {
                        select: {
                          // Add custom classes for styling (e.g., background color)
                          backgroundColor: "lightblue",
                        },
                        icon: {
                          // Optionally style the select icon
                          color: "red",
                        },
                      },
                    },
                  }}
                />
              </Table>
            </TableContainer>

            <div className="mt-4 d-flex flex-column align-items-center justify-content-center gap-2">
              <button
                className="login-form-button shadow-0"
                onClick={() => navigate("/InterviewHome")}
              >
                {t("btn-next")}
              </button>
              <small>{t("already-enrolled")}</small>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
}

export default InterviewOrgTable;
