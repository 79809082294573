import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { UserLogin } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Copyright from "../Home/HomeSections/Section5/Copyright";

const options = {
  complexity: 0.3,
  contrast: 0.6,
  guides: true,
  size: 1000,
  color: "hsl(6.13, 73.23%, 49.8%)",
  stroke: 0,
};

function PISecondScreen() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [radio11Error, setRadio11Error] = useState(false);
  const [radio12Error, setRadio12Error] = useState(false);
  const [radio13Error, setRadio13Error] = useState(false);
  const {
    age,
    gender,
    race,
    education,
    radio11,
    radio12,
    radio13,
    setAge,
    setGender,
    setRace,
    setEducation,
    setRadio11,
    setRadio12,
    setRadio13,
    educationalLevelOptions,
    selectedAge,
    selectedRace,
    handleEnterKeyPress,
  } = UserLogin();

  const onChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "age":
        setAge(value);
        break;
      case "gender":
        setGender(value);
        break;
      case "race":
        setRace(value);
        break;
      case "education":
        setEducation(value);
        break;
      case "radio11":
        setRadio11(value);
        break;
      case "radio12":
        setRadio12(value);
        break;
      case "radio13":
        setRadio13(value);

        break;
      default:
        break;
    }
  };

  /* Function to validate fields */
  const handleContinueClick = async () => {
    setSubmitted(true);
    let hasError = false;

    if (!age) {
      hasError = true;
    }
    if (!gender) {
      setGenderError(true);
      hasError = true;
    }
    if (!race) {
      hasError = true;
    }
    if (!education) {
      hasError = true;
    }
    if (!radio11) {
      setRadio11Error(true);
      hasError = true;
    }
    if (!radio12) {
      setRadio12Error(true);
      hasError = true;
    }
    if (!radio13) {
      setRadio13Error(true);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    try {
      console.log("Navigating to the next screen...");
      setTimeout(() => {
        navigate("/PIThirdScreen");
      }, 100);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="pi-top-main"
        style={{ textAlign: "center", background: "#56367f", padding: "50px " }}
      >
        <h1 style={{ color: "white" }}>
          <b>{t("pi-heading")}</b>
        </h1>
        <label for="exampleInputEmail1" className="form-label">
          <div className="form-text" style={{ color: "white" }}>
            {t("pi-p1")}
            <br />
            {t("pi-p2")}
          </div>
        </label>
      </div>

      <div className="PI-container pb-5">
        <form>
          {/* Age */}
          <div className="mb-3 mt-sm-2 mt-4">
            <label for="exampleInputEmail1" className="form-label">
              <h8>
                <b>{t("pi-age")} *</b>
              </h8>
            </label>
            <TextField
              name="age"
              value={age}
              onChange={onChange}
              variant="outlined"
              className="md-8 square-field"
              select
              margin="normal"
              sx={{
                "& fieldset": { border: "none" },
                background: "#F0F3F6",
                borderRadius: "5px",
                width: "100%",
              }}
              required
              error={submitted && !age}
              helperText={submitted && !age ? "Age is required" : ""}
              onKeyDown={(event) => handleEnterKeyPress(event, "race")}
            >
              {selectedAge.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Gender */}
          <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label">
              <h8>
                <b>{t("pi-gender")} *</b>
              </h8>
            </label>
            <div className="card-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={gender === "male"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  {t("pi-gender-male")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  checked={gender === "female"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  {t("pi-gender-female")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="other"
                  value="other"
                  checked={gender === "other"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  {t("pi-gender-other")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="not_disclosed"
                  value="not_disclosed"
                  checked={gender === "not_disclosed"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  {t("pi-gender-donot-disclose")}
                </label>
              </div>
            </div>

            {submitted && genderError && <p className="text-danger">{t("pi-gender-heading")}</p>}
          </div>

          {/* Race */}
          <div className="mb-3 mt-4">
            <label for="exampleInputEmail1" className="form-label">
              <h8>
                <b>{t("pi-race-heading")}</b>
              </h8>
            </label>
            <TextField
              name="race"
              id="race"
              value={race}
              onChange={onChange}
              variant="outlined"
              select
              margin="normal"
              sx={{
                "& fieldset": { border: "none" },
                background: "#F0F3F6",
                borderRadius: "5px",
                width: "100%",
              }}
              required
              error={submitted && !race}
              helperText={submitted && !race ? "Please select your race!" : ""}
              onKeyDown={(event) => handleEnterKeyPress(event, "education")}
            >
              {selectedRace.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Education */}
          <div className="mb-3 mt-3">
            <label for="exampleInputEmail1" className="form-label">
              <h8>
                <b>{t("pi-education-heading")} *</b>
              </h8>
            </label>
            <div className="mb-2">
              <TextField
                name="education"
                value={education}
                onChange={onChange}
                placeholder="Select Educational Level"
                id={`education`}
                select
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                  width: "100%",
                }}
                error={submitted && !education}
                helperText={submitted && !education ? "Please select your educational level!" : ""}
              >
                {educationalLevelOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          {/* I appreciate when my manager provides feedback that is… * */}
          <div className="mb-3 mt-4 card">
            <label for="exampleInputEmail1" className="card-header text-white">
              <h8>
                <b>{t("pi-radio1-heading")} *</b>
              </h8>
            </label>
            <div className="card-body ">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="data_no_opinion"
                  value="data_no_opinion"
                  checked={radio11 === "data_no_opinion"}
                  onChange={onChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault4">
                  {t("pi-radio1-label")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="data_some_opinion"
                  value="data_some_opinion"
                  checked={radio11 === "data_some_opinion"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault5">
                  {t("pi-radio1-label2")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="equal"
                  value="equal"
                  checked={radio11 === "equal"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault6">
                  {t("pi-radio1-label3")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="opinion_some_data"
                  value="opinion_some_data"
                  checked={radio11 === "opinion_some_data"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault7">
                  {t("pi-radio1-label4")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="opinion_no_data"
                  value="opinion_no_data"
                  checked={radio11 === "opinion_no_data"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault8">
                  {t("pi-radio1-label5")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio11"
                  id="not_disclosed"
                  value="not_disclosed"
                  checked={radio11 === "not_disclosed"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault9">
                  {t("pi-radio1-label6")}
                </label>
              </div>
            </div>

            {submitted && radio11Error && <p className="text-danger">{t("pi-radio1-error")}</p>}
          </div>

          {/* Your company is considering a big change in strategic direction… * */}
          <div className="mb-3 mt-3 card">
            <label for="exampleInputEmail1" className="card-header text-white">
              <h8>
                <b>{t("pi-desc")} *</b>
              </h8>
            </label>
            <div className="card-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio12"
                  id="data_only"
                  value="data_only"
                  checked={radio12 === "data_only"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault10">
                  {t("pi-radio2-heading")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio12"
                  id="data_mostly"
                  value="data_mostly"
                  checked={radio12 === "data_mostly"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault11">
                  {t("pi-radio2-label1")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio12"
                  id="equal"
                  value="equal"
                  checked={radio12 === "equal"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault12">
                  {t("pi-radio2-label2")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio12"
                  id="intuition_mostly"
                  value="intuition_mostly"
                  checked={radio12 === "intuition_mostly"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault13">
                  {t("pi-radio2-label3")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio12"
                  id="not_disclosed"
                  value="not_disclosed"
                  checked={radio12 === "not_disclosed"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault15">
                  {t("pi-radio1-label6")}
                </label>
              </div>
            </div>

            {submitted && radio12Error && <p className="text-danger">{t("pi-radio1-error")}</p>}
          </div>

          {/* You have been tasked with organizing the company holiday party… * */}
          <div className="mb-3 mt-3 card">
            <label for="exampleInputEmail1" className="card-header text-white ">
              <h8>
                <b>{t("pi-radio3-heading")} *</b>
              </h8>
            </label>
            <div className="card-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="central"
                  value="central"
                  checked={radio13 === "central"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault16">
                  {t("pi-radio3-label1")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="survey"
                  value="survey"
                  checked={radio13 === "survey"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault17">
                  {t("pi-radio3-label2")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="discuss"
                  value="discuss"
                  checked={radio13 === "discuss"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault18">
                  {t("pi-radio3-label3")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="fun"
                  value="fun"
                  checked={radio13 === "fun"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault19">
                  {t("pi-radio3-label4")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="none"
                  value="none"
                  checked={radio13 === "none"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault20">
                  {t("pi-radio3-label5")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="radio13"
                  id="not_disclosed"
                  value="not_disclosed"
                  checked={radio13 === "not_disclosed"}
                  onChange={onChange}
                />
                <label className="form-check-label" for="flexRadioDefault21">
                  {t("pi-radio1-label6")}
                </label>
              </div>
            </div>

            {submitted && radio13Error && <p className="text-danger">{t("pi-radio1-error")}</p>}
          </div>

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Link
              className="login-form-button text-decoration-none"
              to="/PIHome"
              style={{ backgroundColor: "gray" }}
            >
              {t("back-btn")}
            </Link>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Link
                type="submit"
                className="login-form-button text-decoration-none"
                onClick={handleContinueClick}
              >
                {t("continue-btn")}
              </Link>
            </div>
          </div>
        </form>
      </div>
      <Copyright />
    </>
  );
}

export default PISecondScreen;
