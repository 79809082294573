import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";

function Copyright() {
  function getCurrentYear() {
    return new Date().getFullYear();
  }

  return (
    <>
      <div className="bg-footer d-flex flex-column gap-3 align-items-center justify-content-center">
        <div className="social-media d-flex gap-3 justify-content-center">
          <a
            href="https://www.facebook.com/caribglobaldataservices"
            target="_blank"
          >
            <FaFacebookF className="social-icon" />
          </a>
          <a 
            href="https://www.linkedin.com/company/caribglobal-data-services-b.v./"
            target="_blank"
          >
            <BiLogoLinkedin className="social-icon" />
          </a>
        </div>
        <div className="copy-right">© Copyright Onboardiq {getCurrentYear()} </div>
      </div>
    </>
  );
}

export default Copyright;
