import React, { useEffect, useState } from "react";
import Navbar from "../../components/Home/HomeSections/Section1/Navbar";
import cvImg from "../../assets/img/cloud-upload-f1b59713 (1) 1.png";
import cvPng from "../../assets/img/cvPng.png";
import { CSSTransition } from "react-transition-group";
import { UserLogin } from "../../context/AuthContext";
import { RiArrowDropDownLine } from "react-icons/ri";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { USER_PROFILE_URL } from "../../Auth_API";
import Progress from "../Loader/Progress";
import PersonalInfo from "./Sections/PersonalInfo";
import EducationalInfo from "./Sections/EducationalInfo";
import WorkExperience from "./Sections/WorkExperience";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
function CVForm() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const steps = [t("cv-personal-info"), t("cv-education"), t("cv-work-experience")];

  const {
    userData,
    activeCV,
    isLoading,
    setIsLoading,
    showToast,
    showPersonalInfoForm,
    showEducationForm,
    showWorkExperienceForm,
    fileSelected,
    setFileSelected,

    selectedFileName,
    setSelectedFileName,
    setFileToUpload,
    setCVSkimData,
    setCVSkimEduData,
    setCVSkimWorkData,
  } = UserLogin();

  const { token } = userData || {};

  // Get selected file name
  useEffect(() => {
    const fetchFileName = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${USER_PROFILE_URL}/upload-cv/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const fullPath = data.cv_file;
          const fileName = fullPath.split("/").pop();
          setSelectedFileName(fileName);
          setFileSelected(true);
        } else {
          showToast("Step: 1 Upload your CV and Personal Info.", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching file name:", error);
        showToast("Step: 1 Upload your CV and Personal Info.", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchFileName();
    }
  }, [token]);

  /* Functions for File select & drop */
  const handleFileUpload = async (cvFile) => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("cv_file", cvFile);

      const uploadResponse = await fetch(`${USER_PROFILE_URL}/upload-cv/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
      }
      handleSkimCVPersonal(cvFile);
      handleSkimCVEducation(cvFile);
      handleSkimCVExperience(cvFile);

      showToast("Your CV has been stored!");

      // Get cv file endpoint
      const getResponse = await fetch(`${USER_PROFILE_URL}/upload-cv/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (!getResponse.ok) {
        throw new Error(`HTTP error! Status: ${getResponse.status}`);
      }

      const getData = await getResponse.json();
      const fullPath = getData.cv_file;
      const fileName = fullPath.split("/").pop();

      setFileToUpload(cvFile);
      setSelectedFileName(fileName);
      setFileSelected(true);
    } catch (error) {
      console.error("Failed to save CV form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkimCVPersonal = async (cvFile) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("cv_file", cvFile);

      const response = await fetch(`${USER_PROFILE_URL}/personal-info-cv/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });
      const responseData = await response.json();
      setCVSkimData(responseData);
    } catch (error) {
      console.error("Failed to save CV form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkimCVEducation = async (cvFile) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("cv_file", cvFile);

      const response = await fetch(`${USER_PROFILE_URL}/education-cv/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      const responseData = await response.json();
      setCVSkimEduData(responseData);
      console.log("edu info", responseData);
    } catch (error) {
      console.error("Failed to save CV form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkimCVExperience = async (cvFile) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("cv_file", cvFile);

      const response = await fetch(`${USER_PROFILE_URL}/experience-cv/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      const responseData = await response.json();
      setCVSkimWorkData(responseData);
      console.log("experience", responseData);
    } catch (error) {
      console.error("Failed to save CV form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /* Functions for File select & drop */
  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const files = e.dataTransfer.files;

  //   if (files.length > 0) {
  //     const cvFile = files[0];
  //     handleFileUpload(cvFile);
  //   }
  // };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const cvFile = files[0];
      const allowedTypes = ["application/pdf"];

      if (!allowedTypes.includes(cvFile.type)) {
        // Show error message using Swal
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload a PDF file.",
        });
        return;
      }

      handleFileUpload(cvFile);
    }
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const cvFile = files[0];
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(cvFile.type)) {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Only PDF file is allowed!",
        });
        return;
      }
      handleFileUpload(cvFile);
    }
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClearFile = () => {
    setFileSelected(false);
    setSelectedFileName(null);
  };

  return (
    <>
      <Navbar />
      <div className="cv-form-container">
        <div className="cv-first-portion">
          {/* <div className="cv-img">
            <img src={cdsLogo}  height="55px" className="logo" />
          </div> */}
          <div
            className="cv-select-container my-5"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ cursor: "pointer" }}
          >
            {fileSelected ? (
              <>
                <div className="d-flex align-items-center gap-sm-2 gap-0 justify-content-center flex-sm-row flex-column">
                  <img src={cvPng} alt="" />
                  <p className="my-sm-3 my-2">
                    {selectedFileName} <CloseIcon onClick={handleClearFile} />
                  </p>
                </div>
                <div className="cv-dropdown">
                  <label
                    className="dropdown-button-cv"
                    htmlFor="cvUploadInput"
                    style={{
                      borderRadius: "30px",
                      padding: "12px 20px",
                    }}
                  >
                    {t("cv-drop-another-file")}
                    <input
                      type="file"
                      id="cvUploadInput"
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </label>
                </div>
              </>
            ) : (
              <>
                <label htmlFor="cvUploadInput">
                  <img src={cvImg} alt="cv-upload-img" className="responsive-img" />
                  <input
                    type="file"
                    id="cvUploadInput"
                    accept=".pdf,.docx"
                    style={{
                      display: "none",
                      borderRadius: "30px",
                      padding: "12px 20px",
                    }}
                    onChange={handleFileInputChange}
                  />
                </label>
                <p className="my-3">{t("cv-drop")}</p>

                <div className="cv-dropdown">
                  <label
                    htmlFor="cvUploadInput"
                    className="dropdown-button-cv"
                    style={{
                      borderRadius: "30px",
                      padding: "12px 20px",
                    }}
                  >
                    {t("choose-file")}
                    <span className="arrow-icon" onClick={handleDropdownToggle}>
                      <RiArrowDropDownLine style={{ width: "30px", height: "30px" }} />
                    </span>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>

        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeCV} alternativeLabel>
            {steps.map((label) => (
              <Step
                key={label}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "green",
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                    color: "gray",
                  },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#56367f",
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                    color: "#56367f",
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "#white",
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <CSSTransition
          in={showPersonalInfoForm}
          timeout={1000}
          classNames="form-fade"
          unmountOnExit
        >
          <div className="cv-info-container">
            <h2>
              <b>{t("personal-info")}</b>
            </h2>
            <PersonalInfo />
          </div>
        </CSSTransition>

        <CSSTransition in={showEducationForm} timeout={1000} classNames="form-fade" unmountOnExit>
          <div className="cv-info-container">
            <h2>
              <b>{t("cv-education")}</b>
            </h2>
            <EducationalInfo />
          </div>
        </CSSTransition>

        <CSSTransition
          in={showWorkExperienceForm}
          timeout={1000}
          classNames="form-fade"
          unmountOnExit
        >
          <div className="cv-info-container">
            <h2 className="mb-3">
              <b>Work Experience</b>
            </h2>
            <WorkExperience />
          </div>
        </CSSTransition>
        {isLoading ? <Progress /> : null}
      </div>
      <br />
      <Copyright />
    </>
  );
}

export default CVForm;
