import React, { useState, useEffect } from "react";
import logo from "../../../assets/img/Onboardiq-logo.png";
import ellipse from "../../../assets/img/eclipse1.svg";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { UserLogin } from "../../../context/AuthContext";


const Navbar = () => {
  const navigate = useNavigate();
  const {token} = UserLogin();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      {/* Navbar for larger screens */}
      <div className="navbar-container d-flex justify-content-between align-items-center">
        <img src={ellipse} className="ellipse" alt="Ellipse Background" />

        <div className="navbar-logo-container d-flex flex-column align-items-center my-3">
          <img src={logo} alt="Logo" className="navbar-logo-img" />
        </div>
        <div className="navbar-menu-container1 d-none d-lg-block">
          <ul className="d-flex gap-xl-5 gap-3">
            <li>
              <a href="#hero-website" className="text-white p-0 m-0">
                Home
              </a>
            </li>
            <li>
              <a href="#hero-section-2" className="text-white p-0 m-0">
                How it Works
              </a>
            </li>
            <li>
              <a href="#about" className="text-white p-0 m-0">
                About Us
              </a>
            </li>
            <li>
              <a href="#features" className="text-white p-0 m-0">
                Features
              </a>
            </li>
            <li>
              <a href="#pricing-plan" className="text-white p-0 m-0">
                Pricing
              </a>
            </li>
            <li>
              <a href="#testimonials" className="text-white p-0 m-0">
                Testimonials
              </a>
            </li>
          </ul>
        </div>
        <div className="nav-buttons-container d-none d-lg-flex gap-1">
          <div className="d-flex gap-2">
            <button className="btn-signup" onClick={() => navigate("/register")}>
              Sign up
            </button>
            {token ? (
              <button className="btn-login" onClick={() => navigate("/main")}>
                Go to App
              </button>
            ) : (
              <button
                className="btn-login"
                onClick={() => {
                  navigate("/login");
                  toggleMobileMenu();
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>
        <div
          className={`mobile-menu-icon d-lg-none ${mobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
        >
          <div className={`icon-bar ${mobileMenuOpen ? "open" : ""}`}></div>
          <div className={`icon-bar ${mobileMenuOpen ? "open" : ""}`}></div>
        </div>
      </div>
  
      {/* Mobile navbar */}
      <div className={`mobile-navbar-container d-lg-none ${mobileMenuOpen ? "open" : ""}`}>
        <ul className="mobile-menu">
          <li className="d-flex flex-column align-items-center">
            <img src={logo} alt="Logo" className="navbar-logo-img mobile-logo" />
          </li>
          <li>
            <a href="#hero-website" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              Home
            </a>
          </li>
          <li>
            <a href="#hero-section-2" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              How it Works
            </a>
          </li>
          <li>
            <a href="#about" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              About Us
            </a>
          </li>
          <li>
            <a href="#features" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              Features
            </a>
          </li>
          <li>
            <a href="#pricing-plan" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              Pricing
            </a>
          </li>
          <li>
            <a href="#testimonials" onClick={toggleMobileMenu} className="text-white p-0 m-0">
              Testimonials
            </a>
          </li>
          <li>
            <div className="nav-buttons-container d-flex flex-column gap-2">
              <button
                className="btn-signup"
                onClick={() => {
                  navigate("/register");
                  toggleMobileMenu();
                }}
              >
                Sign up
              </button>
              {token ? (
                <button className="btn-login" onClick={() => navigate("/main")}>
                  Go to App
                </button>
              ) : (
                <button
                  className="btn-login"
                  onClick={() => {
                    navigate("/login");
                    toggleMobileMenu();
                  }}
                >
                  Login
                </button>
              )}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
