// const stepsEnglish = [
//   {
//     selector: '[data-tour="1"]',
//     content: (
//       <>
//         <h3 className="step-heading">Step 1:</h3>
//         <p>Your step one is to upload your CV to get started.</p>
//       </>
//     ),
//     highlightedSelectors: [".ReactModal__Content"],
//     mutationObservables: [".ReactModal__Overlay"]
//   },
//   {
//     selector: '[data-tour="2"]',
//     content: (
//       <>
//         <h3 className="step-heading">Step 2:</h3>
//         <p>Choose a test and demonstrate your language skills.</p>
//       </>
//     )
//   },
//   {
//     selector: '[data-tour="3"]',
//     content: (
//       <>
//         <h3 className="step-heading">Step 3:</h3>
//         <p>Take the assessment to learn about your personality and work preferences.</p>
//       </>
//     )
//   },
//   {
//     selector: '[data-tour="4"]',
//     content: (
//       <>
//         <h3 className="step-heading">Step 4:</h3>
//         <p>Access your assessment reports in the "Reports" tab from this menu.</p>
//       </>
//     )
//   },
// ];

// export default stepsEnglish;

const stepsEnglish = [
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">Step 1:</h3>
        <p>Your first step is to upload your CV to get started.</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],
  },
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Step 2:</h3>
        <p>Choose a test and demonstrate your language skills.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Step 3:</h3>
        <p>Take the assessment to learn about your personality and work preferences.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Step 4:</h3>
        <p>Access your assessment reports in the "Reports" tab from this menu.</p>
      </>
    ),
  },
];

const stepsSpanish = [
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">Paso 1:</h3>
        <p>Su primer paso es cargar su CV para comenzar.</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],
  },
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Paso 2:</h3>
        <p>Elija una prueba y demuestre sus habilidades lingüísticas.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Paso 3:</h3>
        <p>Realice la evaluación para conocer su personalidad y preferencias laborales.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Paso 4:</h3>
        <p>Acceda a sus informes de evaluación en la pestaña "Informes" de este menú.</p>
      </>
    ),
  },
];

const stepsDutch = [
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">Stap 1:</h3>
        <p>Uw eerste stap is om uw CV te uploaden om te beginnen.</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],
  },
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Stap 2:</h3>
        <p>Kies een test en demonstreer uw taalvaardigheden.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Stap 3:</h3>
        <p>
          Doe de beoordeling om meer te weten te komen over uw persoonlijkheid en werkvoorkeuren.
        </p>
      </>
    ),
  },
  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Stap 4:</h3>
        <p>Toegang tot uw beoordelingsrapporten in het tabblad "Rapporten" van dit menu.</p>
      </>
    ),
  },
];

const stepsPapiamento = [
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">Paso 1:</h3>
        <p>Bo prome paso ta subi bo CV pa cuminsa.</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],
  },
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Paso 2:</h3>
        <p>Scoge un test y demostra bo habilidadnan di idioma.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Paso 3:</h3>
        <p>Haci e evaluacion pa conoce bo personalidad y preferencia di trabao.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Paso 4:</h3>
        <p>Acces bo raportnan di evaluacion den e tab "Rapportnan" for di e menu aki.</p>
      </>
    ),
  },
];

export { stepsEnglish, stepsSpanish, stepsDutch, stepsPapiamento };
