import React from "react";
import Lottie from "lottie-react";

const Progress = () => {
  return (
    <div className="cv-progress-animation">
      <Lottie
        animationData={require("../../assets/animation/loader.json")}
        loop
        autoplay
        className="cv-progress"
      />
    </div> 
  );
};

export default Progress;
