import React from "react";
import hire2 from "../../../assets/img/portalScreen.png";
import ellipse from "../../../assets/img/eclipse4.svg"; // Import the ellipse image
import "./herosection2.css";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router";
import Zoom from "react-reveal/Zoom";

const HeroSection2 = () => {
  const navigate = useNavigate();
  return (
    <div id="hero-section-2" className="hero-section-2">
      <img src={ellipse} className="ellipse-hero" alt="Ellipse Background" />
      {/* Add the ellipse image */}
      <div className="container d-flex flex-lg-row flex-column align-items-center gap-lg-0 gap-2">
        <div className="col-lg-6 col-12 left-div-image ">
          <Zoom appear>
            <img src={hire2} alt="Hire Illustration" />
          </Zoom>
        </div>

        <div className="col-lg-6 col-12">
          <Fade right appear>
            <div className="col hidden-content">
              <p className="stats-paragraph w-100 text-center">
                UNCOVER HIDDEN TALENT WITH PRECISION AND INSIGHT
              </p>
              <p className="hidden-para">
                Elevate Your Hiring Standards <span className="span-gradient">with OnboardIQ</span>
              </p>
              <p className="paragraph-hiring" style={{color:"#b5b5c3"}}>
                Step into the future of recruitment with a platform designed to deepen your
                understanding of each candidate. OnboardIQ not only assesses the basic competencies
                but also delves into how candidates perform in real-world scenarios, giving you a
                richer, more accurate view of their potential. Embrace a tool that brings to light
                the strengths and nuances of each applicant, making your hiring decisions not just
                faster, but smarter.
              </p>
              <div className="buttons-start-video">
                <button className="get-started" onClick={() => navigate("/login")}>LEARN MORE</button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HeroSection2;
