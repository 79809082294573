import React, { useEffect, useState } from "react";
import "../../App.css";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { UserLogin } from "../../context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AUTH_API_URL } from "../../Auth_API";
import { CgDanger } from "react-icons/cg";
import { BsChevronDown } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import OrganizationModal from "./OrganizationModal";
import onbLogo from "../../assets/img/onbLogoPng.png";
import polygonTL from "../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../assets/img/loginSearchImg.png";
import signupGirl from "../../assets/img/signup-girl.png";
import signupBlocks from "../../assets/img/signup-blocks-svg.svg";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Signup() {
  const location = useLocation();
  let navigation = useNavigate();
  const [signUpClicked, setSignUpClicked] = useState(false);
  const [isRegistrationScreen, setIsRegistrationScreen] = useState(true);
  const [isLoginScreenVisible, setIsLoginScreenVisible] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const { name, email, password, cpassword } = credentials;
  const {
    isLoading,
    setIsLoading,
    setUserData,
    isValidObjField,
    updateError,
    error,
    setError,
    showPassword,
    setShowPassword,
    showToast,
    setIsErrorOpen,
    isTypingPassword,
    setIsTypingPassword,
    selectedOrganizationId,
    passwordRequirementsError,
    setPasswordRequirementsError,
    handleEnterKeyPress,
    isPasswordInput,
    setIsPasswordInput,
    setSelectedOrganizationId,
    passwordMismatch,
    setPasswordMismatch,
  } = UserLogin();

  useEffect(() => {
    console.log(location.state, "location state");
    console.log(location.state?.organizationID, "org id");

    if (location.state) {
      setCredentials(location.state.credentials);
      setSelectedOrganizationId(location?.state?.organizationID);
    }
  }, [location]);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordFocus = () => {
    setIsTypingPassword(true);
  };

  const handlePasswordBlur = () => {
    setIsTypingPassword(false);
    if (password.trim() && cpassword.trim()) {
      if (password !== cpassword) {
        setPasswordMismatch(true);
      } else {
        setPasswordMismatch(false);
      }
    } else {
      setPasswordMismatch(false);
    }

    const meetsLengthRequirement = password.length >= 8;
    const meetsUppercaseRequirement = /[A-Z]/.test(password);
    const meetsLowercaseRequirement = /[a-z]/.test(password);
    const meetsNumericRequirement = /[0-9]/.test(password);

    const meetsAllRequirements =
      meetsLengthRequirement &&
      meetsUppercaseRequirement &&
      meetsLowercaseRequirement &&
      meetsNumericRequirement;
    setPasswordRequirementsError(!meetsAllRequirements);
  };

  /* Dropdown for password validations */
  const getPasswordRequirements = () => {
    const meetsLengthRequirement = password.length >= 8;
    const meetsUppercaseRequirement = /[A-Z]/.test(password);
    const meetsLowercaseRequirement = /[a-z]/.test(password);
    const meetsNumericRequirement = /[0-9]/.test(password);

    if (
      meetsLengthRequirement &&
      meetsUppercaseRequirement &&
      meetsLowercaseRequirement &&
      meetsNumericRequirement
    ) {
      return null;
    }

    return (
      <>
        <div className="password-requirements mt-2">
          <div className="row">
            <div className="col-11">
              <h5>Default password requirements:</h5>
            </div>
            <div className="col-1">
              <BsChevronDown size={15} color="#66B855" />
            </div>
          </div>
          <ul>
            <li
              className={meetsLengthRequirement ? "list-no-style" : ""}
              style={{ color: meetsLengthRequirement ? "green" : "inherit" }}
            >
              {meetsLengthRequirement ? <FaCheck /> : null} Must be at least 8 characters long
            </li>
            <li
              className={meetsUppercaseRequirement ? "list-no-style" : ""}
              style={{ color: meetsUppercaseRequirement ? "green" : "inherit" }}
            >
              {meetsUppercaseRequirement ? <FaCheck /> : null} Must include at least one English
              uppercase character (A-Z)
            </li>
            <li
              className={meetsLowercaseRequirement ? "list-no-style" : ""}
              style={{ color: meetsLowercaseRequirement ? "green" : "inherit" }}
            >
              {meetsLowercaseRequirement ? <FaCheck /> : null} Must include at least one English
              lowercase character (a-z)
            </li>
            <li
              className={meetsNumericRequirement ? "list-no-style" : ""}
              style={{ color: meetsNumericRequirement ? "green" : "inherit" }}
            >
              {meetsNumericRequirement ? <FaCheck /> : null} Must include at least one numeric
              character (0-9)
            </li>
          </ul>
        </div>
      </>
    );
  };

  const onChange = (e) => {
    if (e.target.name === "email") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value.toLowerCase() });
    } else {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
    setIsPasswordInput(e.target.name === "password" && e.target.value.trim().length > 0);
  };

  const isValidEmail = (value) => {
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
    return regx.test(value);
  };

  // Function to create a user
  const handleSignup = async (e) => {
    console.log("org id: ", selectedOrganizationId);
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        console.log("error");
        return updateError("Require all fields!", setError);
      }
      if (!name.trim() || name.length < 3) {
        console.log("error");
        return updateError("Enter a valid name!", setError);
      }
      if (!isValidEmail(email)) {
        console.log("error");
        return updateError("Enter a valid email!", setError);
      }
      if (!password.trim() || password.length < 8) {
        console.log("error");
        return updateError("Password must be 5 characters long!", setError);
      }
      if (passwordMismatch) {
        return updateError("Passwords do not match!", setError);
      }

      // Check if password satisfies requirements
      const meetsLengthRequirement = password.length >= 8;
      const meetsUppercaseRequirement = /[A-Z]/.test(password);
      const meetsLowercaseRequirement = /[a-z]/.test(password);
      const meetsNumericRequirement = /[0-9]/.test(password);
      if (
        !meetsLengthRequirement ||
        !meetsUppercaseRequirement ||
        !meetsLowercaseRequirement ||
        !meetsNumericRequirement
      ) {
        console.log("error in req");
        updateError("Password does not meet requirements!", setError);
        setPasswordRequirementsError(true); // Set error state here
        return;
      }
      if (!isAgreed) {
        updateError("You must agree to the terms and conditions!", setError);
        return;
      }

      setIsLoading(true);
      console.log("loading started");
      console.log(selectedOrganizationId);
      console.log("credentials", credentials);
      const response = await fetch(`${AUTH_API_URL}/add_user_/${selectedOrganizationId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data, "fgsdhjfsdfsd");
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(data));
        setIsRegistrationScreen(false);
        setIsLoginScreenVisible(false);
        setUserData(data);
        // showToast("You have been Register successfully!");
        showToast("If you did not recieve an OTP, please check your spam!");
        navigation("/otp_verificaion", {
          state: { email: data.email },
          replace: true,
        });
        console.log("User register successfully:", data);
        setIsLoading(false);
      } else {
        console.error("User with these credentials already present", error);
        showToast("Failed to register. User already present.");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("catch error");
      console.error("Failed to create user:", error);
      showToast("Failed to register. Please check your credentials.");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };

  const navigateToTnC = () => {
    navigation("/tnc", { state: { credentials } });
  };

  const navigateToPrivacy = () => {
    navigation("/privacy_policy", { state: { credentials } });
  };

  return (
    <div className="login-main">
      <div className="login-page-container d-flex justify-content-center gap-2 col-12">
        <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
          <div className="login-page-form mt-3 d-flex flex-column align-items-center">
            <div className="col-9 ps-4">
              <img
                onClick={() => navigation("/")}
                src={onbLogo}
                alt="logo"
                className="login-form-logo"
              />
            </div>
            <div className="login-form-container col-9 mt-4 shadow-none">
              <div className="login-form-body">
                <h4 className="fw-bold text-capitalize">Sign Up</h4>
                <p className="m-0 p-0 text-secondary">
                  Please enter the details to create an account
                </p>
                <hr />
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Name (Required)</label>
                  <input
                    name="name"
                    value={name}
                    onChange={onChange}
                    margin="normal"
                    required
                    autoComplete="off"
                    type="text"
                    placeholder="Name"
                    className="col-12"
                    onKeyDown={(e) => handleEnterKeyPress(e, "email")}
                  />
                  {error && (!name.trim() || name.length < 3) && (
                    <span className="error-fields text-danger ms-2">Enter a valid name!</span>
                  )}
                </div>
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Email Address (Required)</label>
                  <input
                    name="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    margin="normal"
                    required
                    autoComplete="off"
                    type="text"
                    placeholder="Email"
                    className="col-12"
                    onKeyDown={(e) => handleEnterKeyPress(e, "password")}
                  />
                  {error && !isValidEmail(email) && (
                    <span className="error-fields text-danger ms-2">Enter a valid email!</span>
                  )}
                </div>

                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Password</label>
                  <div className="col-12 password-textfield p-0 m-0">
                    <TextField
                      className="col-12 m-0"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      placeholder="Password"
                      value={password}
                      onChange={onChange}
                      margin="normal"
                      aria-autocomplete="none"
                      required
                      autoComplete="off"
                      error={
                        (error && (!password.trim() || password.length < 8) && isPasswordInput) ||
                        passwordRequirementsError
                      }
                      helperText={
                        error && (passwordRequirementsError || !password.trim()) ? (
                          <span className="error-fields text-danger ms-2">
                            Password does not meet requirements
                          </span>
                        ) : (
                          ""
                        )
                      }
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword} edge="end">
                              {showPassword ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                {isTypingPassword && password.length > 0 && getPasswordRequirements()}
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Confirm Password</label>
                  <div className="col-12 password-textfield p-0 m-0">
                    <TextField
                      type={showPassword ? "text" : "password"}
                      name="cpassword"
                      id="cpassword"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      className="col-12 m-0"
                      value={cpassword}
                      onChange={onChange}
                      margin="normal"
                      required
                      autoComplete="off"
                      error={passwordMismatch}
                      helperText={
                        (passwordMismatch && (
                          <div className="password-validation">
                            <CgDanger /> Password do not match!
                          </div>
                        )) ||
                        ""
                      }
                      onBlur={handlePasswordBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword} edge="end">
                              {showPassword ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                  style={{ fontSize: "16px" }}
                                ></i>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3 ms-1">
                  <div className="d-flex align-items-center gap-2">
                    <input
                      type="checkbox"
                      name="agreeTerms"
                      className="custom-checkbox"
                      style={{ height: "15px", width: "15px" }}
                      checked={isAgreed}
                      onChange={(e) => setIsAgreed(e.target.checked)}
                    />
                    <p className="p-0 m-0">
                      Accept
                      <span
                        className="p-0 m-0 ms-1"
                        style={{ color: "#5051FA", cursor: "pointer" }}
                        onClick={navigateToTnC}
                      >
                        Terms & Conditions
                      </span>
                    </p>
                  </div>
                </div>
                {error && !isAgreed && (
                  <span className="error-fields text-danger">
                    Please accept terms and conditions!
                  </span>
                )}
                <button
                  onClick={(e) => {
                    setSignUpClicked(true);
                    handleSignup(e);
                  }}
                  className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                  disabled={isLoading}
                >
                  Sign up
                </button>

                <p className="m-0 mt-3 p-0 d-flex gap-2 text-secondary">
                  Already have an account?
                  <span>
                    <Link
                      to="/login"
                      className="text-decoration-underline"
                      style={{ color: "#56367f", fontWeight: "600" }}
                    >
                      Sign in
                    </Link>
                  </span>
                </p>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <p
                  className="m-0 mt-5"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={navigateToPrivacy}
                >
                  Privacy Policy
                </p>
                <p className="m-0 mt-5" style={{ fontSize: "14px" }}>
                  Copyright 2022
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="login-page-right d-flex align-items-center flex-column  my-3 col-md-5 d-md-block d-none">
          <div className="d-flex col-12 mt-5 flex-column align-items-center">
            <h4 className="text-capitalize text-center text-white fw-bold fs-2 col-10">
              Transform Your Hiring Process With Onboard.IQ{" "}
            </h4>
            <p className="text-white text-center mt-1 col-10">
              Forget the old way of manually going through endless CVs. OnboardIQ transforms your
              hiring process into an enlightening journey of discovery. Our advanced platform
              doesn’t just evaluate; it uncovers the hidden potential in each candidate, ensuring
              you see more than just qualifications you see the future contributors to your
              company’s success.
            </p>
          </div>
          <img
            src={loginSearchImg}
            alt="login-search-img"
            className="login-search-img"
            style={{ opacity: "0" }}
          />
          <img src={polygonTL} alt="polygon" className="polygon-top-left" />
          <img src={cellsTR} alt="cells" className="cells-top-right" />
          <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
          <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
          <img src={signupBlocks} alt="polygon" className="signup-blocks" />
          <img src={signupGirl} alt="polygon" className="signup-right-girl" />
        </div>
      </div>
      <OrganizationModal />
    </div>
  );
}
