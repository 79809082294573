import React from "react";
import "./features.css";
import ellipse from "../../../assets/img/eclipse3.svg";
import icon1 from "../../../assets/img/user.png";
import icon2 from "../../../assets/img/analytics.png";
import icon3 from "../../../assets/img/assessments.png";
import icon4 from "../../../assets/img/task.png";
import { Fade } from "react-reveal";

const Features = () => {
  return (
    <div id="features" className="features">
      <img src={ellipse} className="ellipse-features" alt="Ellipse Background" />
      <div className=" mt-4 d-flex flex-column align-items-center">
        <Fade top appear>
          <p className="features-paragraph text-center">OUR FEATURES</p>
          <h3 className="features-heading text-white">Key Features of OnboardIQ</h3>
        </Fade>
        {/* Boxes Section */}
        <div className="features-boxes d-flex flex-column align-items-center">
          <div className="d-flex align-items-center col-md-8 col-10 flex-lg-row flex-column">
            <Fade left appear>
              <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center">
                <div className="feature-box col-lg-5 col-12 ">
                  <img src={icon1} alt="Icon 1" className="feature-icon" />
                  <h4 className="feature-heading">Advanced Candidate Profiling</h4>
                  <p className="feature-paragraph">
                    Go beyond resumes to uncover candidates' true skills and potential with in-depth
                    assessments.
                  </p>
                </div>
                <div className="feature-box col-lg-5 col-12">
                  <img src={icon2} alt="Icon 2" className="feature-icon" />
                  <h4 className="feature-heading">Real-World Task Simulation</h4>
                  <p className="feature-paragraph">
                    Evaluate how candidates perform in actual job-related scenarios, ensuring
                    practical fit.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="d-flex align-items-center col-md-8 col-10 flex-lg-row flex-column">
            <Fade right appear>
              <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center ">
                <div className="feature-box col-lg-5 col-12">
                  <img src={icon3} alt="Icon 3" className="feature-icon" />
                  <h4 className="feature-heading">Comprehensive Analytics Dashboard</h4>
                  <p className="feature-paragraph">
                    Gain essential insights from a streamlined dashboard to refine your hiring
                    process.
                  </p>
                </div>
                <div className="feature-box col-lg-5 col-12">
                  <img src={icon4} alt="Icon 4" className="feature-icon" />
                  <h4 className="feature-heading">Customizable Assessments</h4>
                  <p className="feature-paragraph">
                    Adapt tests to meet your specific organizational needs, enhancing their
                    relevance and impact.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
