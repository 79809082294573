import React from 'react';
import './pricingplan.css';
import tick from "../../../assets/img/tick.png";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import eclipse2 from "../../../assets/img/eclipse2.svg";

const PricingPlan = () => {
    return (
        <div className='container-fluid' id='pricing-plan'>
            <img src={eclipse2} className='ellipse-pricing' alt="Eclipse 2" />
            <div className='pricing-content d-flex flex-column align-items-center'>
                <Fade top appear>
                    <p className='stats-paragraph text-center mx-sm-0 mx-2'>
                        FLEXIBLE PRICING FOR EVERY BUSINESS SIZE
                    </p>
                    <h3 className='pricing-heading text-white'>
                        Choose Your Plan, Empower Your Hiring
                    </h3>
                </Fade>

                <div className='pricing-cards'>
                    <Fade left appear>
                        <div className='pricing-card'>
                            <h4>Starter</h4>
                            <p className='price'>
                                <span className='amount'>$19</span> <span>/mo</span>
                            </p>
                            <p className='price-yearly'>or $199 yearly</p>
                            <ul>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                            </ul>
                            <button className='btn-get-started'>Get Started</button>
                        </div>
                    </Fade>
                    <Zoom appear>
                        <div className='pricing-card'>
                            <h4>Professional</h4>
                            <p className='price'>
                                <span className='amount'>$29</span> <span>/mo</span>
                            </p>
                            <p className='price-yearly'>or $299 yearly</p>
                            <ul>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                            </ul>
                            <button className='btn-get-started'>Get Started</button>
                        </div>
                    </Zoom>
                    <Fade right appear>
                        <div className='pricing-card'>
                            <h4>Enterprise</h4>
                            <p className='price'>
                                <span className='amount'>$49</span> <span>/mo</span>
                            </p>
                            <p className='price-yearly'>or $499 yearly</p>
                            <ul>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                                <li><img src={tick} width={20} height={20} />Feature text goes here</li>
                            </ul>
                            <button className='btn-get-started'>Get Started</button>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default PricingPlan;
