import React, { useState, useEffect } from "react";
import Login from "../components/AuthScreens/Login";
import Register from "../components/AuthScreens/Signup";
import OTPVerification from "../components/AuthScreens/OTPVerification";
import RegisterPhone from "../components/AuthScreens/RegisterPhone";
import VerificationDone from "../components/AuthScreens/VerificationDone";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserLogin } from "../context/AuthContext";
import InterviewHome from "../components/InterviewScreens/InterviewHome";
import InterviewWarmUp from "../components/InterviewScreens/InterviewWarmUp";
import InterviewQs from "../components/InterviewScreens/InterviewQs";
import Reports from "../components/MyReports/Reports";
import SelectedReport from "../components/MyReports/SelectedReport";
import PISecondScreen from "../components/PredictiveIndex/PISecondScreen";
import PIThirdScreen from "../components/PredictiveIndex/PIThirdScreen";
import PIFourthScreen from "../components/PredictiveIndex/PIFourthScreen";
import PIFivthScreen from "../components/PredictiveIndex/PIFivthScreen";
import PILastScreen from "../components/PredictiveIndex/PILastScreen";
import Contact from "../components/ContactUs/Contact";
import FAQScreen from "../components/FAQ/FAQScreen";
import Main from "../components/Home/Main";
import CVForm from "../components/CV/CVForm";
import FPScreen1 from "../components/AuthScreens/ForgetPassword/FP_Screen1";
import FPScreen3 from "../components/AuthScreens/ForgetPassword/FP_Screen3";
import FPScreen2 from "../components/AuthScreens/ForgetPassword/FP_Screen2";
import ErrorPage from "../components/AuthScreens/ErrorPage";
import Terms from "../components/T&C/Terms";
import PrivacyPolicy from "../components/T&C/PrivacyPolicy";
import InterviewOrgTable from "../components/InterviewScreens/InterviewOrgTable";
import PIReportMain from "../components/MyReports/PredictiveReport/PIReportMain";
import MainRoute from "../components/LandingWebsite/MainRoute";
import PrivateRoutes from "./PrivateRoutes";
import { TourProvider, useTour } from "@reactour/tour";
import { stepsEnglish, stepsSpanish, stepsDutch, stepsPapiamento } from "../components/steps";

function Stack() {
  const { showToast, setIsErrorOpen, setToken, langChange, setLangChange } = UserLogin();
const {steps,setSteps} = useTour();
  const getInitialSteps = () => {
    switch (langChange) {
      case "SP":
        return stepsSpanish;
      case "DU":
        return stepsDutch;
      case "PA":
        return stepsPapiamento;
      case "EN":
      default:
        return stepsEnglish;
    }
  };

  let navigation = useNavigate();
  const finalSteps = getInitialSteps();
  useEffect(() => {
    let idleTimer;
    const timeoutDuration = 2 * 60 * 60 * 1000;

    const resetTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("organizationUrl");
        setToken("");
        showToast("You have been logged out due to inactivity.");
        setIsErrorOpen(true);
        console.log("Token removed");
        navigation("/", { replace: true });
      }, timeoutDuration);
    };

    const handleUserInteraction = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    resetTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      clearTimeout(idleTimer);
    };
  }, []);

  useEffect(() => {
   console.log("steps from stack page:",steps)
  }, [steps,setSteps]);

  return (
    <TourProvider steps={finalSteps} showBadge={false} showTooltip={false} showTooltipArrow={false}>
      <div style={{ fontFamily: "Roboto, sans-serif" }}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route exact path="/Main" element={<Main rerender />} />
            <Route exact path="/CVForm" element={<CVForm />} />
            <Route exact path="/InterviewHome" element={<InterviewHome />} />
            <Route exact path="/InterviewWarmUp" element={<InterviewWarmUp />} />
            <Route exact path="/InterviewQs" element={<InterviewQs />} />
            <Route exact path="/Reports" element={<Reports />} />
            <Route exact path="/SelectedReport" element={<SelectedReport />} />
            <Route exact path="/PISecondScreen" element={<PISecondScreen />} />
            <Route exact path="/PIThirdScreen" element={<PIThirdScreen />} />
            <Route exact path="/PIFourthScreen" element={<PIFourthScreen />} />
            <Route exact path="/PIFivthScreen" element={<PIFivthScreen />} />
            <Route exact path="/PILastScreen" element={<PILastScreen />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/FAQScreen" element={<FAQScreen />} />
            <Route exact path="/interview_org_table" element={<InterviewOrgTable />} />
            <Route exact path="/predictive_report" element={<PIReportMain />} />
          </Route>

          <Route path="/" element={<MainRoute />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget_password" element={<FPScreen1 />} />
          <Route path="/forget_otp_password" element={<FPScreen2 />} />
          <Route path="/reset_password" element={<FPScreen3 />} />
          <Route path="/otp_verificaion" element={<OTPVerification />} />
          <Route path="/register_phone" element={<RegisterPhone />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/verification_done" element={<VerificationDone />} />
          <Route exact path="/tnc" element={<Terms />} />
          <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route exact path="/error" element={<ErrorPage />} />
          <Route exact path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </TourProvider>
  );
}

export default Stack;
