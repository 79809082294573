import React from "react";
import Lottie from "lottie-react";

const EmptySearch = () => {
  return (

    <div className="app-loader-animation">
      <Lottie
        animationData={require("../../assets/animation/emptySearch.json")}
        loop
        autoplay
        className="app-loader"
      />
    </div>
  );
};

export default EmptySearch;
