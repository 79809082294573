import React from "react";
import imgPI1 from "../../../../assets/img/pi-15.png";
import imgPI2 from "../../../../assets/img/pi-1.png";
import imgPI3 from "../../../../assets/img/pi-3.png";
import { UserLogin } from "../../../../context/AuthContext";
import {useTranslation} from "react-i18next";

function Section6() {
  const { piReportData } = UserLogin();
  const { t } = useTranslation();

  return (
    <>
      <div className="pi-section-container pi-manag-container">
        <div
          className="row row-cols-1 row-cols-md-3 row-cols-sm-1 pi-manag-row"
          style={{ marginTop: "5%" }}
        >
          <div className="col pi-col-5 pi-manag-col">
            <div className="card pi-card py-4 pi-manag1-card">
              <img
                src={imgPI1}
                className="card-img-top pi-img-detailed pi-img-managment"
                alt="..."
              />
              <div className="card-body pi-last-card">
                <p className="card-title pi-details-title pi-manag-title">{t("motivation")}</p>
                <div className="card-text text-center bar-pi-details mt-5">
                  <div className="progress-container">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar"
                        style={{ width: "50%", background: "#56367f", borderRadius: "30px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <p className="card-text pi-card-text mt-4 text-center w-100 pi-manag-text">
                  {piReportData?.analysis_data?.management_recommendations?.motivation}
                </p>
              </div>
            </div>
          </div>
          <div className="col pi-col-5  pi-manag-col">
            <div className="card pi-card py-4 pi-manag-card">
              <img
                src={imgPI2}
                className="card-img-top pi-img-detailed pi-img-managment"
                alt="..."
              />
              <div className="card-body pi-last-card">
                <p className="card-title pi-details-title pi-manag-title">{t("communication")}</p>
                <div className="card-text text-center bar-pi-details mt-5">
                  <div className="progress-container">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar"
                        style={{ width: "50%", background: "#56367f", borderRadius: "30px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <p className="card-text pi-card-text mt-4 text-center w-100 pi-manag-text">
                  {piReportData?.analysis_data?.management_recommendations?.communication}
                </p>
              </div>
            </div>
          </div>
          <div className="col pi-col-5  pi-manag-col">
            <div className="card pi-card py-4 pi-manag-card">
              <img
                src={imgPI3}
                className="card-img-top pi-img-detailed pi-img-managment"
                alt="..."
              />
              <div className="card-body pi-last-card">
                <p className="card-title pi-details-title pi-manag-title">{t("team-placement")}</p>
                <div className="card-text text-center bar-pi-details mt-5">
                  <div className="progress-container">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar"
                        style={{ width: "50%", background: "#56367f", borderRadius: "30px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <p className="card-text pi-card-text mt-4 text-center w-100 pi-manag-text">
                  {piReportData?.analysis_data?.management_recommendations?.team_placement}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section6;
