import React, { useEffect, useState } from "react";
import { UserLogin } from "../../../context/AuthContext";
import { USER_PROFILE_URL } from "../../../Auth_API";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { Button, TextField, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function WorkExperienceTable() {
  const { t } = useTranslation();
  const { workData, setWorkData, userData, employmentTypeOptions, workMethodOptions, isValidDate } =
    UserLogin();

  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  // Function to toggle the currentlyWorking state
  const handleCurrentlyWorkingToggle = () => {
    setCurrentlyWorking(!currentlyWorking);
  };

  const { id, token } = userData || {};
  const [formData, setFormData] = useState({
    company_name: "",
    job_title: "",
    employment_type: "",
    work_method: "",
    work_start_date: "",
    work_end_date: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    company_name: false,
    job_title: false,
    work_method: false,
    employment_type: false,
    description: false,
    work_start_date: false,
  });

  const [editMode, setEditMode] = useState(false);

  /* Get Integrate endpoint */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${USER_PROFILE_URL}/work-experience/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setWorkData(data);
      } catch (error) {
        console.error("Error fetching education data:", error);
      }
    };

    fetchData();
  }, []);

  /* Delete Integrate endpoint */
  const handleDeleteWork = async (idToDelete) => {
    try {
      const response = await fetch(`${USER_PROFILE_URL}/work-experience/${idToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        const updatedData = workData.filter((work) => work.id !== idToDelete);
        setWorkData(updatedData);
        console.log("Delete data:", response);
      } else {
        console.error("Request failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const [editWorkModalOpen, setEditWorkModalOpen] = useState(false);

  const toggleModal = () => {
    setEditWorkModalOpen(!editWorkModalOpen);
  };

  const [selectedWorkId, setSelectedWorkId] = useState(null);

  /* Function to pick eduction id */
  const handleEditWork = async (idToEdit) => {
    try {
      const selectedWork = workData.find((work) => work.id === idToEdit);
      setCurrentlyWorking(selectedWork.work_end_date ? false : true);
      if (selectedWork) {
        setFormData({
          company_name: selectedWork.company_name,
          job_title: selectedWork.job_title,
          employment_type: selectedWork.employment_type,
          work_method: selectedWork.work_method,
          work_start_date: formatDateForInput(selectedWork.work_start_date),
          work_end_date: formatDateForInput(selectedWork.work_end_date),
          description: selectedWork.description,
        });

        setEditMode(true);
        toggleModal();
        setSelectedWorkId(idToEdit);
      }
    } catch (error) {
      console.error("Error setting form data for edit:", error);
    }
  };

  /* Edit Integrate endpoint */
  const handleModifiedWork = async (idToEdit) => {
    // Check if work_end_date is empty and set it to "in progress" if so
    const updatedWorkEndDate = currentlyWorking ? "in progress" : formData.work_end_date;

    console.log("form erros", formErrors);
    try {
      const hasErrors = Object.values(formErrors).some((error) => error);
      if (hasErrors) {
        console.log("form has errors");
        console.log("form errors:", formErrors);
        return;
      }
      const response = await fetch(`${USER_PROFILE_URL}/work-experience/${idToEdit}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          company_name: formData.company_name,
          job_title: formData.job_title,
          employment_type: formData.employment_type,
          work_method: formData.work_method,
          work_start_date: formData.work_start_date,
          work_end_date: updatedWorkEndDate === "in progress" ? null : updatedWorkEndDate,
          description: formData.description,
          user: id,
        }),
      });

      if (response.ok) {
        const updatedDataResponse = await fetch(`${USER_PROFILE_URL}/work-experience/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (updatedDataResponse.ok) {
          const updatedData = await updatedDataResponse.json();
          setWorkData(updatedData);
          console.log("Updated data:", updatedData);
        } else {
          console.error("Failed to fetch updated data:", updatedDataResponse.statusText);
        }

        setEditWorkModalOpen(false);
      } else {
        console.error("Request failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  /* Styling for divider */
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const onChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let error = false;
    switch (name) {
      case "company_name":
        error = !value || !/^[a-zA-Z\s.()-]+$/.test(value.trim()) || value.length < 3;
        break;
      case "job_title":
        error = !value || !/^[a-zA-Z\s.,()\-]+$/.test(value.trim()) || value.length < 3;
        break;
      case "employment_type":
        error = employmentTypeOptions.some((option) => option.value === value);
        break;
      case "work_method":
        error = !workMethodOptions.some((option) => option.value === value);
        break;
      case "work_start_date":
        error = !isValidDate(value) || value === "" ? true : false;
        break;

      case "description":
        error =
          value && value.trim().length > 0
            ? /^(?=.*[a-zA-Z])[a-zA-Z0-9\s.,!@#$%^&*()-_+=<>?:"{}|[\]\\/]+$/g.test(value.trim()) &&
              value.trim().length >= 5
              ? false
              : true
            : false; // Validate only if description is provided
        break;
      default:
        break;
    }
    console.log("Value coming in form:", value);
    setFormErrors({ ...formErrors, [name]: error });
  };

  useEffect(() => {
    console.log("work method selected value:", formData.work_method);
    console.log("work method options:", workMethodOptions);
    console.log(
      "work method match:",
      workMethodOptions.some((option) => option.value === formData.work_method)
    );
  }, [formData]);

  const handleStartDateChange = (e) => {
    onChangeFormData(e);
    setSelectedStartDate(e.target.value);
  };

  // const formatDateForInput = (dateString) => {
  //   const [day, month, year] = dateString.split("/");
  //   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  // };

  const formatDateForInput = (dateString) => {
    if (dateString === null) {
      return ""; // Return an empty string for "in progress"
    } else {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, cursor: "pointer", borderRadius: "12px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow style={{ background: "lightgrey" }}>
              <TableCell>#</TableCell>
              <TableCell>{t("work-company")}</TableCell>
              <TableCell>{t("work-job")}</TableCell>
              <TableCell>{t("work-employment")}</TableCell>
              <TableCell>{t("work-method")}</TableCell>
              <TableCell>{t("education-startDate")}</TableCell>
              <TableCell>{t("education-endDate")}</TableCell>
              <TableCell>{t("education-jobDesc")}</TableCell>
              <TableCell>Modify Info</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workData.map((work, index) => (
              <TableRow key={work?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{work?.company_name}</TableCell>
                <TableCell>{work?.job_title}</TableCell>
                <TableCell>{work?.employment_type}</TableCell>
                <TableCell>{work?.work_method}</TableCell>
                <TableCell>{work?.work_start_date}</TableCell>
                <TableCell>{work?.work_end_date}</TableCell>
                <TableCell>{work?.description}</TableCell>
                <TableCell onClick={() => handleEditWork(work?.id)}>
                  <CiEdit size={15} />
                </TableCell>
                <TableCell onClick={() => handleDeleteWork(work?.id)}>
                  <RiDeleteBinLine size={15} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Education Modal */}
      <div
        className={`modal fade ${workData ? "show" : ""}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!editWorkModalOpen || selectedWorkId === null}
        style={{
          display: editWorkModalOpen ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "800px", borderRadius: "10px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}
                ></button>
              </div>

              <br />
              <form className="my-5" id="education">
                <div className="row" style={{ marginTop: "-5%" }}>
                  <h2 style={{ textAlign: "center", marginBottom: "5%" }}>
                    <b>{t("education-modify")}</b>
                  </h2>
                  <>
                    <div className="col-md-6">
                      <label htmlFor={`company_name`} className="form-label">
                        {t("work-company")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. Innovative Tech Solutions"
                          name="company_name"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          value={formData.company_name}
                          onChange={onChangeFormData}
                          required
                          id={`company_name`}
                          variant="outlined"
                          fullWidth
                          error={formErrors.company_name}
                          helperText={
                            formErrors.company_name && "Please enter a valid company name!"
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor={`job_title`} className="form-label">
                        {t("work-job")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. Web Developer"
                          id={`job_title`}
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="job_title"
                          value={formData.job_title}
                          onChange={onChangeFormData}
                          error={formErrors.job_title}
                          helperText={formErrors.job_title && "Please enter a valid job title!"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`employment_type`} className="form-label">
                        {t("work-employment")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          placeholder="e.g. Web Developer"
                          name="employment_type"
                          value={formData.employment_type}
                          onChange={onChangeFormData}
                          required
                          id={`employment_type`}
                          variant="outlined"
                          fullWidth
                          select
                          error={formErrors.employment_type}
                          helperText={
                            formErrors.employment_type && "Please select valid employment type!"
                          }
                        >
                          {employmentTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`work_method`} className="form-label">
                        {t("work-method")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          id={`work_method`}
                          select
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="work_method"
                          value={formData.work_method}
                          onChange={onChangeFormData}
                          error={formErrors.work_method}
                          helperText={formErrors.work_method && "Please choose valid work method!"}
                        >
                          {workMethodOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor={`work_start_date `} className="form-label">
                        {t("education-startDate")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          name="work_start_date"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                            "& input::-webkit-calendar-picker-indicator": {
                              filter: "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                            },
                            "& input[type='date']::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type='date']::-webkit-clear-button": {
                              display: "none",
                            }
                          }}
                          value={formData.work_start_date}
                          onChange={handleStartDateChange}
                          id={`work_start_date `}
                          variant="outlined"
                          fullWidth
                          type="date"
                          error={formErrors.work_start_date}
                          helperText={formErrors.work_start_date && "Please enter start date"}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor={`work_end_date `} className="form-label">
                        {t("education-endDate")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          name="work_end_date"
                          value={formData.work_end_date}
                          onChange={onChangeFormData}
                          id={`work_end_date`}
                          variant="outlined"
                          disabled={currentlyWorking || !formData.work_start_date}
                          fullWidth
                          type="date"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                            "& input::-webkit-calendar-picker-indicator": {
                              filter: "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                            },
                            "& input[type='date']::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type='date']::-webkit-clear-button": {
                              display: "none",
                            }
                          }}
                          inputProps={{
                            min: formData.work_start_date,
                          }}
                          error={formData.work_end_date === "" && !currentlyWorking}
                          helperText={
                            formData.work_end_date === "" &&
                            !currentlyWorking &&
                            "Please enter end date!"
                          }
                        />
                      </div>
                      <label className="d-flex gap-2 mx-2">
                        <input
                          type="checkbox"
                          checked={currentlyWorking}
                          onChange={handleCurrentlyWorkingToggle}
                        />
                                         {t("currently-working")}

                      </label>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor={`description`} className="form-label">
                        {t("education-jobDesc")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          type="text"
                          name="description"
                          value={formData.description}
                          onChange={onChangeFormData}
                          id={`description`}
                          variant="outlined"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          fullWidth
                          error={formErrors.description}
                          helperText={formErrors.description && "Job description is not valid!"}
                        />
                      </div>
                    </div>
                  </>

                  <Root>
                    <Divider></Divider>
                  </Root>
                </div>

                <div className="edit-education-buttons">
                  <Button
                    type="button"
                    variant="outlined"
                    data-bs-dismiss="modal"
                    className="login-form-button text-white"
                    onClick={toggleModal}
                  >
                    {t("btn-notnow")}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => handleModifiedWork(selectedWorkId)}
                    className="login-form-button text-white"
                  >
                    {t("btn-edit")}
                  </Button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
