import React from "react";
import Lottie from "lottie-react";

function NoPI() {
  return (
    <>
      <div className="pi-section-container mt-3">
        <div
          className="pi-card card mb-3"
          style={{ width: "100%", padding: "60px 100px", background: " linear-gradient(90deg, #56367f 0%, #3a5697 100%)"}}
        >
          <div className="row g-0 pi-dynamic-row">
            <div className="col-md-12 col-sm-12 pi-col-5">
              <p
                className="card-text pi-card-text mt-0 text-center d-flex flex-md-row flex-column align-items-center" 
                style={{
                  color: "white",
                  fontSize: "28px",
                  fontWeight: "500",
                  margin: "auto",
                  display: "flex",
                }}
              >
                <span className="mt-lg-0 mt-5 w-100">You do not have any Predictive index report </span>
                <Lottie
                  animationData={require("../../../../assets/animation/noPI.json")}
                  loop
                  autoplay
                  style={{ width: "25%" }}
                  className="img-fluid dynamic-img"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoPI;
