import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { USER_PROFILE_URL } from "../../../Auth_API";
import EducationAccordian from "./EducationAccordian";
import Progress from "../../Loader/Progress";
import { useTranslation } from "react-i18next";
import axios from "axios";

function EducationalInfo() {
  const { t } = useTranslation();
  const [educationCount, setEducationCount] = useState(1);
  const [currentlyStudying, setCurrentlyStudying] = useState(false);

  // Function to toggle the currentlyStudying state
  const handleCurrentlyStudyingToggle = () => {
    setCurrentlyStudying(!currentlyStudying);
  };

  const {
    userData,
    cvCredentials: { title, institute_name, education_level, start_date, end_date, score },
    onChangeCVCredentials,
    setActiveCV,
    fieldValidations,
    setFieldValidations,
    setShowWorkExperienceForm,
    educationalLevelOptions,
    setShowEducationForm,
    setEducationData,
    educationData,
    showToast,
    isLoading,
    setIsLoading,
    setIsErrorOpen,
    isValidDate,
    setCVCredentials,
    setShowPersonalInfoForm,
    handleEnterKeyPress,
    cvSkimEduData,
  } = UserLogin();

  const { id, token } = userData || {};
  const hasEducationData = educationData && educationData.length > 0;
  const [selectedStartDate, setSelectedStartDate] = useState("");

  const handleStartDateChange = (e) => {
    onChangeCVCredentials(e);
    setSelectedStartDate(e.target.value);
  };

  const isValidScore = (value, minValue, maxValue) => {
    if (!value) {
      return false;
    }

    const scorePattern = /^(\d+(\.\d+)?\/\d+(\.\d+)?)$/;
    const gradePattern = /^[A-F]$/;

    const isNumericScore = scorePattern.test(value);
    const isGrade = gradePattern.test(value);

    if (!(isNumericScore || isGrade)) {
      return false;
    }

    if (isGrade && value.length !== 1) {
      // If it's a grade, it should be a single character.
      return false;
    }

    if (isNumericScore) {
      const matchScore = value.match(scorePattern);
      if (!matchScore) {
        return false;
      }

      const [obtainedScore, totalScore] = matchScore[0].split("/").map(parseFloat);

      return (
        !isNaN(obtainedScore) &&
        obtainedScore > 0 &&
        !isNaN(totalScore) &&
        obtainedScore >= minValue &&
        obtainedScore <= maxValue &&
        totalScore > 0 &&
        obtainedScore <= totalScore
      );
    }

    // If it's a single character grade, no further validation is needed.
    return true;
  };

  /* Function to handle educational info */
  const handleEducationSubmit = async (e) => {
    e.preventDefault();
    const updatedEduEndDate = currentlyStudying ? "in progress" : end_date;

    const newFieldValidations = {
      ...fieldValidations,
      title: !(
        !title ||
        !/^[a-zA-Z0-9\s'!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/i.test(title.trim()) ||
        title.length < 3 ||
        /^\d+$/.test(title.trim())
      ),
      
      
      // title: !(!title && !cvSkimEduData?.title || !/^[a-zA-Z\s]+$/.test((title || cvSkimEduData?.title || "").trim()) || (title || cvSkimEduData?.title || "").length < 3),
      institute_name: !(
        !institute_name ||
        !/^[\w\s\d'!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/.test(institute_name.trim()) ||
        institute_name.length < 3
      ),

      education_level: educationalLevelOptions.some((option) => option.value === education_level),
      start_date: isValidDate(start_date),
      end_date: updatedEduEndDate,
      score: isValidScore(score, 0, 1000),
    };
    const isFormValid = Object.values(newFieldValidations).every((valid) => valid);
    if (!isFormValid) {
      setFieldValidations(newFieldValidations);
      setIsLoading(false);
      console.log("not valid edu form");
      console.log("field validations:", fieldValidations);
      console.log("new field validations:", newFieldValidations);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${USER_PROFILE_URL}/education/`,
        {
          title,
          institute_name,
          education_level,
          start_date,
          end_date: updatedEduEndDate === "in progress" ? null : updatedEduEndDate,
          score,
          user: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("response after saving education data", response);
      if (response.status === 201) {
        console.log("Request succeeded!");

        setIsLoading(false);
        setSelectedStartDate("");
        setCurrentlyStudying(false);
        setFieldValidations(newFieldValidations);
        setCVCredentials({
          title: "",
          institute_name: "",
          education_level: "",
          start_date: "",
          end_date: "",
          score: "",
        });

        // Fetch updated data
        const updatedDataResponse = await axios.get(`${USER_PROFILE_URL}/education/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        console.log("updated response :", updatedDataResponse);
        if (updatedDataResponse.status === 200) {
          // const updatedData = await updatedDataResponse.data;
          setEducationData(updatedDataResponse.data);
          console.log("Updated all education data:", updatedDataResponse.data);
        } else {
          console.error("Failed to fetch updated data:", updatedDataResponse.statusText);
        }
      }
    } catch (error) {
      console.error("Error making request:", error);
      setIsErrorOpen(true);
      showToast(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  /* Function to handle without auth educational info */
  const handleWithoutAuthEducationSubmit = async (e) => {
    e.preventDefault();
    setActiveCV(2);
    setShowEducationForm(false);
    setShowWorkExperienceForm(true);
  };

  /* Function to move back */
  const handleMoveBack = (e) => {
    e.preventDefault();
    setShowPersonalInfoForm(true);
    setShowEducationForm(false);
  };

  useEffect(() => {
    if (cvSkimEduData) {
      // Check if the start_date matches the desired format
      const datePatternRegex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (datePatternRegex.test(cvSkimEduData.start_date)) {
        setCVCredentials({
          start_date: new Date(cvSkimEduData.start_date),
        });
      } else {
        setCVCredentials({
          start_date: "",
        });
      }
      if (datePatternRegex.test(cvSkimEduData.end_date)) {
        setCVCredentials({
          end_date: new Date(cvSkimEduData.end_date),
        });
      } else {
        setCVCredentials({
          end_date: "",
        });
      }
      const cleanedTitle = cvSkimEduData.title ? cvSkimEduData.title.replace(/[^\w\s]/gi, "") : "";

      setCVCredentials({
        title: cleanedTitle,
        institute_name: cvSkimEduData.institute_name ? cvSkimEduData.institute_name : "",
        education_level: cvSkimEduData.education_level
          ? (() => {
              switch (cvSkimEduData.education_level) {
                case "Undergraduate":
                  return "BD";
                case "Postgraduate":
                  return "MD";
                case "Diploma":
                  return "DIP";
                case "High School":
                  return "HS";
                case "Associate Degree":
                  return "AD";
                case "Bachelor's Degree":
                  return "BD";
                case "Master's Degree":
                  return "MD";
                case "Professional Degree":
                  return "PD";
                case "PhD":
                  return "PHD";
                case "Doctorate":
                  return "PHD";
                case "Certification":
                  return "CERT";
                case "Vocational Training":
                  return "VC";
                case "Postgraduate Residency":
                  return "PR";
                case "Fellowship":
                  return "FC";
                case "Other":
                  return "OTH";
                // Add more cases as needed
                default:
                  return "Other";
              }
            })()
          : "",
        score: cvSkimEduData?.score ? cvSkimEduData?.score : "",
      });

      console.log("cvSkimData:", cvSkimEduData);
    }
  }, [cvSkimEduData]);

  const today = new Date();
  const tenYearsAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
  const minDate = tenYearsAgo.toISOString().split("T")[0];

  return (
    <>
      <EducationAccordian />
      <form className="my-5" id="education">
        <div className="row" style={{ marginTop: "1%" }}>
          <h4>
            <b>{t("education-heading")}</b>
          </h4>
          {Array.from({ length: educationCount }).map((_, index) => (
            <>
              <div key={index} className="col-md-6">
                <label htmlFor={`title`} className="form-label">
                  {t("education-degree")}*
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Intermediate"
                    name="title"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                    }}
                    type="text"
                    value={title || ""}
                    // value={title || ""}
                    onChange={onChangeCVCredentials}
                    variant="outlined"
                    fullWidth
                    error={
                      !fieldValidations.title &&
                      (!(title || cvSkimEduData?.title) ||
                        title?.length < 2 ||
                        /\d/.test(title) ||
                        !cvSkimEduData?.title)
                    }
                    helperText={
                      !fieldValidations.title &&
                      (!(title || cvSkimEduData?.title) ||
                        title?.length < 2 ||
                        /\d/.test(title) ||
                        !cvSkimEduData?.title) &&
                      "Enter a valid degree title!"
                    }
                    onKeyDown={(event) => handleEnterKeyPress(event, "institute_name")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="institute_name" className="form-label">
                  {t("education-institute")}*
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. Sidwell Friends School"
                    name="institute_name"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                    }}
                    // value={institute_name || cvSkimEduData?.institute_name || ""}
                    value={institute_name || ""}
                    onChange={onChangeCVCredentials}
                    id="institute_name"
                    variant="outlined"
                    fullWidth
                    // error={!fieldValidations.institute_name && !(institute_name || cvSkimEduData?.institute_name)}
                    error={!fieldValidations.institute_name}
                    helperText={
                      !fieldValidations.institute_name &&
                      // !(institute_name || cvSkimEduData?.institute_name) &&
                      "Enter a valid institute name!"
                    }
                    onKeyDown={(event) => handleEnterKeyPress(event, "education_level")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`education_level`} className="form-label">
                  {t("education-educationallevel")}*
                </label>
                <div className="mb-3">
                  <TextField
                    name="education_level"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                    }}
                    value={education_level || ""}
                    onChange={onChangeCVCredentials}
                    placeholder="Select Educational Level"
                    id={`education_level`}
                    select
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={!fieldValidations.education_level}
                    helperText={
                      !fieldValidations.education_level && "Please select education level"
                    }
                    onKeyDown={(event) => handleEnterKeyPress(event, "start_date")}
                  >
                    {educationalLevelOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`start_date${index}`} className="form-label">
                  {t("education-startDate")}*
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. BS"
                    name="start_date"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                      "& input::-webkit-calendar-picker-indicator": {
                        filter:
                          "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                      },
                      "& input[type='date']::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type='date']::-webkit-clear-button": {
                        display: "none",
                      },
                    }}
                    value={start_date || ""}
                    onChange={handleStartDateChange}
                    id={`start_date`}
                    variant="outlined"
                    fullWidth
                    type="date"
                    error={!fieldValidations.start_date}
                    InputProps={{
                      inputProps: {
                        max: minDate,
                      },
                    }}
                    helperText={!fieldValidations.start_date && "Enter start date!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "end_date")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor={`end_date${index}`} className="form-label">
                  {t("education-endDate")}*
                </label>
                <div className="mb-3">
                  <TextField
                    name="end_date"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                      "& input::-webkit-calendar-picker-indicator": {
                        filter:
                          "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                      },
                      "& input[type='date']::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type='date']::-webkit-clear-button": {
                        display: "none",
                      },
                    }}
                    value={end_date || ""}
                    onChange={onChangeCVCredentials}
                    id={`end_date`}
                    variant="outlined"
                    fullWidth
                    type="date"
                    error={!fieldValidations.end_date}
                    helperText={!fieldValidations.end_date && "Enter end date!"}
                    onKeyDown={(event) => handleEnterKeyPress(event, "score")}
                    disabled={!selectedStartDate || currentlyStudying}
                    inputProps={{
                      min: selectedStartDate,
                    }}
                  />
                </div>
                <label className="d-flex gap-2 mx-2 mb-2 ">
                  <input
                    type="checkbox"
                    checked={currentlyStudying}
                    onChange={handleCurrentlyStudyingToggle}
                  />
                  {t("currently-studying-here")}
                </label>
              </div>
              <div className="col-md-6">
                <label htmlFor={`score${index}`} className="form-label">
                  {t("education-score")}*
                </label>
                <div className="mb-3">
                  <TextField
                    placeholder="e.g. 770/1050"
                    name="score"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px",
                    }}
                    value={score || ""}
                    onChange={onChangeCVCredentials}
                    id="score"
                    variant="outlined"
                    fullWidth
                    error={!fieldValidations.score}
                    helperText={
                      !fieldValidations.score && "Enter a valid obtained marks/total marks!"
                    }
                    onKeyDown={(event) =>
                      handleEnterKeyPress(event, hasEducationData ? "nextButton" : "addButton")
                    }
                  />
                </div>
              </div>
            </>
          ))}

          <hr />
        </div>

        <div className="d-flex align-items-md-center gap-2 align-items-start justify-content-between mt-2 flex-md-row flex-column ">
          <button
            type="submit"
            // variant="outlined"
            className="login-form-button"
            onClick={handleMoveBack}
          >
            {t("back-btn")}
          </button>
          <div className="d-flex align-items-sm-center align-items-start flex-sm-row flex-column gap-2">
            <button
              type="submit"
              id="addButton"
              onClick={handleEducationSubmit}
              className="login-form-button"
            >
              {t("btn-add")}
            </button>

            {hasEducationData ? (
              <button
                type="submit"
                id="nextButton"
                variant="outlined"
                className="login-form-button"
                onClick={handleWithoutAuthEducationSubmit}
              >
                {t("btn-next")}
              </button>
            ) : null}
          </div>
        </div>
      </form>
      {isLoading ? <Progress /> : null}
    </>
  );
}

export default EducationalInfo;
