import React from "react";
import { UserLogin } from "../../../context/AuthContext";
import exclaimatory from "../../../assets/img/exclaimatory.svg";

function ReportSection6() {
  const { selectedAudioReport } = UserLogin();
  const grammarMistakes =
    selectedAudioReport?.analysis_data?.detailedMetrics?.CommonGrammarProblems;
  const transcriptions = selectedAudioReport?.transcriptions;

  // Prepare data for rendering
  const mistakesData = [
    { category: "Articles", mistakes: grammarMistakes?.Articles },
    { category: "Conditionals", mistakes: grammarMistakes?.Conditionals },
    { category: "Propositions", mistakes: grammarMistakes?.Propositions },
    { category: "Verb Constructions", mistakes: grammarMistakes?.["Verb Constructions"] },
  ];

  const hasMistakes = (transcription, mistakes) => {
    return (
      transcription && Object.keys(mistakes).some((mistake) => transcription.includes(mistake))
    );
  };

  const combinedMistakes = Object.values(grammarMistakes || {}).reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  const renderTranscriptionWithMistakes = (transcription, mistakes) => {
    let renderedTranscription = transcription;
    Object.entries(mistakes).forEach(([mistake, suggestion]) => {
      const regex = new RegExp(mistake, "gi");
      renderedTranscription = renderedTranscription.replace(
        regex,
        `<span style="color: red; text-decoration: line-through;">${mistake}</span>` +
          `<span style="color: green;"> ${suggestion}</span>`
      );
    });

    return { __html: renderedTranscription };
  };

  return (
    <div style={{ borderRadius: "22px" }}>
      {/* Common Mistakes in Grammar */}
      <div className="row mb-3">
        <div className="col-md-12" style={{ padding: "15px 0 0 0" }}>
          <div className="card" style={{ borderRadius: "25px" }}>
            <div className="card-body mx-4">
              <div style={{ fontWeight: "500", padding: "25px 0"}}>
                <h4 className="card-title mx-3 mt-2" style={{ fontWeight: "700" }}>
                  Common Grammar Mistakes
                </h4>
              </div>
              <div className="table-responsive">
                <table className="table" style={{ textAlign: "center" }}>
                  <thead style={{ color: "#606070", fontWeight: "700" }}>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Mistakes</th>
                      <th scope="col">Suggestions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mistakesData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.category}</td>
                        <td>{Object.keys(item.mistakes || {}).join(", ")}</td>
                        <td>{Object.values(item.mistakes || {}).join(", ")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mistakes in Detail */}
      <div className="row mb-3">
        <div className="col-md-12" style={{ padding: "15px 0 0 0" }}>
          <div className="card" style={{ borderRadius: "22px" }}>
            <div className="card-body mx-4 ">
              <div
                style={{
                  fontWeight: "bold",
                  padding: "25px 0",
                }}
              >
                <h8 className="card-title mx-2">Mistakes in detail</h8>
              </div>
              <div
                style={{
                  backgroundColor: "#f1f1f8",
                  borderRadius: "10px",
                  padding: "20px 30px 10px 10px"
                }}
              >
                <div className="d-flex align-items-center mb-3 gap-1 mx-2">
                  <img src={exclaimatory} alt="exclaimatory_image" style={{ width: "2%" }} /> &nbsp;
                  <p className="m-0 fw-bold"> Experimental block</p>
                </div>
                <p className="mx-2">
                  This block serves for experimental purposes, and some of our English learners
                  consider it useful. If you spot any words you haven’t pronounced, please check
                  your environment for unexpected noise and try to speak clearly. These issues won’t
                  impact your overall score.
                </p>
              </div>
              <div class="label-grid ms-2 my-5">
                <strike class="grid-item mistake">Mistake</strike>
                <span class="grid-item speech-error">Speech error</span>
                <span class="grid-item suggestion">Suggestion</span>
              </div>
              <div className="row">
                <div className="col-md-12 mx-3 my-2">
                  {transcriptions?.map((transcript, index) => {
                    if (hasMistakes(transcript.transcription, combinedMistakes)) {
                      return (
                        <div key={index}>
                          <p
                            dangerouslySetInnerHTML={renderTranscriptionWithMistakes(
                              transcript.transcription,
                              combinedMistakes
                            )}
                          ></p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSection6;
