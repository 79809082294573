import React from "react";
import { UserLogin } from "../../../context/AuthContext";
import mic from "../../../assets/img/mic.svg";
import meterBlue from "../../../assets/img/speakingRate (1).svg";
import meterBlack from "../../../assets/img/speakingRate (2).svg";

function ReportSection5_1() {
  const { selectedAudioReport } = UserLogin();
  const speakingRateString =
    selectedAudioReport?.analysis_data?.detailedMetrics?.Fluency?.SpeakingRate;
  const speakingRate = parseInt(speakingRateString);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12" style={{ padding: "15px 0 0 0" }}>
          <div
            className="card"
            style={{
              borderTopRightRadius: "25px",
              borderTopLeftRadius: "25px",
              background: "rgb(249, 249, 250)",
              border: "none",
            }}
          >
            <div className="card-body mx-4 ">
              <div style={{ fontWeight: "bold", padding: "25px 0" }}>
                <h5 className="card-title" style={{ fontWeight: "bold" }}>
                  <img src={mic} alt="trophy img" /> &nbsp; Speaking rate (words per minute)
                </h5>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col">
                      <img
                        src={meterBlue}
                        alt="blue meter"
                        width={50}
                        height={50}
                        style={{ float: "left" }}
                      />
                      <div
                        className="card-text mt-2"
                        style={{ color: "#9E9E9E", padding: "0 0 0 60px" }}
                      >
                        <p style={{ color: "#787878" }}>
                          <span style={{ color: "#38B6FF", fontSize: "30px", fontWeight: "700" }}>
                            {speakingRate}
                          </span>
                          <span style={{ fontSize: "14px" }}> My speaking rate</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col">
                      <img
                        src={meterBlack}
                        alt="blue meter"
                        width={50}
                        height={50}
                        style={{ float: "left" }}
                      />
                      <p
                        className="card-text mt-2"
                        style={{ color: "#9E9E9E", padding: "0 0 0 60px" }}
                      >
                        <p style={{ color: "#787878" }}>
                          <span style={{ color: "#4D4D4D", fontSize: "30px", fontWeight: "700" }}>
                            90-150
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            &nbsp; Common speaking rate for native speakers in the US
                          </span>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Progress Bar */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportSection5_1;
