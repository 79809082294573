import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { AUTH_API_URL } from "../../../Auth_API";
import { BsChevronDown } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";
import { Link } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";

function FP_Screen3() {
  let navigation = useNavigate();
  const location = useLocation();
  const { reset_pass_email } = location.state || {};

  const {
    isLoading,
    setIsLoading,
    error,
    setError,
    updateError,
    isValidObjField,
    showToast,
    setIsErrorOpen,
    showPassword,
    setShowPassword,
    isTypingPassword,
    setIsTypingPassword,
    passwordMismatch,
    setPasswordMismatch,
    passwordRequirementsError,
    setPasswordRequirementsError,
  } = UserLogin();

  const [credentials, setCredentials] = useState({
    password: "",
    cpassword: "",
  });
  const { password, cpassword } = credentials;
  const [showToastMsg,setShowToastMsg] = useState(true);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  /* Function to reset password */
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        return updateError("Require all fields!", setError);
      }
      if (!password.trim() || password.length < 5) {
        return updateError("Password must be 5 character long!", setError);
      }
      if (password !== cpassword) {
        return updateError("Passwords do not match!", setError);
      }

      const meetsLengthRequirement = password.length >= 8;
      const meetsUppercaseRequirement = /[A-Z]/.test(password);
      const meetsLowercaseRequirement = /[a-z]/.test(password);
      const meetsNumericRequirement = /[0-9]/.test(password);

      if (
        !meetsLengthRequirement ||
        !meetsUppercaseRequirement ||
        !meetsLowercaseRequirement ||
        !meetsNumericRequirement
      ) {
        updateError("Password does not meet requirements", setError);
        setPasswordRequirementsError(true);
        return;
      }

      setIsLoading(true);
      const response = await fetch(`${AUTH_API_URL}/change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: reset_pass_email,
          new_password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        showToast("Your Password changed. Please Login to continue!");
        navigation("/");
        setIsLoading(false);
        console.log("Password message:", data);
      } else {
        console.error("Failed to reset password!", error);
        showToast("Failed to reset password!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to reset password!", error);
      showToast("Failed to reset password!");
      setIsLoading(false);
    }
  };

  const handlePasswordFocus = () => {
    setIsTypingPassword(true);
  };

  const handlePasswordBlur = () => {
    setIsTypingPassword(false);

    if (password.trim() && cpassword.trim()) {
      if (password !== cpassword) {
        setPasswordMismatch(true);
      } else {
        setPasswordMismatch(false);
      }
    } else {
      setPasswordMismatch(false);
    }
  };

  /* Dropdown for password validations */
  const getPasswordRequirements = () => {
    const meetsLengthRequirement = password.length >= 8;
    const meetsUppercaseRequirement = /[A-Z]/.test(password);
    const meetsLowercaseRequirement = /[a-z]/.test(password);
    const meetsNumericRequirement = /[0-9]/.test(password);

    return (
      <>
        <div className="password-requirements">
          <div className="row">
            <div className="col-11">
              <h5>Default password requirements:</h5>
            </div>
            <div className="col-1">
              <BsChevronDown size={15} color="#66B855" />
            </div>
          </div>
          <ul>
            <li
              className={meetsLengthRequirement ? "list-no-style" : ""}
              style={{ color: meetsLengthRequirement ? "green" : "inherit" }}
            >
              {meetsLengthRequirement ? <FaCheck /> : null} Must be at least 8 characters long
            </li>
            <li
              className={meetsUppercaseRequirement ? "list-no-style" : ""}
              style={{ color: meetsUppercaseRequirement ? "green" : "inherit" }}
            >
              {meetsUppercaseRequirement ? <FaCheck /> : null} Must include at least one English
              uppercase character (A-Z)
            </li>
            <li
              className={meetsLowercaseRequirement ? "list-no-style" : ""}
              style={{ color: meetsLowercaseRequirement ? "green" : "inherit" }}
            >
              {meetsLowercaseRequirement ? <FaCheck /> : null} Must include at least one English
              lowercase character (a-z)
            </li>
            <li
              className={meetsNumericRequirement ? "list-no-style" : ""}
              style={{ color: meetsNumericRequirement ? "green" : "inherit" }}
            >
              {meetsNumericRequirement ? <FaCheck /> : null} Must include at least one numeric
              character (0-9)
            </li>
          </ul>
        </div>
      </>
    );
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setCredentials({ ...credentials, password: newPassword });

    const meetsLengthRequirement = newPassword.length >= 8;
    const meetsUppercaseRequirement = /[A-Z]/.test(newPassword);
    const meetsLowercaseRequirement = /[a-z]/.test(newPassword);
    const meetsNumericRequirement = /[0-9]/.test(newPassword);

    if (
      meetsLengthRequirement &&
      meetsUppercaseRequirement &&
      meetsLowercaseRequirement &&
      meetsNumericRequirement
    ) {
      setPasswordRequirementsError(false);
      updateError("", setError);
    } else {
      setPasswordRequirementsError(true);
      updateError("Password does not meet requirements", setError);
    }

    if (cpassword.trim() && newPassword !== cpassword) {
      setPasswordMismatch(true);
      updateError("Passwords do not match", setError);
    } else {
      setPasswordMismatch(false);
      updateError("", setError);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setCredentials({ ...credentials, cpassword: newConfirmPassword });

    if (password.trim() && password !== newConfirmPassword) {
      setPasswordMismatch(true);
      updateError("Passwords do not match", setError);
    } else {
      setPasswordMismatch(false);
      updateError("", setError);
    }
  };

  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-center">
              <div className="col-9 ps-4">
                <img
                  src={onbLogo}
                  alt="logo"
                  className="login-form-logo"
                />
              </div>
              <div className="login-form-container col-9 mt-1 shadow-none">
                <div className="login-form-body">
                  <div className="d-flex align-items-center mb-2">
                    <ArrowBack onClick={() => navigation("/")} style={{ cursor: "pointer" }} />
                    <p
                      className="m-0 p-0"
                      onClick={() => navigation("/")}
                      style={{ cursor: "pointer", color: "grey" }}
                    >
                      Back to log in
                    </p>
                  </div>
                  <div
                    className={`col-11 justify-content-evenly align-items-center rounded-1 py-2 ${showToastMsg ? "d-flex" : "d-none"}`}
                    style={{
                      opacity: "0.9",
                      background: "rgba(80, 180, 130, 0.12)",
                    }}
                  >
                    <CheckIcon fontSize="small" />
                    <p className="m-0 p-0"> OTP has been verfied. Please set a new password</p>
                    <CloseIcon fontSize="small" onClick = {()=> setShowToastMsg(false)} />
                  </div>
                  <h4 className="fw-bold text-capitalize mt-3">Reset your password</h4>
                  <p className="m-0 p-0 text-secondary">Please enter your new password.</p>
                  <hr />

                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Password</label>
                    <div className="col-12 password-textfield p-0 m-0">
                      <TextField
                        className="col-12 m-0"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        placeholder="Password*"
                        value={password}
                        onChange={handlePasswordChange}
                        margin="normal"
                        aria-autocomplete="none"
                        required
                        autoComplete="off"
                        error={
                          (error && (!password.trim() || password.length < 5)) ||
                          passwordRequirementsError
                        }
                        helperText={
                          (error &&
                            (!password.trim() || password.length < 5) &&
                            "Password must be at least 5 characters long!") ||
                          (passwordRequirementsError && "Password does not meet requirements")
                        }
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePassword} edge="end">
                                {showPassword ? (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  {isTypingPassword && password.length > 0 && getPasswordRequirements()}

                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Confirm Password</label>
                    <div className="col-12 password-textfield p-0 m-0">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm Password*"
                        name="cpassword"
                        className="col-12 m-0"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        id="cpassword"
                        value={cpassword}
                        onChange={handleConfirmPasswordChange}
                        margin="normal"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleResetPassword();
                          }
                        }}
                        required
                        autoComplete="off"
                        error={error || passwordMismatch}
                        helperText={(passwordMismatch && "Passwords do not match!") || ""}
                        onBlur={handlePasswordBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePassword} edge="end">
                                {showPassword ? (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <button
                    onClick={handleResetPassword}
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    disabled={isLoading}
                  >
                    Reset password
                  </button>

                  <p className="m-0 mt-3 p-0 d-flex gap-2 text-secondary">
                    Don't have an account please
                    <span>
                      <Link
                        // onClick={() => setIsLoginScreen(false)}
                        to="/register"
                        className="text-decoration-underline"
                        style={{ color: "#56367f", fontWeight: "600" }}
                      >
                        Sign up
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
            <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={loginStatsImg} alt="stats" className="login-stats" />
          </div>
        </div>
      </div>
    </>
  );
}

export default FP_Screen3;
