import React, { useEffect, useRef, useState } from "react";
import "./testimonials.css";
import t1 from "../../../assets/img/t1.png";
import t2 from "../../../assets/img/t2.png";
import t3 from "../../../assets/img/t3.png";
import t4 from "../../../assets/img/t4.png";
import Fade from "react-reveal/Fade";
import ellipse from "../../../assets/img/ellipse-test.svg";
import Marquee from "react-fast-marquee";

const testimonials = [
  {
    text: "I find it incredibly useful to be able to highlight and annotate specific parts of the content with just a click, making it easy to capture and organize key information.",
    name: "Sarah Leon",
    title: "High School Teacher, US",
    rating: 5,
    image: t1,
  },
  {
    text: "They have greatly saved my life and is still doing so! So I am highly appreciative of all Otter’s services.",
    name: "Alexandra Smith",
    title: "Medical billing specialist, France",
    rating: 5,
    image: t2,
  },
  {
    text: "I really like the ability to highlight and write notes on specific things the professor is saying by just clicking and writing.",
    name: "Chloe Taylor",
    title: "Software Engineer, Dubai",
    rating: 5,
    image: t3,
  },
  {
    text: "After four years... I finally broke down... and got an workistan Premium account. I hope you’re happy Otter...",
    name: "Michael Simpson",
    title: "Infotech CEO, Australia",
    rating: 5,
    image: t4,
  },
  {
    text: "They are actually the most reliable service. Not sponsored, no bias, just fact!",
    name: "Sarah Leon",
    title: "Business Owner, US",
    rating: 4,
    image: t1,
  },
  {
    text: "They are actually the most reliable service. Not sponsored, no bias, just fact!",
    name: "Alexandra Smith",
    title: "Technical Consultant, UK",
    rating: 5,
    image: t2,
  },
];

const Testimonials = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollAmount = 0;
    const speed = 2; // Adjust scroll speed as needed

    const autoScroll = () => {
      if (container) {
        scrollAmount += speed;
        container.scrollTo({ left: scrollAmount });

        if (scrollAmount >= container.scrollWidth / 2) {
          scrollAmount = 0;
          container.scrollTo({ left: 0 });
        }
      }
    };

    const intervalId = setInterval(autoScroll, 20); // Adjust interval for smoother scrolling
    return () => clearInterval(intervalId);
  }, []);

  const renderStars = (rating) => {
    return "★".repeat(rating) + "☆".repeat(5 - rating);
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center" id="testimonials">
        <img src={ellipse} className="ellipse-test" alt="Ellipse Background" />
        <Fade top appear>
          <div className="d-flex flex-column align-items-center col-sm-12 col-10">
            <p className="testimonials-paragraph text-center py-2">OUR TESTIMONIALS</p>
            <h3 className="pricing-heading text-white text-capitalize">
              Success stories from our happy clients
            </h3>
            <p className="paragraph-testimonials mt-0">
              At OnboardIQ, we’re proud of the partnerships we’ve built and the impact we’ve had on
              companies just like yours. Here’s what some of our clients have to say about how
              OnboardIQ has transformed their hiring processes:
            </p>
          </div>
        </Fade>
        <div className="testimonial-slider">
          <Marquee loop={999} pauseOnHover speed={100}>
            <div className="testimonial-row" ref={containerRef}>
              {testimonials.map((testimonial, index) => (
                <div className="testimonial d-flex flex-column justify-content-between" key={index}>
                  <div>
                    <p className="testimonial-rating m-0">{renderStars(testimonial.rating)}</p>
                    <p className="testimonial-text">"{testimonial.text}"</p>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <img
                      className="testimonial-image"
                      src={testimonial.image}
                      alt={testimonial.name}
                    />
                    <div>
                      <p className="testimonial-name m-0">{testimonial.name}</p>
                      <p className="testimonial-title m-0">{testimonial.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
