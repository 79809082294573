import React,{useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import tcBlocks from "../../assets/img/tcBlocks.svg";
import tcPolygonBottomLeft from "../../assets/img/tcPolygonBottomLeft.svg";
import tcPolygonTopLeft from "../../assets/img/tcPolygonTopLeft.svg";
import tcRightPolygon from "../../assets/img/tcRightPolygon.svg";
import tcCells from "../../assets/img/tcCells.png";
import termsDot from "../../assets/img/terms-point-dot.svg";
import ArrowBack from "@mui/icons-material/ArrowBackIos";


function Terms() {
  let navigate = useNavigate();
  const location = useLocation();

  const termsAndConditions = [
    {
      heading: "Acceptance of Terms",
      points: [
        {
          heading: "",
          text: "By using the onboardiq, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions, as well as any additional terms and policies incorporated herein by reference.",
        },
      ],
    },
    {
      heading: "Use of Service",
      points: [
        {
          heading: "Eligibility",
          text: "You must be of legal age and have the authority to enter into these Terms and Conditions on behalf of yourself or the entity you represent By using the Language Ability Tester, you warrant that you meet these eligibility requirements 2.2 License Subject to these Terms and Conditions, OnboardIQ grants you a limited, non-exclusive, non-transferable license to access and use the Language Ability Tester for the purpose of assessing language abilities during candidate interviews.",
        },
        {
          heading: "License",
          text: "Subject to these Terms and Conditions, OnboardIQ grants you a limited, non-exclusive, non transferable license to access and use the language ability tester for the purpose of accessing language abilities during candidate interviews.",
        },
        {
          heading: "User Responsibilities",
          text: "You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to use the Language Ability Tester in compliance with applicable laws regulations, and these Terms and Conditions.",
        },
      ],
    },
    {
      heading: "Intellectual Property Rights",
      points: [
        {
          heading: "Ownership",
          text: "OnboardIQ retains all rights, litle, and interest in and to the Language Ability Tester, including afl associated intellectual property rights.",
        },
        {
          heading: "User Content",
          text: "By using the Language Ability Tester Saas Product, you grant OnboardIQ a worldwide, non-exclusive, royalty-free license to use reproduce, modify, adapt, publish, translate distribute, and display any content you submit, upload, or transmit through the platform, solely for the purpose of providing the service.",
        },
      ],
    },
    {
      heading: "Privacy",
      points: [
        {
          heading: "Data Collection",
          text: "OnboardIQ may collect and process personal information as described in our Privacy Policy. By using the Language Ability Tester, you consent to the collection use, and disclosure of your personal information in accordance with our Privacy Policy.",
        },
        {
          heading: "Confidentiality",
          text: "OnboardIQ will maintain the confidentiality of any candidate responses of assessment data coflected through the Language Ability Tester, in accordance with applicable laws and our Privacy Policy.",
        },
      ],
    },
    {
      heading: "Limitation of Liability",
      points: [
        {
          heading: "",
          text: "To the fullest extent permitted by law, OnboardIQ shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill arising out of or in connection with your use of the Language Ability Tester.",
        },
      ],
    },
    {
      heading: "Modifications to Terms & Conditions",
      points: [
        {
          heading: "",
          text: "OnboardiQ reserves the night to modify or revise these Terms and Conditions at any time, without prior notice Your continued use of the Language Ability Tester following the posting of any changes constitutes acceptance of those changes.",
        },
      ],
    },
    {
      heading: "Contact Us",
      points: [
        {
          heading: "",
          text: "If you have any questions about these Terms and Conditions, please contact us at support@onboardiq.io. By using the Language Ability Tester, you agree to abide by these Terms and Conditions. Thank you for choosing OnboardiQ",
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    location.state.credentials?.target === "website" ? navigate("/") :
    navigate("/Register", {
      replace: true,
      state: {
        credentials: location.state.credentials,
        organizationID: location.state.organizationID,
      },
    });
  };

  return (
    <div className="d-flex flex-column align-items-center terms-page">
      <div class="terms-header-container col-12 d-flex flex-column gap-2 align-items-center justify-content-center">
        <h1 className="fw-bold text-center text-white">Terms & Conditions</h1>
        <p className="text-center">Last updated july 17,2024</p>
        <img src={tcPolygonTopLeft} alt="" className="tc-topleft-poly" />
        <img src={tcBlocks} alt="" className="tc-blocks" />
        <img src={tcCells} alt="" className="tc-cells" />
        <img src={tcRightPolygon} alt="" className="tc-right-poly" />
        <img src={tcPolygonBottomLeft} alt="" className="tc-bottomleft-poly" />
        <div className="terms-back-btn" onClick={handleBack}>
          <ArrowBack className="terms-back-icon"/>
        </div>
      </div>

      <svg width="0" height="0">
        <defs>
          <clipPath id="rounded-bottom-clip" clipPathUnits="objectBoundingBox">
            <path d="M0,0 H1 V0.8 C0.5,1 0.5,1 0,0.8 Z" />
          </clipPath>
          <clipPath id="rounded-bottom-clip-mobile" clipPathUnits="objectBoundingBox">
            <path d="M0,0 H1 V0.9 C0.5,1 0.5,1 0,0.9 Z" />
          </clipPath>
        </defs>
      </svg>

      <p className="col-sm-10 col-11 mt-2">
        Welcome to OnboardIQ's Language Ability Tester. These Terms and Conditions govem your use of
        our service, so please read them carefully before accessing or using our platform. By
        accessing or using the Language Ability Tester, you agree to be bound by these Terms and
        Conditions. If you disagree with any part of these terms, then you may not access the
        service
      </p>

      <div className="d-flex justify-content-start col-sm-10 col-11">
        <ol>
          {termsAndConditions.map((section, headingIndex) => (
            <li key={headingIndex} className="mt-5 terms-list-heading">
              <h3>{section.heading}</h3>
              <ul className="terms-point-list">
                {section.points.map((point, pointIndex) =>
                  point.heading.length > 0 ? (
                    <li key={pointIndex} className="d-flex align-items-start ">
                      <div
                        className="d-flex align-items-center gap-2"
                        style={{ minWidth: "fit-content" }}
                      >
                        <img src={termsDot} alt="" style={{ width: "15px" }} />
                        <p className="fw-bold m-0">{`${headingIndex + 1}.${pointIndex + 1}`}</p>
                        <p className="fw-bold m-0 point-heading">{point.heading}: </p>
                      </div>
                      <p className="m-0 p-0 ms-1">{point.text}</p>
                    </li>
                  ) : (
                    <li key={pointIndex} className="d-flex align-items-start">
                      <div
                        className="d-flex align-items-start gap-2"
                        style={{ minWidth: "fit-content" }}
                      >
                        <img className="mt-1" src={termsDot} alt="" style={{ width: "15px" }} />
                        <p className="m-0 p-0 ms-2">{point.text}</p>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </li>
          ))}
        </ol>
      </div>

      <div className="col-sm-10 col-11 d-flex align-items-center justify-content-end my-3">
        <button
          className="login-form-button px-5"
          onClick={() =>
            navigate("/privacy_policy", {
              replace: true,
              state: {
                credentials: location.state.credentials,
                organizationID: location.state.organizationID,
              },
            })
          }
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Terms;
