import React, { useState } from "react";
import "./faqs.css";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="faqs">
      <div className="d-flex flex-column px-lg-0 px-2 align-items-center faqs-content">
        <div>
          <Fade top appear>
            <p className="faqs-paragraph">FAQ's</p>
            <h3 className="pricing-heading text-white text-capitalize">
              Frequently Asked Questions
            </h3>

            <p className="paragraph-faqs mt-0">
              Get quick answers to common queries about OnboardIQ’s features, security, and
              integration here. Need more info? We’re just a click away.
            </p>

            <button className="get-started" onClick={() => navigate('/contact')}>CONTACT US TODAY</button>
          </Fade>
        </div>

        <div className="accordion">
          <Fade right appear>
            <div className="accordion-item">
              <button
                className={`accordion-button ${activeIndex === 0 ? "active" : ""}`}
                onClick={() => toggleAccordion(0)}
              >
                What makes OnboardIQ different from other hiring platforms?
                <i className={`bi bi-caret-${activeIndex === 0 ? "down" : "up"}-fill`}></i>
              </button>
              <div className={`accordion-content ${activeIndex === 0 ? "show" : ""}`}>
                <p>
                  OnboardIQ combines advanced profiling, real-world task simulation, and a
                  comprehensive analytics dashboard to provide a deeper understanding of each
                  candidate, setting it apart from traditional hiring tools.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className={`accordion-button ${activeIndex === 1 ? "active" : ""}`}
                onClick={() => toggleAccordion(1)}
              >
                How does OnboardIQ ensure the security of our data?
                <i className={`bi bi-caret-${activeIndex === 1 ? "down" : "up"}-fill`}></i>
              </button>
              <div className={`accordion-content ${activeIndex === 1 ? "show" : ""}`}>
                <p>
                  OnboardIQ uses state-of-the-art encryption and follows industry best practices to
                  ensure that your data is secure and confidential.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className={`accordion-button ${activeIndex === 2 ? "active" : ""}`}
                onClick={() => toggleAccordion(2)}
              >
                Can I integrate OnboardIQ with my existing HR software?
                <i className={`bi bi-caret-${activeIndex === 2 ? "down" : "up"}-fill`}></i>
              </button>
              <div className={`accordion-content ${activeIndex === 2 ? "show" : ""}`}>
                <p>
                  Yes, OnboardIQ is designed to seamlessly integrate with your existing HR software,
                  providing a smooth and efficient hiring process.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className={`accordion-button ${activeIndex === 3 ? "active" : ""}`}
                onClick={() => toggleAccordion(3)}
              >
                What types of organizations can use OnboardIQ?
                <i className={`bi bi-caret-${activeIndex === 3 ? "down" : "up"}-fill`}></i>
              </button>
              <div className={`accordion-content ${activeIndex === 3 ? "show" : ""}`}>
                <p>
                  OnboardIQ is suitable for organizations of all sizes and industries, from small
                  businesses to large enterprises.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button
                className={`accordion-button ${activeIndex === 4 ? "active" : ""}`}
                onClick={() => toggleAccordion(4)}
              >
                How do I get started with OnboardIQ?
                <i className={`bi bi-caret-${activeIndex === 4 ? "down" : "up"}-fill`}></i>
              </button>
              <div className={`accordion-content ${activeIndex === 4 ? "show" : ""}`}>
                <p>
                  Getting started with OnboardIQ is easy. Contact us today to set up a demo and see
                  how OnboardIQ can transform your hiring process.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
