import {useState} from "react";
import "./footer.css";
import logo from "../../../assets/img/Onboardiq-logo.png";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigation = useNavigate();
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    target: "website",
  });
  const navigateToTnC = () => {
    navigation("/tnc", { state: { credentials } });
  };
  const navigateToPrivacy = () => {
    navigation("/privacy_policy", { state: { credentials } });
  };

  return (
    <footer className="footerx d-flex flex-column " id="footer">
      <div className="">
        <Fade left appear>
          <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center">
            <div className="footer-left col-lg-5 col-12">
              <img src={logo} alt="Logo" className="footer-logo" />
              <p className="newsletter-para">
                Join our newsletter to stay up to date on features and releases.
              </p>
              <form className="footer-form">
                <input type="email" placeholder="Enter your email" />
                <button type="submit">Subscribe</button>
              </form>
              <p className="footer-consent">
                By subscribing, you agree to with our Privacy Policy and provide consent to receive
                updates from our company.
              </p>
            </div>
            <div className="footer-links mt-3 col-lg-6 ms-lg-3 ms-0 col-12 d-flex justify-content-center">
              <div className="footer-column align-items-center col-4 ps-sm-0 ps-2">
                <h4 className="fw-bold">Links</h4>
                <ul className="text-center">
                  <li>
                    <a href="#hero-website">Home</a>
                  </li>
                  <li>
                    <a href="#about">About Us</a>
                  </li>
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#testimonials">Testimonials</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column align-items-center col-4">
                <h4 className="fw-bold">Pricing Plans</h4>
                <ul className="text-center">
                  <li>
                    <a href="#pricing-plan">Starter</a>
                  </li>
                  <li>
                    <a href="#pricing-plan">Professional</a>
                  </li>
                  <li>
                    <a href="#pricing-plan">Enterprise</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column align-items-center col-4 ps-sm-0 ps-2">
                <h4 className="fw-bold">Follow Us</h4>
                <ul className="social-links text-center">
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i className="fab fa-facebook"></i>Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i className="fab fa-instagram"></i>Instagram
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i className="fab fa-twitter"></i>Twitter
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i className="fab fa-linkedin"></i>LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <div>
        <Fade right appear>
          <div className="footer-bottom">
            <p>&copy; 2024 OnboardIQ. All rights reserved.</p>
            <div className="footer-bottom-links">
              <p style={{cursor:"pointer"}} cll onClick={navigateToPrivacy}>Privacy Policy</p>
              <p style={{cursor:"pointer"}} cll onClick={navigateToTnC}>Terms of Service</p>
            </div>
          </div>
        </Fade>
      </div>
    </footer>
  );
};

export default Footer;
