import React from "react";
import hire from "../../../assets/img/hire.png";
import "./hero.css";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="my-0 " id="hero-website">
      <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center">
        <div className="col-lg-6 col-10 d-flex align-items-lg-end align-items-center flex-column">
          <Fade left appear>
            <div className="discover-content col-lg-9 col-10 me-4">
              <p className="paragraph-discover">DISCOVER BRILLIANCE IN EVERY HIRE</p>
              <p className="hiring-para text-lg-start text-center">
                Transform Your Hiring <span className="span-gradient">Process With</span> Onboard.IQ
              </p>
              <p className="paragraph-hiring" style={{color:"#b5b5c3"}}>
                Forget the old way of manually going through endless CVs. OnboardIQ transforms your
                hiring process into an enlightening journey of discovery. Our advanced platform
                doesn’t just evaluate; it uncovers the hidden potential in each candidate, ensuring
                you see more than just qualifications—you see the future contributors to your
                company’s success.
              </p>
              <div className="buttons-start-video mb-lg-0 mb-2">
                <button className="get-started" onClick={()=> navigate("/login")}>GET STARTED NOW</button>
                {/* <button className="watch-video gap-2"> <img src={video} width={50} height={50} className="mx-2" alt="" />WATCH VIDEO</button> */}
              </div>
            </div>
          </Fade>
        </div>
        <div className="right-div-image m-0 col-lg-6 col-12 mb-sm-0 mb-5 d-flex justify-content-start">
          <Zoom appear>
            <img src={hire} alt="Hire Illustration" />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Hero;
