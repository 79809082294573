import React from "react";
import onbLogo from "../../assets/img/onbLogoPng.png";
import polygonTL from "../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../assets/img/loginStatsPic.png";
import TickImg from "../../assets/img/tickSvg.svg";
import { useNavigate } from "react-router";

const VerificationDone = () => {
  const navigate = useNavigate();
  return (
    <div className="login-main">
      <div className="login-page-container d-flex justify-content-center gap-0 col-12">
        <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
          <div className="login-page-form mt-3 col-md-10 col-12 d-flex flex-column justify-content-center align-items-center">
            <div className="login-form-body d-flex flex-column align-items-center gap-3">
              <img src={TickImg} alt="" />
              <p
                className="text-uppercase mt-2 m-0 tracking-normal"
                style={{
                  letterSpacing: "1px",
                  fontSize: "medium",
                  fontWeight: "500",
                }}
              >
                congratulations!
              </p>
              <h3 className="fw-bold text-capitalize">Thanks for verifying</h3>
            </div>
            <button onClick={() => navigate("/login")} className="login-form-button col-10 mt-3">
              Let's go
            </button>
          </div>
        </div>

        <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
          <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
          <img src={polygonTL} alt="polygon" className="polygon-top-left" />
          <img src={cellsTR} alt="cells" className="cells-top-right" />
          <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
          <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
          <img src={loginStatsImg} alt="stats" className="login-stats" />
        </div>
      </div>
    </div>
  );
};

export default VerificationDone;
