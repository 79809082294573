import React from "react";
import "./stats.css";
import meter from "../../../assets/img/meterPic.png";
import { Fade } from "react-reveal";

const Stats = () => {
  return (
    <div className="d-flex justify-content-center" id="stats">
      <div className=" d-flex flex-column col-10 align-items-lg-start align-items-center justify-content-center stats-content">
        {/* <img src={ellipse} className="ellipse-stats" alt="Ellipse Background" /> Add the ellipse image */}
        <Fade top appear>
          <p className="stats-paragraph">SOME SNAPS OF THE STATS SHOWN BY ONBOARDIQ</p>
          <h3 className="features-heading text-white">Pronounce and Fluency</h3>
        </Fade>

        <Fade left appear>
          <div className="speaking-rate d-flex flex-md-row flex-column align-items-center">
            <div className="rate-section ">
              <img src={meter} className="timer-image" alt="timer" />
              <div className="rate-content">
                <div className="rate-title">Speaking rate (words per minute)</div>
                <div className="rate-unit">My Speaking Rate</div>
                <div className="rate-value">120</div>
              </div>
            </div>
            <div className="rate-divider"></div>
            <div className="rate-section">
              <img src={meter} className="timer-image" alt="timer" />
              <div className="rate-content text-center">
                <div className="rate-title">
                  <p>Common Speaking rate for native speakers in the US</p>
                </div>
                <div className="rate-value">90-150</div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Stats;
