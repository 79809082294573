import React, { useState, useEffect } from "react";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import Copyright from "../Home/HomeSections/Section5/Copyright";

function PILastScreen() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div
        className="pi-top-main"
        style={{ textAlign: "center", background: "#56367f", padding: "35px" }}
      >
        <h2 style={{ color: "white" }}>
          <b>{t("pi-heading-secondpage")}®</b>
        </h2>
        <label className="form-label mt-2">
          <div style={{ color: "white", fontWeight: "bold" }}>{t("pi-last-page-heading")}</div>
        </label>
      </div>

      <div className="PI-container" style={{ height: "100vh" }}>
        <>
          <p className="mt-4">{t("pi-last-page-p")}</p>
        </>
        <div>
          <p>{t("pi-last-page-thanku")}</p>
        </div>

        {/* Buttons */}
        <div className="d-flex justify-content-end">
          <Link className="login-form-button text-decoration-none py-2 " to="/Main">
            {t("finish-btn")}
          </Link>
        </div>
      </div>
      <Copyright />
    </>
  );
}

export default PILastScreen;
