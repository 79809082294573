import { UserLogin } from "../../../context/AuthContext";
import { USER_PROFILE_URL } from "../../../Auth_API";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function EducationAccordian() {
  const { t } = useTranslation();
  const { educationData, setEducationData, userData, educationalLevelOptions, isValidDate } =
    UserLogin();
  const [selectedEducationId, setSelectedEducationId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editEducationModalOpen, setEditEducationModalOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");

  const { id, token } = userData || {};
  const [formData, setFormData] = useState({
    title: "",
    institute_name: "",
    education_level: "",
    start_date: "",
    end_date: "",
    score: "",
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
    institute_name: false,
    education_level: false,
    start_date: false,
    end_date: false,
    score: false,
  });
  const today = new Date();
  const tenYearsAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
  const minDate = tenYearsAgo.toISOString().split("T")[0];

  const [currentlyStudying, setCurrentlyStudying] = useState(false);
  const handleCurrentlyStudyingToggle = () => {
    setCurrentlyStudying(!currentlyStudying);
  };

  const isValidScore = (value, minValue, maxValue) => {
    if (!value) {
      return false;
    }

    const scorePattern = /^(\d+(\.\d+)?\/\d+(\.\d+)?)$/;
    const gradePattern = /^[A-F]$/;

    const isNumericScore = scorePattern.test(value);
    const isGrade = gradePattern.test(value);

    if (!(isNumericScore || isGrade)) {
      return false;
    }

    if (isGrade && value.length !== 1) {
      // If it's a grade, it should be a single character.
      return false;
    }

    if (isNumericScore) {
      const matchScore = value.match(scorePattern);
      if (!matchScore) {
        return false;
      }

      const [obtainedScore, totalScore] = matchScore[0].split("/").map(parseFloat);

      return (
        !isNaN(obtainedScore) &&
        obtainedScore > 0 &&
        !isNaN(totalScore) &&
        obtainedScore >= minValue &&
        obtainedScore <= maxValue &&
        totalScore > 0 &&
        obtainedScore <= totalScore
      );
    }

    // If it's a single character grade, no further validation is needed.
    return true;
  };

  const onChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Validate input using regex
    let error = false;
    switch (name) {
      case "title":
        error =
          !/^[a-zA-Z0-9\s'!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/i.test(value) || /^\d+$/.test(value) || value.length < 3;
        break;
      case "institute_name":
        error = !/^[a-zA-Z\s]+$/.test(value);
        break;
      case "education_level":
        error = !/^[a-zA-Z\s]+$/.test(value);
        break;
      case "start_date":
        error = !isValidDate(value) || value === "" ? true : false;
        break;
      case "score":
        const validScore = isValidScore(value, 0, 1000);
        error = !validScore;
        break;
      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: error });
  };

  const handleStartDateChange = (e) => {
    onChangeFormData(e);
    setSelectedStartDate(e.target.value);
  };

  /* Get Integrate endpoint */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${USER_PROFILE_URL}/education/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEducationData(data);
        console.log(data, "edu data ");
      } catch (error) {
        console.error("Error fetching education data:", error);
      }
    };

    fetchData();
  }, []);

  /* Delete Integrate endpoint */
  const handleDeleteEducation = async (idToDelete) => {
    try {
      const response = await fetch(`${USER_PROFILE_URL}/education/${idToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        const updatedData = educationData.filter((education) => education.id !== idToDelete);
        setEducationData(updatedData);
        console.log("Delete data:", response);
      } else {
        console.error("Request failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const toggleModal = () => {
    setEditEducationModalOpen(!editEducationModalOpen);
  };

  /* Function to pick eduction id */
  const handleEditEducation = async (idToEdit) => {
    try {
      const selectedEducation = educationData.find((education) => education.id === idToEdit);
      setCurrentlyStudying(selectedEducation.end_date ? false : true);
      if (selectedEducation) {
        setFormData({
          title: selectedEducation.title,
          institute_name: selectedEducation.institute_name,
          education_level: selectedEducation.education_level,
          start_date: formatDateForInput(selectedEducation.start_date),
          end_date: formatDateForInput(selectedEducation.end_date),
          score: selectedEducation.score,
        });

        setEditMode(true);
        toggleModal();
        setSelectedEducationId(idToEdit);
      }
    } catch (error) {
      console.error("Error setting form data for edit:", error);
    }
  };

  /* Edit Integrate endpoint */
  const handleModifiedEducation = async (idToEdit) => {
    const updatedEduEndDate = currentlyStudying ? "in progress" : formData.end_date;

    try {
      const hasErrors = Object.values(formErrors).some((error) => error);
      if (hasErrors) {
        console.log("form has errors");
        console.log("form errors:", formErrors);
        return;
      }
      const response = await fetch(`${USER_PROFILE_URL}/education/${idToEdit}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          institute_name: formData.institute_name,
          title: formData.title,
          education_level: formData.education_level,
          start_date: formData.start_date,
          end_date: updatedEduEndDate === "in progress" ? null : updatedEduEndDate,
          score: formData.score,
          user: id,
        }),
      });

      if (response.ok) {
        const updatedDataResponse = await fetch(`${USER_PROFILE_URL}/education/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (updatedDataResponse.ok) {
          const updatedData = await updatedDataResponse.json();
          setEducationData(updatedData);
          console.log("Updated data:", updatedData);
        } else {
          console.error("Failed to fetch updated data:", updatedDataResponse.statusText);
        }

        setEditEducationModalOpen(false);
      } else {
        console.error("Request failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  /* Styling for divider */
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const formatDateForInput = (dateString) => {
    // const [day, month, year] = dateString.split("/");
    // return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    if (dateString === null) {
      return ""; // Return an empty string for "in progress"
    } else {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, cursor: "pointer" }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: "lightgrey" }}>
              <TableCell>#</TableCell>
              <TableCell>{t("education-degree")}</TableCell>
              <TableCell>{t("education-institute")}</TableCell>
              <TableCell>{t("education-educationallevel")}</TableCell>
              <TableCell>{t("education-score")}</TableCell>
              <TableCell>{t("education-startDate")}</TableCell>
              <TableCell>{t("education-endDate")}</TableCell>
              <TableCell>{t("education-modifyInfo")}</TableCell>
              <TableCell>{t("education-delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {educationData.map((education, index) => (
              <TableRow
                key={education?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{education?.title}</TableCell>
                <TableCell>{education?.institute_name}</TableCell>
                <TableCell>{education?.education_level}</TableCell>
                <TableCell>{education?.score}</TableCell>
                <TableCell>{education?.start_date}</TableCell>
                <TableCell>{education?.end_date}</TableCell>
                <TableCell onClick={() => handleEditEducation(education?.id)}>
                  <CiEdit size={15} />
                </TableCell>
                <TableCell onClick={() => handleDeleteEducation(education?.id)}>
                  <RiDeleteBinLine size={15} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Education Modal */}
      <div
        className={`modal fade ${educationData ? "show" : ""}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!editEducationModalOpen || selectedEducationId === null}
        style={{
          display: editEducationModalOpen ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "800px", borderRadius: "10px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}
                ></button>
              </div>

              <br />
              <form className="my-5" id="education">
                <div className="row" style={{ marginTop: "-5%" }}>
                  <h2 style={{ textAlign: "center", marginBottom: "5%" }}>
                    <b>{t("education-modify")}</b>
                  </h2>
                  <>
                    <div className="col-md-6">
                      <label htmlFor={`title`} className="form-label">
                        {t("education-degree")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. Intermediate"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          name="title"
                          value={formData.title}
                          onChange={onChangeFormData}
                          htmlFor={`title`}
                          variant="outlined"
                          fullWidth
                          error={formErrors.title}
                          helperText={formErrors.title ? "Invalid degree title" : ""}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`institute_name `} className="form-label">
                        {t("education-institute")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. Sidwell Friends School"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          name="institute_name"
                          value={formData.institute_name}
                          onChange={onChangeFormData}
                          id={`institute_name `}
                          variant="outlined"
                          fullWidth
                          error={formErrors.institute_name}
                          helperText={formErrors.institute_name ? "Invalid institute name" : ""}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`education_level `} className="form-label">
                        {t("education-educationallevel")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          name="education_level"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          value={formData.education_level}
                          onChange={onChangeFormData}
                          placeholder="Select Educational Level"
                          id={`education_level `}
                          select
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          {educationalLevelOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`start_date`} className="form-label">
                        {t("education-startDate")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          name="start_date"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                            "& input::-webkit-calendar-picker-indicator": {
                              filter:
                                "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                            },
                            "& input[type='date']::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type='date']::-webkit-clear-button": {
                              display: "none",
                            },
                          }}
                          value={formData.start_date}
                          onChange={handleStartDateChange}
                          id={`start_date `}
                          variant="outlined"
                          fullWidth
                          type="date"
                          InputProps={{
                            inputProps: {
                              max: minDate,
                            },
                          }}
                          error={formErrors.start_date}
                          helperText={formErrors.start_date ? "Please enter start date" : ""}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor={`end_date `} className="form-label">
                        {t("education-endDate")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. BS"
                          name="end_date"
                          value={formData.end_date}
                          onChange={onChangeFormData}
                          id={`end_date `}
                          variant="outlined"
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                            "& input::-webkit-calendar-picker-indicator": {
                              filter:
                                "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                            },
                            "& input[type='date']::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type='date']::-webkit-clear-button": {
                              display: "none",
                            },
                          }}
                          fullWidth
                          type="date"
                          disabled={currentlyStudying || !formData.start_date}
                          inputProps={{
                            min: formData.start_date,
                          }}
                          error={formData.end_date === "" && !currentlyStudying}
                          helperText={
                            formData.end_date === "" &&
                            !currentlyStudying &&
                            "Please enter end date!"
                          }
                        />
                      </div>
                      <label className="d-flex gap-2 mx-2 mb-2">
                        <input
                          type="checkbox"
                          checked={currentlyStudying}
                          onChange={handleCurrentlyStudyingToggle}
                        />
                        {t("currently-studying-here")}
                      </label>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor={`score `} className="form-label">
                        {t("education-score")}
                      </label>
                      <div className="mb-3">
                        <TextField
                          placeholder="e.g. 770/1050"
                          name="score"
                          value={formData.score}
                          onChange={onChangeFormData}
                          sx={{
                            "& fieldset": { border: "none" },
                            background: "#F0F3F6",
                            borderRadius: "5px",
                          }}
                          id={`score `}
                          variant="outlined"
                          fullWidth
                          error={formErrors.score}
                          helperText={formErrors.score ? "Enter valid score/marks/grade!" : ""}
                        />
                      </div>
                    </div>
                  </>

                  <Root>
                    <Divider>{"\n"}</Divider>
                  </Root>
                </div>

                <div className="edit-education-buttons">
                  <Button
                    type="button"
                    variant="outlined"
                    data-bs-dismiss="modal"
                    className="login-form-button text-white"
                    onClick={toggleModal}
                  >
                    {t("btn-notnow")}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="button"
                    className="login-form-button text-white"
                    variant="outlined"
                    onClick={() => handleModifiedEducation(selectedEducationId)}
                  >
                    {t("btn-edit")}
                  </Button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
