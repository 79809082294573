import React from "react";
import { useTranslation } from "react-i18next";
import infoPic from "../../../../assets/img/email-info-pic.png";
import tcBlocks from "../../../../assets/img/tcBlocks.svg";
import tcPolygonBottomLeft from "../../../../assets/img/tcPolygonBottomLeft.svg";
import tcPolygonTopLeft from "../../../../assets/img/PolygonBottomRight.svg";
import tcRightPolygon from "../../../../assets/img/tcRightPolygon.svg";

const Info = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="d-flex flex-md-row flex-column-reverse col-md-10 align-items-center col-lg-8 my-3 info-email-container px-5 py-md-0 py-3">
        <div className="info-bg mb-md-0 mb-2 col-md-6 col-12 text-white d-flex flex-column gap-3 align-items-md-start align-items-center justify-content-center">
          <img src={tcPolygonTopLeft} alt="" className="tc-topleft-poly" />
          <img src={tcBlocks} alt="" className="tc-blocks2" />
          <img src={tcRightPolygon} alt="" className="tc-right-poly2" />
          <img src={tcPolygonBottomLeft} alt="" className="tc-bottomleft-poly2" />
          <h2 className="text-md-start text-center">{t("footer-info-heading")}</h2>
          <p className="m-0 p-0">{t("footer-info-email")}</p>
          <p className="m-0 p-0">
            <a href="mailto:support@onboardiq.io" className="text-white text-decoration-none">
              support@onboardiq.io
            </a>
          </p>
        </div>
        <div className="info-img col-md-6 col-12 d-flex justify-content-center align-items-center">
          <img src={infoPic} alt="info-pic" style={{ height: "300px", width: "auto" }} />
        </div>
      </div>
    </div>
  );
};

export default Info;
