import React, { useEffect, useState } from "react";
import "../../App.css";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { UserLogin } from "../../context/AuthContext";
import { REPORT_API_URL } from "../../Auth_API";
import ReportSection1 from "./ReportSections/ReportSection1";
import ReportSection2 from "./ReportSections/ReportSection2";
import ReportSection3 from "./ReportSections/ReportSection3";
import ReportSection4 from "./ReportSections/ReportSection4";
import ReportSection5 from "./ReportSections/ReportSection5";
import ReportSection6 from "./ReportSections/ReportSection6";
import AppLoader from "../Loader/AppLoader";
import Lottie from "lottie-react";
// import footerImg from "../../assets/img/pi-5.png";

export default function SelectedReport() {
  const {
    testName,
    userData,
    selectedAudioReport,
    setSelectedAudioReport,
    value,
    isLoading,
    setIsLoading,
  } = UserLogin();
  const { token } = userData || {};
  const [error, setError] = useState("");
  const totalWords = selectedAudioReport?.total_words_count;
  const totalDuration = selectedAudioReport?.total_audio_duration;
  const minutes = Math.floor(totalDuration / 60);
  const seconds = totalDuration % 60;
  const submissionId = localStorage.getItem("submissionId");

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${REPORT_API_URL}/${submissionId}/report`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSelectedAudioReport(data);
        } else {
          const errorData = await response.json();
          setError(errorData.error || "Failed to fetch report data");
        }
      } catch (error) {
        setError("Error while fetching report: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };
    console.log("submissionId", submissionId);
    if (submissionId) {
      fetchReport();
    }
  }, [submissionId]);

  if (isLoading) {
    return <AppLoader />;
  }

  if (!selectedAudioReport || error) {
    return (
      <>
        <Navbar />
        <div style={{ padding: "45px" }}>
          <div
            className="card"
            style={{
              borderRadius: "25px",
              background: "linear-gradient(180deg, #56367f 0%, #3a5697 100%)",
            }}
          >
            <div className="card-body mx-4">
              <div
                className="d-flex flex-lg-row flex-column align-items-center justify-content-center"
                style={{ padding: "25px 0", display: "flex" }}
              >
                <h4 className="card-title text-white text-center mt-lg-0 mt-5">
                  You did not speak enough words. Your report is not generated.
                </h4>
                <Lottie
                  animationData={require("../../assets/animation/noOral.json")}
                  loop
                  autoplay
                  style={{ width: "15%", marginLeft: "-20px" }}
                  className="img-fluid dynamic-img"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  /* Bottom Content */
  const cardContent = [
    /* For Vocabulary */
    <div>
      <ReportSection4 />
    </div>,

    /*For Prouncation */
    <div>
      <ReportSection5 />
    </div>,

    // /* Grmmer mistakes */
    <div>
      <ReportSection6 />
    </div>,
  ];

  if (selectedAudioReport || !error) {
    return (
      <>
        <Navbar />
        <div className="oral-container ms-md-0 ms-3">
          <div className="row mx-1">
            <div className="col-md-12 d-flex flex-column align-items-start">
              <h3 className="text-start p-0">
                <p style={{ fontWeight: "600" }} className="m-0">
                  {testName}
                </p>
              </h3>
              <p className="text-secondary" style={{ fontSize: "18px" }}>
                <span>Duration:</span> {isNaN(minutes) ? 0 : minutes} Min{" "}
                {isNaN(seconds) ? 0 : seconds} Sec
                <span> Words: </span> {totalWords}
              </p>
            </div>
          </div>

          <ReportSection1 />
          <ReportSection2 />
          <ReportSection3 />
          {cardContent[value]}
        </div>
        {/* {isLoading && <AppLoader />} */}
      </>
    );
  }
  return null;
}
