import React, { useEffect } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import ReactCountryFlag from "react-country-flag";
import { styled } from "@mui/material/styles";
import startsWith from "lodash.startswith";
import "../../../App.css";
import { USER_PROFILE_URL } from "../../../Auth_API";
import Progress from "../../Loader/Progress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

export default function PersonalInfo() {
  const { t } = useTranslation();
  const {
    userData,
    cvCredentials: {
      first_name,
      last_name,
      phone_number,
      date_of_birth,
      nationality,
      city,
      state,
      zip,
      current_address,
      cv_gender,
    },
    cvCredentials,
    setCVCredentials,
    onChangeCVCredentials,
    setShowPersonalInfoForm,
    setShowEducationForm,
    setActiveCV,
    fieldValidations,
    setFieldValidations,
    isLoading,
    setIsLoading,
    isValidDate,
    handleEnterKeyPress,
    countryCodes,
    personalData,
    setPersonalData,
    cvSkimData,
  } = UserLogin();

  const { email, id, token } = userData || {};
  const hasPersonalData = personalData && Object.keys(personalData).length > 0;

  /* Menu for gender */
  const genderOptions = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    { label: "Non Binary", value: "N" },
    { label: "Prefer not to say", value: "P" },
  ];

  /* Function to handle personal info */
  const handlePersonalInfoSubmit = async (e) => {
    console.log("cv_gender:", cv_gender);

    e.preventDefault();
    const formattedPhoneNumber = `+${phone_number.replace(/\D/g, "")}`;
    const newFieldValidations = {
      ...fieldValidations,
      first_name: !(!first_name?.trim() || first_name?.length < 3),
      last_name: !(!last_name.trim() || last_name.length < 3),
      current_address: !(!current_address.trim() || current_address.length < 5),
      nationality: Boolean(nationality?.trim()),
      phone_number: formattedPhoneNumber.length >= 12,
      date_of_birth: isValidDate(date_of_birth),
      city: !(!city.trim() || city.length < 3),
      cv_gender: genderOptions.some((option) => option.value === cv_gender),
    };

    const isFormValid = Object.values(newFieldValidations).every((valid) => valid);
    console.log("is form valid:", newFieldValidations);
    if (isFormValid) {
      try {
        setIsLoading(true);
        const response = await fetch(`${USER_PROFILE_URL}/personal-information/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            first_name,
            last_name,
            phone_number: formattedPhoneNumber,
            date_of_birth,
            city,
            state: state === "" ? null : state,
            zip: zip === "" ? null : zip,
            current_address,
            cv_gender,
            nationality,
            user: id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Request succeeded!", data);
          setIsLoading(false);
          setFieldValidations(newFieldValidations);
          setShowPersonalInfoForm(false);
          setShowEducationForm(true);
          setActiveCV(1);
        } else {
          console.error("Request failed:", response.statusText);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error making request:", error);
        setIsLoading(false);
      }
    } else {
      setFieldValidations(newFieldValidations);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cvSkimData) {
      setCVCredentials({
        first_name: cvSkimData.first_name ? cvSkimData.first_name : "",
        last_name: cvSkimData.last_name ? cvSkimData.last_name : "",
        email: cvSkimData.email ? cvSkimData.email : "",
        phone_number: cvSkimData.phone_number ? cvSkimData.phone_number : "",
        date_of_birth: cvSkimData.date_of_birth ? cvSkimData.date_of_birth : "",
        cv_gender: cvSkimData.cv_gender ? cvSkimData.cv_gender : "",
        current_address: cvSkimData.current_address ? cvSkimData.current_address : "",
        zip: cvSkimData.zip ? cvSkimData.zip : "",
        city: cvSkimData.city ? cvSkimData.city : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvSkimData]);

  /* Endpoint to get the user info from backend */
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${USER_PROFILE_URL}/personal-information/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPersonalData(data);
          console.log("fetch user info:", data);
          setCVCredentials({
            ...data,
            first_name: cvSkimData.first_name ? cvSkimData.first_name : data.first_name,
            last_name: cvSkimData.last_name ? cvSkimData.last_name : data.last_name,
            email: cvSkimData.email ? cvSkimData.email : data.email,
            phone_number: cvSkimData.phone_number ? cvSkimData.phone_number : data.phone_number,
            date_of_birth: cvSkimData.date_of_birth ? cvSkimData.date_of_birth : data.date_of_birth,
            cv_gender: cvSkimData.cv_gender ? cvSkimData.cv_gender : data.cv_gender,
            current_address: cvSkimData.current_address
              ? cvSkimData.current_address
              : data.current_address,
            city: cvSkimData.city ? cvSkimData.city : data.city,
            zip: cvSkimData.zip ? cvSkimData.zip : data.zip,
            state: cvSkimData.state ? cvSkimData.state : data.state,
          });
        } else {
          console.log("error while fetching user info", response);
        }
      } catch (error) {
        console.log("error while fetching", error);
      }
    };

    if (token) {
      fetchUserInfo();
    }
    //to disable warning react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, cvSkimData]);

  /* Function to handle without auth personal info */
  const handleWithoutAuth = async (e) => {
    e.preventDefault();
    setActiveCV(1);
    setShowPersonalInfoForm(false);
    setShowEducationForm(true);
  };

  /* Styling for divider */
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  useEffect(() => {
    console.log("cvCredentials ", cvCredentials);
    console.log(" cv_gender", cv_gender);
    console.log("cv skim data:", cvSkimData);
  }, [cvSkimData, cv_gender, cvCredentials]);

  const today = new Date();
  const tenYearsAgo = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());
  const maxDate = tenYearsAgo.toISOString().split("T")[0];

  return (
    <>
      <form className="my-4" id="personal-info">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="fname" className="form-label">
              {t("personal-fname")}
            </label>
            <div className="mb-3">
              <TextField
                name="first_name"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={first_name}
                onChange={onChangeCVCredentials}
                placeholder="e.g. Jhon"
                required
                id="first_name?"
                variant="outlined"
                fullWidth
                error={!fieldValidations.first_name && !(first_name || cvSkimData?.first_name)}
                helperText={
                  !fieldValidations.first_name &&
                  !(first_name || cvSkimData?.first_name) &&
                  !first_name.trim() &&
                  "First Name must be at least 3 characters long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "last_name")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="lname" className="form-label">
              {t("personal-lname")}
            </label>
            <div className="mb-3">
              <TextField
                name="last_name"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={last_name}
                onChange={onChangeCVCredentials}
                placeholder="e.g.Kalz"
                required
                id="last_name"
                variant="outlined"
                fullWidth
                error={!fieldValidations.last_name}
                helperText={
                  !fieldValidations.last_name &&
                  !last_name.trim() &&
                  "Last Name must be at least 3 characters long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "phone_number")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="contactEmail" className="form-label">
              {t("personal-email")}
            </label>
            <div className="mb-3">
              <TextField
                name="email"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={email}
                id="contactEmail"
                variant="outlined"
                fullWidth
                disabled
              />
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="phone_number" className="form-label">
              {t("personal-phone")}
            </label>
            <div className="mb-3">
              <div style={{ display: "flex" }}>
                <PhoneInput
                  id="phone_number"
                  name="phone_number"
                  country={"cw"}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  value={phone_number}
                  onChange={(newPhone) =>
                    onChangeCVCredentials({ target: { name: "phone_number", value: newPhone } })
                  }
                  maxLength={20}
                  onKeyDown={(event) => handleEnterKeyPress(event, "date_of_birth")}
                />
              </div>
            </div>
            <span className="text-danger fs-6 m-0">
              {!fieldValidations.phone_number && "Please enter a valid phone number"}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="date_of_birth" className="form-label">
              {t("personal-dob")}
            </label>
            <div className="mb-3">
              <div className="mb-3">
                <TextField
                  name="date_of_birth"
                  sx={{
                    "& fieldset": { border: "none" },
                    background: "#F0F3F6",
                    borderRadius: "5px",
                    "& input::-webkit-calendar-picker-indicator": {
                      filter:
                        "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                    },
                    "& input[type='date']::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type='date']::-webkit-clear-button": {
                      display: "none",
                    },
                  }}
                  value={date_of_birth || ""}
                  onChange={onChangeCVCredentials}
                  type="date"
                  required
                  id="date_of_birth"
                  variant="outlined"
                  fullWidth
                  error={!fieldValidations.date_of_birth}
                  helperText={
                    !fieldValidations.date_of_birth &&
                    !date_of_birth.trim() &&
                    "Please enter a valid date of birth."
                  }
                  onKeyDown={(event) => handleEnterKeyPress(event, "cv_gender")}
                  inputProps={{
                    max: maxDate,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="gender" className="form-label">
              {t("personal-gender")}
            </label>
            <div className="mb-3">
              <TextField
                name="cv_gender"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={cv_gender}
                onChange={onChangeCVCredentials}
                placeholder="Select Gender"
                id="cv_gender"
                select
                variant="outlined"
                style={{ width: "100%" }}
                error={!fieldValidations.cv_gender}
                helperText={
                  !fieldValidations.cv_gender && !cv_gender.trim() && "Please select a gender"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "nationality")}
              >
                {genderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="nationality" className="form-label">
              {t("personal-nationality")}
            </label>
            <div className="mb-3">
              <TextField
                id="nationality"
                name="nationality"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                select
                variant="outlined"
                style={{ width: "100%" }}
                value={nationality || ""}
                onChange={onChangeCVCredentials}
                error={!fieldValidations.nationality}
                helperText={
                  !fieldValidations.nationality &&
                  !nationality?.trim() &&
                  "Please select a nationality."
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "city")}
              >
                {Object.entries(countryCodes).map(([countryName, countryCode], index) => (
                  <MenuItem key={index} value={countryName}>
                    <ReactCountryFlag
                      countryCode={countryCode}
                      svg
                      style={{ marginRight: "8px" }}
                    />
                    {countryName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="city" className="form-label">
              {t("personal-city")}
            </label>
            <div className="mb-3">
              <TextField
                name="city"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={city || cvSkimData?.city || ""}
                onChange={onChangeCVCredentials}
                required
                placeholder="e.g. Willemstad"
                id="city"
                variant="outlined"
                fullWidth
                error={!fieldValidations.city}
                helperText={
                  !fieldValidations.city && !city.trim() && "City must be 5 character long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "state")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="state" className="form-label">
              {t("personal-state")} (optional)
            </label>
            <div className="mb-3">
              <TextField
                name="state"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={state || null}
                onChange={onChangeCVCredentials}
                placeholder="e.g. Caribbean Sea"
                id="state"
                variant="outlined"
                fullWidth
                // error={!fieldValidations.state}
                // helperText={
                //   !fieldValidations.state &&
                //   !state.trim() &&
                //   "State must of atleast 3 character long!"
                // }
                onKeyDown={(event) => handleEnterKeyPress(event, "zip")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="zip" className="form-label">
              {t("personal-zip")} {countryCodes[nationality] ? "(optional)" : ""}
            </label>
            <div className="mb-3">
              <TextField
                name="zip"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={zip || null}
                onChange={onChangeCVCredentials}
                placeholder="e.g. 0000"
                id="zip"
                variant="outlined"
                fullWidth
                inputMode="numeric"
                type="number"
                // error={!fieldValidations.zip}
                // helperText={
                //   !fieldValidations.zip &&
                //   !zip.trim() &&
                //   "Enter a Valid 5-digit ZIP code!"
                // }
                onKeyDown={(event) => handleEnterKeyPress(event, "current_address")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label htmlFor="current_address" className="form-label">
              {t("personal-address")}
            </label>
            <div className="mb-3">
              <TextField
                name="current_address"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={current_address || ""}
                onChange={onChangeCVCredentials}
                required
                id="current_address"
                variant="outlined"
                fullWidth
                error={!fieldValidations.current_address}
                helperText={
                  !fieldValidations.current_address &&
                  // !current_address.trim() &&
                  "current_address must be 5 character long!"
                }
              />
            </div>
          </div>
        </div>

        <div className="d-flex aligns-items-center justify-content-end gap-2 ">
          <div className="d-flex align-items-center mt-2">
            <button type="submit" className="login-form-button" onClick={handlePersonalInfoSubmit}>
              {t("btn-add")}
            </button>
          </div>
          {hasPersonalData ? (
            <div className="d-flex align-items-center mt-2">
              <button type="submit" className="login-form-button" onClick={handleWithoutAuth}>
                {t("btn-next")}
              </button>
            </div>
          ) : null}
        </div>
      </form>
      {isLoading ? <Progress /> : null}
    </>
  );
}
