import React from "react";
import imgPI3_1 from "../../../../assets/img/pi-2.png";
import { UserLogin } from "../../../../context/AuthContext";
import {useTranslation} from "react-i18next";

function Section3() {
  const { piReportData } = UserLogin();

  const { t } = useTranslation();

  return (
    <div className="pi-section-container pi-executive-container">
      {/* <p className='pi-team-dynamics-heading'>executive summary</p> */}
      <div className="pi-card card mb-3" style={{ width: "100%", padding: "60px 100px" }}>
        <div className="row g-0 pi-dynamic-row">
          <div className="col-md-7 pi-col-7">
            <div className="card-body">
              <p className="card-title pi-card-title">{t("executive-summary")}</p>
              <div className="progress-container">
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar"
                    style={{ width: "40%", background: "#56367f", borderRadius: "30px" }}
                  ></div>
                </div>
              </div>
              <br />
              <p className="card-text pi-card-text mt-4">
                {piReportData?.analysis_data?.executive_summary}
              </p>
            </div>
          </div>
          <div className="col-md-5 pi-col-5">
            <img src={imgPI3_1} className="img-fluid pi-img-summary" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
