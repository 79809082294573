import React, { useState } from "react";
import { UserLogin } from "../../../context/AuthContext";
import trophy from "../../../assets/img/oral_trophy.svg";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

function ReportSection1() {
  const { t } = useTranslation();
  const { selectedAudioReport, levelDescriptions } = UserLogin();
  const lexical_diversity = selectedAudioReport?.analysis_data?.lexicalDiversity;
  const highestLevel = selectedAudioReport?.analysis_data?.proficiencyLevel;
  const levelDescription = levelDescriptions[highestLevel] || levelDescriptions["Beginner"];
  const levelToValueMap = {
    A1: 6,
    A2: 5,
    B1: 4,
    B2: 3,
    C1: 2,
    C2: 1,
  };
  const proficiencyData = {
    Fluency: selectedAudioReport?.analysis_data?.detailedMetrics?.levels?.Fluency || "",
    Grammar: selectedAudioReport?.analysis_data?.detailedMetrics?.levels?.Grammar || "",
    Vocabulary: selectedAudioReport?.analysis_data?.detailedMetrics?.levels?.Vocabulary || "",
    Interaction: selectedAudioReport?.analysis_data?.detailedMetrics?.levels?.Interaction || "",
    Pronunciation:
      selectedAudioReport?.analysis_data?.detailedMetrics?.levels?.Pronunciation || "",
  };
  const categories = ["Fluency", "Vocabulary", "Interaction", "Pronunciation", "Grammar"];

  const seriesData = categories.map((category) => {
    return levelToValueMap[proficiencyData[category]] || 0;
  });

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Proficiency Levels",
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: "radar",
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: "#000",
          },
        },
      },
      colors: ["#FD9CFF"],
      yaxis: {
        tickAmount: 5,
        stepSize: 1,
        max: 5,
        labels: {
          offsetY: -1,
          style: {
            colors: "#000", // Label color
            fontSize: "12px", // Font size
          },
          formatter: function (value) {
            return "  " + value.toFixed(0); // Adds space before the value to shift it right
          },
        },
      },

      xaxis: {
        categories: categories,
        labels: {
          offsetY: -5,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  });

  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <div className="card" style={{ borderRadius: "25px", border: "2px solid #b7b7b7" }}>
          <div className="card-body py-0 pt-lg-0 pt-3 mx-4 me-0">
            <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <div className="col-lg-8 col-12 d-flex flex-column justify-content-center">
                <div style={{ fontWeight: "bold" }}>
                  <h5 className="card-title m-0" style={{ fontWeight: "700" }}>
                    {t("scores")}
                  </h5>
                  <p className="card-title mt-1 mb-0 text-capitalize ">
                    <span style={{ fontSize: "28px" }}>{highestLevel}</span>
                  </p>
                </div>
                <p className="card-text" style={{ color: "#787878" }}>
                  {levelDescription}
                </p>
                <p className="card-text" style={{ fontSize: "20px" }}>
                  <span style={{ fontWeight: "600" }}>
                    {t("lexical-diversity")}: {lexical_diversity}
                  </span>
                </p>
              </div>
              <div className="col-lg-4 col-12 m-0 ">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="radar"
                  height={300}
                  className="mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSection1;
