import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationSP from "./locales/sp/translation.json";
import translationP from "./locales/p/translation.json";
import translationDU from "./locales/du/translation.json";
// import { UserLogin } from "../context/AuthContext";

// const { langChange } = UserLogin();
// console.log(langChange)
const resources = {
  en: {
    translation: translationEN,
  },
  sp: {
    translation: translationSP,
  },
  pa: {
    translation: translationP,
  },
  du: {
    translation: translationDU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
