import React, { useState, useEffect } from "react";
import "../../App.css";
import { UserLogin } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AUTH_API_URL } from "../../Auth_API";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useTour } from "@reactour/tour";
import onbLogo from "../../assets/img/onbLogoPng.png";
import polygonTL from "../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../assets/img/loginStatsPic.png";

export default function Login() {
  const { i18n } = useTranslation();
  let navigation = useNavigate();
  const { setIsOpen } = useTour();
  // const [isLoginScreen, setIsLoginScreen] = useState(true);
  // const [isRegistrationScreenVisible, setIsRegistrationScreenVisible] = useState(true);
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const { email, password } = credentials;
  const {
    isLoading,
    setIsLoading,
    setUserData,
    isValidObjField,
    updateError,
    error,
    setError,
    showPassword,
    setShowPassword,
    showToast,
    setIsErrorOpen,
    handleEnterKeyPress,
    setLangChange,
  } = UserLogin();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkToken = () => {
      if (token != null) {
        navigation("/main");
      } else {
        setIsCheckingToken(false);
      }
    };

    const timeoutId = setTimeout(checkToken, 2000);

    return () => clearTimeout(timeoutId);
  }, [token, navigation]);

  const isValidEmail = (value) => {
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
    return regx.test(value);
  };

  const lowercaseEmail = email.toLowerCase();
  // let isPageRefreshed = false;

  // Function to login a user
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        return updateError("Require all fields!", setError);
      }
      if (!isValidEmail(lowercaseEmail)) {
        return updateError("Enter a valid email!", setError);
      }
      if (!password.trim() || password.length < 5) {
        return updateError("Password must be 5 characters long!", setError);
      }
      setIsLoading(true);
      const response = await fetch(`${AUTH_API_URL}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...credentials, email: lowercaseEmail }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data, "login data");
        setLangChange(data.preferred_language);
        i18n.changeLanguage(data.preferred_language.toLowerCase());
        localStorage.setItem("token", data.token);
        localStorage.setItem("userData", JSON.stringify(data));
        setCredentials({ email: "", password: "" });
        const currentTime = new Date().getTime(); // Store the current time in milliseconds
        localStorage.setItem("sessionTime", currentTime);
        if (!data.first_login_tour) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
        navigation("/Main", {
          state: {
            name: data.name,
            id: data.id,
            email: data.email,
            token: data.token,
            org_ids: data.org_ids,
            pi_id: data.predictive_index_id,
          },
          replace: true,
        });
        setUserData(data);
        showToast("You have been Login successfully!");
        console.log("User login successfully:", data);
      } else {
        const data = await response.json();
        if (
          response.status === 403 &&
          data.message === "Account not verified. Please verify your account."
        ) {
          Swal.fire({
            icon: "error",
            title: "Account not verified⚠️",
            text: "Please verify your account.",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Verify",
            customClass: {
              confirmButton: "verify-button",
              cancelButton: "cancel-button",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              handleVerifyAccount();
            }
          });
        } else {
          // Handle incorrect credentials or other errors
          console.error("Error:", data.message);
          showToast("Failed to login. Please check your credentials.");
          setIsErrorOpen(true);
        }
      }
    } catch (error) {
      console.error("Failed to login user:", error);
      showToast("Failed to login. Please check your credentials.");
      setIsErrorOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Function to verify account
  const handleVerifyAccount = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${AUTH_API_URL}/initiate_otp_verification/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(email, "handleVerifyAccount");
        navigation("/otp_verificaion", {
          state: { otp_email: email },
          replace: true,
        });
        Swal.fire({
          icon: "success",
          title: "Verify Account OTP!",
          text: "If you did not recieve an OTP, please check your spam!",
        });
        // setIsLoginScreen(false);
        // setIsRegistrationScreenVisible(false);
        setIsLoading(false);
        console.log("OTP message:", data);
      } else {
        console.error("OTP not recieve!", error);
        Swal.fire({
          icon: "error",
          title: "OTP send failed 😢",
          text: "Failed to send OTP!",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to login!", error);
      Swal.fire({
        icon: "error",
        title: "OTP send failed 😢",
        text: "Failed to send OTP!",
      });
      setIsLoading(false);
    }
  };

  if (isCheckingToken) {
    return <></>;
  }

  return (
    <div className="login-main">
      <div className="login-page-container d-flex justify-content-center gap-2 col-12">
        <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
          <div className="login-page-form mt-3 d-flex flex-column align-items-center">
            <div className="col-9 ps-4">
              <img src={onbLogo} alt="logo" className="login-form-logo" />
            </div>
            <div className="login-form-container col-9 mt-1 shadow-none">
              <div className="login-form-body">
                <h4 className="fw-bold text-capitalize">Login to your account</h4>
                <p className="m-0 p-0 text-secondary">
                  Please login the account with your details.
                </p>
                <hr />
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Email Address (Required)</label>
                  <input
                    name="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    margin="normal"
                    required
                    autoComplete="off"
                    type="text"
                    placeholder="Email"
                    className="col-12"
                    onKeyDown={(e) => handleEnterKeyPress(e, "password")}
                  />
                  {error && !isValidEmail(email) && (
                    <span className="error-fields text-danger ms-2">Enter a valid email!</span>
                  )}
                </div>
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="form-control password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={onChange}
                      margin="normal"
                      autoComplete="off"
                      required
                      onKeyDown={(e) => e.key === "Enter" && handleLogin(e)}
                    />
                    <span className="input-group-text" onClick={handleTogglePassword}>
                      {showPassword ? (
                        <i
                          className="fa fa-eye text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      )}
                    </span>
                  </div>
                  {error && (!password.trim() || password.length < 5) && (
                    <span className="error-fields text-danger ms-2">
                      Password must be at least 5 characters long!
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-start mt-2">
                  <Link
                    className="text-decoration-none mt-1"
                    style={{ color: "#56367f", fontSize: "16px", fontWeight: "600" }}
                    to="/forget_password"
                  >
                    Forgot password?
                  </Link>
                </div>

                <button
                  onClick={handleLogin}
                  className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                  disabled={isLoading}
                >
                  Login
                </button>

                <p className="m-0 mt-3 p-0 d-flex gap-2 text-secondary">
                  Don't have an account please
                  <span>
                    <Link
                      // onClick={() => setIsLoginScreen(false)}
                      to="/register"
                      className="text-decoration-underline"
                      style={{ color: "#56367f", fontWeight: "600" }}
                    >
                      Sign up
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
          <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
          <img src={polygonTL} alt="polygon" className="polygon-top-left" />
          <img src={cellsTR} alt="cells" className="cells-top-right" />
          <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
          <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
          <img src={loginStatsImg} alt="stats" className="login-stats" />
        </div>
      </div>
    </div>
  );
}
