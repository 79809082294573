import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Check from "@mui/icons-material/Check";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { FaUser } from "react-icons/fa";
import { RiSpeakFill } from "react-icons/ri";
import { FaProductHunt } from "react-icons/fa";
import { USER_PROFILE_URL } from "../../../../Auth_API";
import { UserLogin } from "../../../../context/AuthContext";

const ProgressBar = () => {
  const { userData, barStatus, setBarStatus } = UserLogin();
  const { token } = userData || {};
  const { t } = useTranslation();

  // Function to fetch progress
  useEffect(() => {
    const fetchApplicationProgress = async () => {
      try {
        const response = await fetch(`${USER_PROFILE_URL}/application-progress/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBarStatus(data.progress);
          console.log(data.progress, "status bar");
        } else {
          console.error("Failed to fetch application progress:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching application progress:", error);
      }
    };

    fetchApplicationProgress();
  }, [setBarStatus, token]);

  // Steppers
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#56367f",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#56367f",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 10,
      border: 0,
      backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage: " linear-gradient(180deg, #56367f 0%, #3a5697 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage: " linear-gradient(180deg, #56367f 0%, #3a5697 100%)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <FaUser />,
      2: <RiSpeakFill size={25} />,
      3: <FaProductHunt size={23} />,
      4: <Check />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="progress-stepper-container d-flex align-items-center gap-md-0 gap-1 px-md-5 px-4 mb-md-0 mb-5">
        <div
          className={`progress-step text-center col-3  d-flex flex-column justify-content-center align-items-center ${
            barStatus === 0 ? "active" : ""
          }`}
        >
          <h3>{t("progress-step-1")}</h3>
          <p className="m-0 p-0">{t("hero-upload-cv")}</p>
        </div>
        <div
          className={`progress-step text-center col-3  d-flex flex-column justify-content-center align-items-center ${
            barStatus === 1 ? "active" : ""
          }`}
        >
          <h3>{t("progress-step-2")}</h3>
          <p className="m-0 p-0">{t("progress-step-title2")}</p>
        </div>
        <div
          className={`progress-step text-center col-3  d-flex flex-column justify-content-center align-items-center ${
            barStatus === 2 ? "active" : ""
          }`}
        >
          <h3>{t("progress-step-3")}</h3>
          <p className="m-0 p-0">{t("progress-step-title3")}</p>
        </div>
        <div
          className={`progress-step step4 text-center col-3  d-flex flex-column justify-content-center align-items-center ${
            barStatus === 3 ? "active" : ""
          }`}
        >
          <h3>{t("progress-step-4")}</h3>
          <p className="m-0 p-0">{t("progress-step-title4")}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
