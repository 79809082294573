import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { UserLogin } from "../context/AuthContext";


const PrivateRoutes = ({ children, ...rest }) => {
    const { setToken } = UserLogin();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for splash screen

    // Auto-logout after 5 minutes
    const sessionTime = localStorage.getItem("sessionTime");
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - sessionTime;

    if (timeElapsed > 86400000) { // 5 minutes in milliseconds
      localStorage.removeItem("token");
      localStorage.removeItem("sessionTime");
      setToken("");
    }

    return () => clearTimeout(timer);
  }, [setToken,navigate]);

  return !token ? <Navigate to="/" /> : <Outlet />;
};

export default PrivateRoutes;
