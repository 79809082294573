import React, { useEffect } from "react";
import { MenuItem, TextField } from "@mui/material";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { UserLogin } from "../../context/AuthContext";
import { USER_PROFILE_URL } from "../../Auth_API";
import { Link, useNavigate } from "react-router-dom";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import onbLogo from "../../assets/img/onbLogoPng.png";
import { useTranslation } from "react-i18next";

export default function InterviewHome() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { interviewName, setInterviewName, userData, selectedInterview, setSelectedInterview } =
    UserLogin();
  const { token } = userData || {};

  const onChange = (e) => {
    setSelectedInterview(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notTakenResponse = await fetch(`${USER_PROFILE_URL}/list_tests_user`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (!notTakenResponse.ok) {
          throw new Error(`HTTP error! Status: ${notTakenResponse.status}`);
        }
        const notTakenData = await notTakenResponse.json();
        const takenResponse = await fetch(`${USER_PROFILE_URL}/user_tests_taken`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (!takenResponse.ok) {
          throw new Error(`HTTP error! Status: ${takenResponse.status}`);
        }

        const takenData = await takenResponse.json();
        const takenTestIds = takenData.map((takenTest) => takenTest.test);
        const filteredTests = notTakenData.filter((test) => !takenTestIds.includes(test.id));

        setInterviewName(filteredTests);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, [token, selectedInterview]);

  const handleTestSubmission = () => {
    setTimeout(() => {
      navigate("/InterviewWarmUp");
    }, 100);
  };

  return (
    <div className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
      <Navbar />
        <div className="interview-home-container">
          <div className="col-10">
            <div className="interview-home-card card " style={{ padding: "20px 40px" }}>
              <div className="card-body">
                <div className=" org-table-head gap-2 d-flex flex-column align-items-center">
                  <div style={{ flexGrow: 0, flexShrink: 0 }}>
                    <img src={onbLogo} alt="" style={{ width: "200px" }} />
                  </div>
                  <h2 className=" mb-2">
                    <b>{t("interive-with-cds")}</b>
                  </h2>
                </div>

                <p className="card-text mt-4">
                  {t("interive-desc")}
                  <div className="my-3" style={{ border: "dotted", color: "gray" }}></div>
                  <div className="interview-home-fields">
                    <TextField
                      label="Select test"
                      variant="outlined"
                      name="test"
                      select
                      value={selectedInterview}
                      onChange={onChange}
                      margin="normal"
                      sx={{ width: "100%" }}
                      required
                    >
                      {interviewName.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </p>
                <div className="d-flex justify-content-center">
                  {selectedInterview && interviewName?.length > 0 && (
                    <button
                      onClick={handleTestSubmission}
                      className="login-form-button shadow-none mt-0"
                    >
                      {t("interive-btn-view-qs")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      <Copyright />
    </div>
  );
}
