import React from "react";
import onbLogo from "../../assets/img/onbLogoPng.png";
import timer from "../../assets/img/TimeCircle.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import { useTranslation } from "react-i18next";
import { UserLogin } from "../../context/AuthContext";

export default function InterviewWarmUp() {
  const { showToast, setIsErrorOpen } = UserLogin();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/InterviewQs", { replace: true });
    showToast("Click on red icon on record button for tour to get started.");
    setIsErrorOpen(true);
  };

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-column justify-content-between gap-3"
    >
      <Navbar />
      <div className="interview-warmup-container px-5 my-3">
        <div className="interview-home-card card">
          <div className="container-fluid d-flex flex-sm-row flex-column justify-content-between ">
            <div>
              <a className="navbar-brand" href="#none">
                {t("interive-with-cds")}
              </a>
            </div>
            <div className="d-flex gap-2 justify-content-end ">
              <img src={timer} alt="timer" />
              <div>00:00 / 60:00</div>
            </div>
          </div>

          <div className="card interview-warmup-card mt-4 text-center">
            <div className="card-body">
              <div className=" org-table-head gap-3 mt-2 d-flex flex-column align-items-center">
                <div>
                  <img src={onbLogo} alt="" style={{ width: "200px" }} />
                </div>
                <h2 className=" mb-2">
                  <b>{t("interive-warmup")}</b>
                </h2>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p className="card-text my-3">{t("interive-warmup-p1")}</p>
                <p className="card-text col-sm-10 col-12">{t("interive-warmup-p2")}</p>
                <p className="card-text">{t("interive-warmup-p3")}</p>
              </div>
              <button className="login-form-button shadow-none my-3 mt-4" onClick={handleNavigate}>
                {t("go-to-questions")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
}
