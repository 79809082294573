export const REPORT_API_URL = "https://onboardiq.io/api/user_reports/test-submissions";

export const AUDIO_API_URL = "http://192.168.18.29:8000/test/english";

export const AUTH_API_URL = "https://onboardiq.io/api/user_accounts";

export const USER_PROFILE_URL = "https://onboardiq.io/api/user_profiles";

export const INTERVIEW_IMG_QS = "https://onboardiq.io";

export const PI_REPORT_URL = "https://onboardiq.io/api/user_reports/predictive-index-report";