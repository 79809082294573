import React from "react";
import Navbar from "./HomeSections/Section1/Navbar";
import Hero from "./HomeSections/Section3/Hero";
import Faqs from "./HomeSections/Section4/Faqs";
import Footer from "./HomeSections/Section5/Footer";

function Main({rerender}) {

  return (
    <div className="bg-white">
      <Navbar />
      <Hero />
      <Faqs />
      <Footer />
    </div>
  );
}

export default Main;
