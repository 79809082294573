import React from "react";
import hr from "../../../assets/img/hr.png";
import arrow from "../../../assets/img/arrow2.png";
import "./demo.css";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router";

const Demo = () => {
  const navigator = useNavigate();
  return (
    <div id="demo">
      <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center">
        <div className="col-lg-5 col-10 ">
          <Fade left appear>
            <div className="demo-content">
              <div className="d-flex flex-column align-items-center align-items-lg-start">
                <p className="stats-paragraph text-center mx-sm-0 mx-2 mb-2 ">
                  UNCOVER HIDDEN TALENT WITH PRECISION AND INSIGHT
                </p>
              </div>
              <p className="demo-para">
                Ready to <span className="span-gradient">Transform Your</span> Hiring Process?
              </p>
              <p className="paragraph-hiring" style={{ color: "#b5b5c3" }}>
                Experience the power of OnboardIQ firsthand and see how our platform can streamline
                your recruitment, uncover deeper insights, and enable smarter hiring decisions.
                Don't just fill positions—find the perfect match for your team.
              </p>
              <div className="buttons-start-video">
                <button className="get-started" onClick={() => navigator("/login")}>
                  START YOUR FREE TRIAL
                </button>
              </div>
            </div>
          </Fade>
        </div>
        <div className="col-lg-5 col-8 right-demo-image">
          <Zoom appear>
            <img src={hr} alt="Hire Illustration" width={80} height={80} />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Demo;
