import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserLogin } from "../../context/AuthContext";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { USER_PROFILE_URL } from "../../Auth_API";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import Progress from "../Loader/Progress";

function PIFivthScreen() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedCount, setSelectedCount] = useState(0);
  const canNavigate = selectedCount >= 5;
  const {
    setBarStatus,
    age,
    gender,
    race,
    education,
    radio11,
    radio12,
    radio13,
    checkbox1_pi,
    checkbox2_pi,
    setCheckbox2_pi,
    showToast,
    userData,
    isLoading,
    setIsLoading,
    setPISubmission,
  } = UserLogin();
  const { token, id } = userData || {};

  const onChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      if (selectedCount < 6) {
        setCheckbox2_pi((prevSelectedCheckboxes) => ({
          ...prevSelectedCheckboxes,
          [name]: true,
        }));
        setSelectedCount(selectedCount + 1);
      }
    } else {
      setCheckbox2_pi((prevSelectedCheckboxes) => ({
        ...prevSelectedCheckboxes,
        [name]: false,
      }));
      setSelectedCount(selectedCount - 1);
    }

    const checkboxes = document.querySelectorAll(".form-check-input");
    checkboxes.forEach((checkbox) => {
      checkbox.disabled = selectedCount >= 5 && !checkbox.checked;
    });
  };

  /* Funvtion to update progress */
  const updateApplicationProgress = async () => {
    try {
      const progressResponse = await fetch(`${USER_PROFILE_URL}/application-progress/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          progress: 3,
        }),
      });
      const progressData = await progressResponse.json();
      console.log("Progress data:", progressData.progress);
      setBarStatus(progressData.progress);
    } catch (error) {
      console.error("Error updating application progress:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* Function to save PI test */
  const handleSavePITest = async () => {
    const data = {
      user: id,
      age: age,
      gender: gender,
      race: race,
      education: education,
      feedback_preference: radio11,
      strategic_feedback: radio12,
      holiday_party_preference: radio13,
      expected_to_at_work: checkbox1_pi,
      describe_yourself: checkbox2_pi,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(`${USER_PROFILE_URL}/predictive-index/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      await updateApplicationProgress();
      const piSubmission = response.data.predictive_index_submission;
      console.log(piSubmission, "gybcfgdhfcgdhf");
      localStorage.setItem("piSubmissionId", piSubmission);
      setPISubmission(piSubmission);
      setIsLoading(false);
      showToast("Your predictive index test has been stored!");
      navigate("/PILastScreen");
    } catch (error) {
      console.error("Axios error:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setIsLoading(false);
      } else if (error.request) {
        console.error("No response received:", error.request);
        setIsLoading(false);
      } else {
        console.error("Error setting up the request:", error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="PI-container">
        <h2>
          <b>{t("pi-heading-secondpage")}®</b>
        </h2>

        <div className="my-5">
          <label>
            <p>
              {t("pi-p21-heading")} <b>{t("pi-p21-heading2")} </b>
            </p>
          </label>
        </div>

        <div className="PI-container">
          {/* CheckBox Content  */}
          <div className="PI-checkbox">
            <div className="row">
              <div className="col mx-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="social"
                    id="social"
                    value="social"
                    checked={checkbox2_pi.social}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="social">
                    {t("pi-social")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="neat"
                    id="neat"
                    value="neat"
                    checked={checkbox2_pi.neat}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="neat">
                    {t("pi-neat")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="patient"
                    id="patient"
                    value="patient"
                    checked={checkbox2_pi.patient}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="patient">
                    {t("pi-patient")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="reasonable"
                    id="reasonable"
                    value="reasonable"
                    checked={checkbox2_pi.reasonable}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox4">
                    {t("pi-reasonable")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="content"
                    id="content"
                    value="content"
                    checked={checkbox2_pi.content}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox5">
                    {t("pi-content")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="persistent"
                    id="persistent"
                    value="persistent"
                    checked={checkbox2_pi.persistent}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox6">
                    {t("pi-persistent")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="realistic"
                    id="realistic"
                    value="realistic"
                    checked={checkbox2_pi.realistic}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox7">
                    {t("pi-realistic")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="relaxed"
                    id="relaxed"
                    value="relaxed"
                    checked={checkbox2_pi.relaxed}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox8">
                    {t("pi-relaxed")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="dominant"
                    id="dominant"
                    value="dominant"
                    checked={checkbox2_pi.dominant}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox9">
                    {t("pi-dominant")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="analytical"
                    id="analytical"
                    value="analytical"
                    checked={checkbox2_pi.analytical}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox10">
                    {t("pi-analytical")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="satisfied"
                    id="satisfied"
                    value="satisfied"
                    checked={checkbox2_pi.satisfied}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox11">
                    {t("pi-satisfied")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="exciting"
                    id="exciting"
                    value="exciting"
                    checked={checkbox2_pi.exciting}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox12">
                    {t("pi-exciting")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="serene"
                    id="serene"
                    value="serene"
                    checked={checkbox2_pi.serene}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox13">
                    {t("pi-serene")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="unassuming"
                    id="unassuming"
                    value="unassuming"
                    checked={checkbox2_pi.unassuming}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox14">
                    {t("pi-unassuming")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="serious"
                    id="serious"
                    value="serious"
                    checked={checkbox2_pi.serious}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox15">
                    {t("pi-serious")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="assertive"
                    id="assertive"
                    value="assertive"
                    checked={checkbox2_pi.assertive}
                    onChange={onChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox16">
                    {t("pi-assertive")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="disciplined"
                    id="disciplined"
                    value="disciplined"
                    checked={checkbox2_pi.disciplined}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="disciplined">
                    {t("pi-disciplined")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="charming"
                    id="charming"
                    value="charming"
                    checked={checkbox2_pi.charming}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="charming">
                    {t("pi-charming")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="orderly"
                    id="orderly"
                    value="orderly"
                    checked={checkbox2_pi.orderly}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="orderly">
                    {t("pi-orderly")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="tolerant"
                    id="tolerant"
                    value="tolerant"
                    checked={checkbox2_pi.tolerant}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="tolerant">
                    {t("pi-tolerant")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="principled"
                    id="principled"
                    value="principled"
                    checked={checkbox2_pi.principled}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="principled">
                    {t("pi-principled")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pleasant"
                    id="pleasant"
                    value="pleasant"
                    checked={checkbox2_pi.pleasant}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="pleasant">
                    {t("pi-pleasant")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="observant"
                    id="observant"
                    value="observant"
                    checked={checkbox2_pi.observant}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="observant">
                    {t("pi-observant")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="consistent"
                    id="consistent"
                    value="consistent"
                    checked={checkbox2_pi.consistent}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="consistent">
                    {t("pi-consistent")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="calm"
                    id="calm"
                    value="calm"
                    checked={checkbox2_pi.calm}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="calm">
                    {t("pi-calm")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="tidy"
                    id="tidy"
                    value="tidy"
                    checked={checkbox2_pi.tidy}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="tidy">
                    {t("pi-tidy")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="judicious"
                    id="judicious"
                    value="judicious"
                    checked={checkbox2_pi.judicious}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="judicious">
                    {t("pi-judicious")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="meticulous"
                    id="meticulous"
                    value="meticulous"
                    checked={checkbox2_pi.meticulous}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="meticulous">
                    {t("pi-meticulous")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="formidable"
                    id="formidable"
                    value="formidable"
                    checked={checkbox2_pi.formidable}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="formidable">
                    {t("pi-formidable")}
                  </label>
                </div>
              </div>

              <div className="col mx-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="contemplative"
                    id="contemplative"
                    value="contemplative"
                    checked={checkbox2_pi.contemplative}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="contemplative">
                    {t("pi-contemplative")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="constant"
                    id="constant"
                    value="constant"
                    checked={checkbox2_pi.constant}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="constant">
                    {t("pi-constant")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="understanding"
                    id="understanding"
                    value="understanding"
                    checked={checkbox2_pi.understanding}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="understanding">
                    {t("pi-understanding")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="bold"
                    id="bold"
                    value="bold"
                    checked={checkbox2_pi.bold}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="bold">
                    {t("pi-bold")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="conventional"
                    id="conventional"
                    value="conventional"
                    checked={checkbox2_pi.conventional}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="conventional">
                    {t("pi-conventional")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="charismatic"
                    id="charismatic"
                    value="charismatic"
                    checked={checkbox2_pi.charismatic}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="charismatic">
                    {t("pi-charismatic")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="convincing"
                    id="convincing"
                    value="convincing"
                    checked={checkbox2_pi.convincing}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="convincing">
                    {t("pi-convincing")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="polished"
                    id="polished"
                    value="polished"
                    checked={checkbox2_pi.polished}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="polished">
                    {t("pi-polished")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="caring"
                    id="caring"
                    value="caring"
                    checked={checkbox2_pi.caring}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="caring">
                    {t("pi-caring")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="formal"
                    id="formal"
                    value="formal"
                    checked={checkbox2_pi.formal}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="formal">
                    {t("pi-formal")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="loyal"
                    id="loyal"
                    value="loyal"
                    checked={checkbox2_pi.loyal}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="loyal">
                    {t("pi-loyal")}
                  </label>
                </div>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="alert"
                    id="alert"
                    value="alert"
                    checked={checkbox2_pi.alert}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="alert">
                    {t("pi-alert")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="popular"
                    id="popular"
                    value="popular"
                    checked={checkbox2_pi.popular}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="popular">
                    {t("pi-popular")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="commanding"
                    id="commanding"
                    value="commanding"
                    checked={checkbox2_pi.commanding}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="commanding">
                    {t("pi-commanding")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="sympathetic"
                    id="sympathetic"
                    value="sympathetic"
                    checked={checkbox2_pi.sympathetic}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="sympathetic">
                    {t("pi-sympathetic")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="precise"
                    id="precise"
                    value="precise"
                    checked={checkbox2_pi.precise}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="precise">
                    {t("pi-precise")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="dutiful"
                    id="dutiful"
                    value="dutiful"
                    checked={checkbox2_pi.dutiful}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="dutiful">
                    {t("pi-dutiful")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="accurate"
                    id="accurate"
                    value="accurate"
                    checked={checkbox2_pi.accurate}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="accurate">
                    {t("pi-accurate")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="powerful"
                    id="powerful"
                    value="powerful"
                    checked={checkbox2_pi.powerful}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="powerful">
                    {t("pi-powerful")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="eager"
                    id="eager"
                    value="eager"
                    checked={checkbox2_pi.eager}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="eager">
                    {t("pi-eager")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="courageous"
                    id="courageous"
                    value="courageous"
                    checked={checkbox2_pi.courageous}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="courageous">
                    {t("pi-courageous")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="agreeable"
                    id="agreeable"
                    value="agreeable"
                    checked={checkbox2_pi.agreeable}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="agreeable">
                    {t("pi-agreeable")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="factual"
                    id="factual"
                    value="factual"
                    checked={checkbox2_pi.factual}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="factual">
                    {t("pi-factual")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="polite"
                    id="polite"
                    value="polite"
                    checked={checkbox2_pi.polite}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="polite">
                    {t("pi-polite")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="determined"
                    id="determined"
                    value="determined"
                    checked={checkbox2_pi.determined}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="determined">
                    {t("pi-determined")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="talkative"
                    id="talkative"
                    value="talkative"
                    checked={checkbox2_pi.talkative}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="talkative">
                    {t("pi-talkative")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="daring"
                    id="daring"
                    value="daring"
                    checked={checkbox2_pi.daring}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="daring">
                    {t("pi-daring")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="esteemed"
                    id="esteemed"
                    value="esteemed"
                    checked={checkbox2_pi.esteemed}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="esteemed">
                    {t("pi-esteemed")}
                  </label>
                </div>
              </div>

              <div className="col mx-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="engaging"
                    id="engaging"
                    value="engaging"
                    checked={checkbox2_pi.engaging}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="engaging">
                    {t("pi-engaging")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="firm"
                    id="firm"
                    value="firm"
                    checked={checkbox2_pi.firm}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="firm">
                    {t("pi-firm")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="responsive"
                    id="responsive"
                    value="responsive"
                    checked={checkbox2_pi.responsive}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="responsive">
                    {t("pi-responsive")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="careful"
                    id="careful"
                    value="careful"
                    checked={checkbox2_pi.careful}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="careful">
                    {t("pi-careful")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="aware"
                    id="aware"
                    value="aware"
                    checked={checkbox2_pi.aware}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="aware">
                    {t("pi-aware")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="relentless"
                    id="relentless"
                    value="relentless"
                    checked={checkbox2_pi.relentless}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="relentless">
                    {t("pi-relentless")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="fascinating"
                    id="fascinating"
                    value="fascinating"
                    checked={checkbox2_pi.fascinating}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="fascinating">
                    {t("pi-fascinating")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="rational"
                    id="rational"
                    value="rational"
                    checked={checkbox2_pi.rational}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="rational">
                    {t("pi-rational")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="gentle"
                    id="gentle"
                    value="gentle"
                    checked={checkbox2_pi.gentle}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="gentle">
                    {t("pi-gentle")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="competitive"
                    id="competitive"
                    value="competitive"
                    checked={checkbox2_pi.competitive}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="competitive">
                    {t("pi-competitive")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="organized"
                    id="organized"
                    value="organized"
                    checked={checkbox2_pi.organized}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="organized">
                    {t("pi-organized")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="lively"
                    id="lively"
                    value="lively"
                    checked={checkbox2_pi.lively}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="lively">
                    {t("pi-lively")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="logical"
                    id="logical"
                    value="logical"
                    checked={checkbox2_pi.logical}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="logical">
                    {t("pi-logical")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="proper"
                    id="proper"
                    value="proper"
                    checked={checkbox2_pi.proper}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="proper">
                    {t("pi-proper")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="outstanding"
                    id="outstanding"
                    value="outstanding"
                    checked={checkbox2_pi.outstanding}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="outstanding">
                    {t("pi-outstanding")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="resolute"
                    id="resolute"
                    value="resolute"
                    checked={checkbox2_pi.resolute}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="resolute">
                    {t("pi-resolute")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="harmonious"
                    id="harmonious"
                    value="harmonious"
                    checked={checkbox2_pi.harmonious}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="harmonious">
                    {t("pi-harmonious")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="earnest"
                    id="earnest"
                    value="earnest"
                    checked={checkbox2_pi.earnest}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="earnest">
                    {t("pi-earnest")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="nice"
                    id="nice"
                    value="nice"
                    checked={checkbox2_pi.nice}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="nice">
                    {t("pi-nice")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="appealing"
                    id="appealing"
                    value="appealing"
                    checked={checkbox2_pi.appealing}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="appealing">
                    {t("pi-appealing")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="stable"
                    id="stable"
                    value="stable"
                    checked={checkbox2_pi.stable}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="stable">
                    {t("pi-stable")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="influential"
                    id="influential"
                    value="influential"
                    checked={checkbox2_pi.influential}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="influential">
                    {t("pi-influential")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="dignified"
                    id="dignified"
                    value="dignified"
                    checked={checkbox2_pi.dignified}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="dignified">
                    {t("pi-dignified")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="outgoing"
                    id="outgoing"
                    value="outgoing"
                    checked={checkbox2_pi.outgoing}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="outgoing">
                    {t("pi-outgoing")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="respectful"
                    id="respectful"
                    value="respectful"
                    checked={checkbox2_pi.respectful}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="respectful">
                    {t("pi-respectful")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="unobtrusive"
                    id="unobtrusive"
                    value="unobtrusive"
                    checked={checkbox2_pi.unobtrusive}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="unobtrusive">
                    {t("pi-unobtrusive")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="communicative"
                    id="communicative"
                    value="communicative"
                    checked={checkbox2_pi.communicative}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="communicative">
                    {t("pi-communicative")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="easygoing"
                    id="easygoing"
                    value="easygoing"
                    checked={checkbox2_pi.easygoing}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="easygoing">
                    {t("pi-easygoing")}
                  </label>
                </div>

                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="objective"
                    id="objective"
                    value="objective"
                    checked={checkbox2_pi.objective}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="objective">
                    {t("pi-objective")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {
            !canNavigate ? (
              <div className="pi-warning mt-4">
                <p>{t("pi-last-caution")}</p>
              </div>
            ) : null
          }

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Link
              className="login-form-button text-decoration-none"
              style={{ backgroundColor: "gray" }}
              to="/PIFourthScreen"
            >
              Back
            </Link>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              {!canNavigate ? (
                <button
                  type="submit"
                  className="login-form-button text-decoration-none disabled"
                  disabled
                  style={{ backgroundColor: "gray", color: "white" }}
                >
                  Submit
                </button>
              ) : (
                <Link
                  type="submit"
                  className="login-form-button text-decoration-none"
                  onClick={handleSavePITest}
                >
                  Submit
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Progress /> : null}
      <Copyright />
    </>
  );
}

export default PIFivthScreen;
