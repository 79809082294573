import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { UserLogin } from "../../../context/AuthContext";
import timer from "../../../assets/img/TimeCircle.png";
import { useTranslation } from "react-i18next";

function InQsSection1() {
  const { t } = useTranslation();
  const { setIsTimerRunning, isTimerRunning, setTimerValue, timerValue, currentQuestionIndex, totalQuestions, formatTimer } =
    UserLogin();

  const startTimeRef = useRef(null);
  const intervalIdRef = useRef(null);

  /***** Functions to format timer for interview ******/
  useEffect(() => {
    if (isTimerRunning) {
      startTimeRef.current = performance.now() - timerValue * 1000;
      intervalIdRef.current = setInterval(() => {
        const elapsed = Math.floor((performance.now() - startTimeRef.current) / 1000);
        setTimerValue(elapsed);
        if (elapsed >= 3600) {
          setIsTimerRunning(false);
          clearInterval(intervalIdRef.current);
        }
      }, 1000);
    } else if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isTimerRunning, setIsTimerRunning, setTimerValue]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (timerValue > 0) {
        event.returnValue =
          "You are not allowed to go back during the interview.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [timerValue]);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link className="navbar-brand">
            {t("interive-with-cds")}
          </Link>
          <Link className="nav-item my-2 tour-step-one" style={{ color: "gray", textDecoration: "none" }}>
            {currentQuestionIndex + 1}/{totalQuestions} Questions
          </Link>
          <ul className="navbar-nav flex-row">
            <li className="nav-item mx-2 my-2">
              <div className="tour-step-two">
                <img src={timer} alt="timer" />&nbsp;
                {formatTimer(timerValue)} / 60:00
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default InQsSection1;
