import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { UserLogin } from "../../../../context/AuthContext";
import imgPI2_1 from "../../../../assets/img/pi-4.png";
import imgPI2_2 from "../../../../assets/img/pi-8.png";
import imgPI2_3 from "../../../../assets/img/pi-14.png";
import imgPI2_4 from "../../../../assets/img/pi-12.png";
import imgPI2_5 from "../../../../assets/img/pi-11.png";
import { useTranslation } from "react-i18next";

function Section2() {
  const { piReportData } = UserLogin();

  const { t } = useTranslation();

  return (
    <div className="pi-section-container">
      <div class="grid-container">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="flip-card-back-dimensions d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                <h5 className="card-title pi-details-title">{t("strength")}</h5>
                <img
                  src={imgPI2_1}
                  className="card-img-top pi-img-detailed pi-small-img"
                  alt="..."
                />
              </div>
            </div>
            <div class="flip-card-back d-flex align-items-center">
              <div className="flip-card-back-dimensions ">
                <h5 className="card-title pi-details-title">{t("strength")}</h5>
                <div className="card-text text-center bar-pi-details">
                  <p className="px-3">
                    {piReportData?.analysis_data?.detailed_analysis?.strengths}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="flip-card-back-dimensions d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                <h5 className="card-title pi-details-title">{t("work-style")}</h5>
                <img
                  src={imgPI2_2}
                  className="card-img-top pi-img-detailed pi-grid-work-img"
                  alt="..."
                />
              </div>
            </div>
            <div class="flip-card-back d-flex align-items-center">
              <div className="flip-card-back-dimensions">
                <h5 className="card-title pi-details-title">{t("work-style")}</h5>
                <div className="card-text text-center bar-pi-details">
                  <p className="px-3">
                    {piReportData?.analysis_data?.detailed_analysis?.work_style}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="flip-card-back-dimensions d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                <h5 className="card-title pi-details-title">{t("decision-making")}</h5>
                <img
                  src={imgPI2_3}
                  className="card-img-top pi-img-detailed pi-grid-work-img"
                  alt="..."
                />
              </div>
            </div>
            <div class="flip-card-back d-flex align-items-center">
              <div className="flip-card-back-dimensions ">
                <h5 className="card-title pi-details-title">{t("decision-making")}</h5>
                <div className="card-text text-center bar-pi-details">
                  <p className="px-3">
                    {piReportData?.analysis_data?.detailed_analysis?.decision_making}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="flip-card-back-dimensions d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                <h5 className="card-title pi-details-title">
                  {t("development")} <br />
                  {t("potential")}
                </h5>
                <img
                  src={imgPI2_4}
                  className="card-img-top pi-img-detailed pi-grid-work-img"
                  alt="..."
                />
              </div>
            </div>
            <div class="flip-card-back d-flex align-items-center">
              <div className="flip-card-back-dimensions">
                <h5 className="card-title pi-details-title">
                {t("development")} <br />
                {t("potential")}
                </h5>
                <div className="card-text text-center bar-pi-details">
                  <p className="px-3">
                    {piReportData?.analysis_data?.detailed_analysis?.development_potential}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="flip-card-back-dimensions d-flex flex-column align-items-center justify-content-center" style={{ height: "100%" }}>
                <h5 className="card-title pi-details-title">
                  {t("team")} <br />
                  {t("collaboration")}
                </h5>
                <img
                  src={imgPI2_5}
                  className="card-img-top pi-img-detailed pi-grid-work-img"
                  alt="..."
                />
              </div>
            </div>
            <div class="flip-card-back d-flex align-items-center">
              <div className="flip-card-back-dimensions">
                <h5 className="card-title pi-details-title">
                {t("team")} <br />
                {t("collaboration")}
                </h5>
                <div className="card-text text-center bar-pi-details">
                  <p className="px-3">
                    {piReportData?.analysis_data?.detailed_analysis?.team_collaboration}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
