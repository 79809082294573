import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { UserLogin } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
function ReportSection3() {
  const { value, handleTabChange } = UserLogin();
  const { t } = useTranslation();

  return (
    <div>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-8">
          <Box sx={{ width: "100%" }}>
            <BottomNavigation
              showLabels
              className="mx-0"
              value={value}
              onChange={handleTabChange}
              style={{
                backgroundColor: "#f1f1f8",
                borderRadius: "50px",
                height: "85px",
                padding: "10px",
              }}
            >
              <BottomNavigationAction
                label={<span className="report-tabs">{t("vocabulary")}</span>}
                sx={{
                  minWidth: "31%",
                  color: value === 0 ? "white !important" : "#606070",
                  background:
                    value === 0 ? "linear-gradient(180deg, #56367F 0%, #3A5697 100%)" : "",
                  borderRadius: value === 0 ? "50px" : "0",
                }}
                className="customWidth mx-1"
              />

              <BottomNavigationAction
                label={
                  <span className="report-tabs">
                    {t("pronunciation")} <br />
                    &amp; {t("fluency")}
                  </span>
                }
                sx={{
                  minWidth: "33%",
                  color: value === 1 ? "white !important" : "#606070",
                  background:
                    value === 1 ? "linear-gradient(180deg, #56367F 0%, #3A5697 100%)" : "",
                  borderRadius: value === 1 ? "50px" : "0",
                }}
                className="customWidth mx-1"
              />

              <BottomNavigationAction
                label={<span className="report-tabs">{t("grammar")}</span>}
                className="customWidth mx-1"
                sx={{
                  minWidth: "31%",
                  color: value === 2 ? "white !important" : "#606070",
                  background:
                    value === 2 ? "linear-gradient(180deg, #56367F 0%, #3A5697 100%)" : "",
                  borderRadius: value === 2 ? "50px" : "0",
                }}
              />
            </BottomNavigation>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ReportSection3;
