import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import faqImg from "../../../../assets/img/faq-work.png";
import faqRocket from "../../../../assets/img/faq-rocket.png";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";

const Faqs = () => {
  const { t } = useTranslation();

  const faqWork = [
    {
      question: `${t("faq-work-qs1-heading")}`,
      answer: `${t("faq-work-qs1-p")}`,
    },
    {
      question: `${t("faq-work-qs2-heading")}`,
      answer: `${t("faq-work-qs2-p")}`,
    },
    {
      question: `${t("faq-work-qs4-heading")}`,
      answer: `${t("faq-work-qs4-p")}`,
    },

    {
      question: `${t("faq-work-qs6-heading")}`,
      answer: `${t("faq-work-qs6-p")}`,
    },
  ];

  const faqPrepare = [
    {
      question: `${t("faq-oralprep-q2")}`,
      answer: `${t("faq-oralprep-a2")}`,
    },
    {
      question: `${t("faq-oralprep-q3")}`,
      answer: `${t("faq-oralprep-a3")}`,
    },
    {
      question: `${t("faq-oralprep-q4")}`,
      answer: `${t("faq-oralprep-a4")}`,
    },
    {
      question: `${t("faq-oralprep-q5")}`,
      answer: `${t("faq-oralprep-a5")}`,
    },
  ];

  const [showStates, setShowStates] = useState(Array(faqWork.length).fill(false));
  const [helloShowStates, setHelloShowStates] = useState(Array(faqPrepare.length).fill(false));

  const toggleQuestion = (index) => {
    const updatedStates = showStates.map((state, i) => (i === index ? !state : false));
    setShowStates(updatedStates);
  };

  const toggleHelloQuestion = (index) => {
    const updatedStates = helloShowStates.map((state, i) => (i === index ? !state : false));
    setHelloShowStates(updatedStates);
  };

  return (
    <div className="container-fluid my-5 d-flex flex-column gap-3">
      {/* How To Work Div */}
      <div className="d-flex  align-items-center col-12 d-flex flex-lg-row flex-column-reverse justify-content-center">
        <div className="col-lg-5 col-10 d-flex flex-column align-items-md-start align-items-center">
          <h2 className="faq-heading text-center m-4">{t("faq-work-div-heading")}</h2>
          {faqWork.map((q, index) => (
            <div className="question-section my-1 col-12" key={index}>
              <div
                key={index}
                className={`collapse-area  ${showStates[index] && "collapse-show"}`}
                onClick={() => toggleQuestion(index)}
              >
                <div
                  className={`d-flex cv-faq-header ${
                    showStates[index] && "active"
                  } align-items-center gap-5 justify-content-between px-4 py-3`}
                >
                  <h2 className={`question ${showStates[index] && "question-active"}`}>
                    {q.question}
                  </h2>
                  {showStates[index] ? <ArrowUpIcon sx={{ color: "white" }} /> : <ArrowDownIcon />}
                </div>
                {showStates[index] && <p className="answer bg-white col-12 my-0 p-3">{q.answer}</p>}
              </div>
            </div>
          ))}
        </div>
        <div className="col-lg-5 col-12 d-flex align-items-center mb-lg-0 mb-3 justify-content-center">
          <img src={faqImg} alt="" className="faq-work" />
        </div>
      </div>

      {/* How to Prepare Div */}
      <div className="d-flex mt-5 align-items-center col-12 d-flex flex-lg-row flex-column justify-content-center">
        <div className="col-lg-5 col-12 d-flex align-items-center mb-lg-0 mb-3 justify-content-center">
          <img src={faqRocket} alt="" className="faq-rocket" />
        </div>
        <div className="col-lg-5 col-10 d-flex flex-column align-items-md-start align-items-center">
          <h2 className="faq-heading text-lg-start text-center m-4">{t("faq-prepare-div-heading")}</h2>
          {faqPrepare.map((q, index) => (
            <div className="question-section my-1 col-12" key={index}>
              <div
                key={index}
                className={`collapse-area  ${helloShowStates[index] && "collapse-show"}`}
                onClick={() => toggleHelloQuestion(index)}
              >
                <div
                  className={`d-flex cv-faq-header ${
                    helloShowStates[index] && "active"
                  } align-items-center gap-5 justify-content-between px-4 py-3`}
                >
                  <h2 className={`question ${helloShowStates[index] && "question-active"}`}>
                    {q.question}
                  </h2>
                  {helloShowStates[index] ? <ArrowUpIcon sx={{ color: "white" }} /> : <ArrowDownIcon />}
                </div>
                {helloShowStates[index] && <p className="answer bg-white col-12 my-0  px-3 py-2">{q.answer}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
