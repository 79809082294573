import React, { useCallback, useEffect, useState } from "react";
import logo from "../../../../assets/img/onbLogoPng.png";
import profileIcon from "../../../../assets/img/profileIcon.png";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { PiCaretUpBold } from "react-icons/pi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserLogin } from "../../../../context/AuthContext";
import logout from "../../../../assets/img/logoutIcon.png";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import usflagImg from "../../../../assets/img/us_flag.png";
import dutchflagImg from "../../../../assets/img/dutch_flag.png";
import spanishflagImg from "../../../../assets/img/sp_flag.png";
import popiaflagImg from "../../../../assets/img/popia_flag.png";
import { USER_PROFILE_URL } from "../../../../Auth_API";
import { useTour } from "@reactour/tour";

const Navbar = ({ rerender }) => {
  const { i18n, t } = useTranslation();
  const [, setRerender] = useState(0);
  let navigation = useNavigate();
  const location = useLocation();
  const { setIsOpen, setSteps, steps } = useTour();
  const [modalOpen, setModalOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const {
    userData,
    stickyNav,
    setstickyNav,
    toTop,
    settoTop,
    nav,
    setNav,
    setDisplayedFlag,
    displayedFlag,
    langChange,
    setLangChange,
  } = UserLogin();
  const { name, token } = userData || {};

  const onOpen = () => {
    setNav(!nav);
  };
  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY || window.pageYOffset;
    scrollY > stickyNav ? setstickyNav(true) : setstickyNav(false);
    scrollY > 300 ? settoTop(true) : settoTop(false);
  }, [stickyNav, setstickyNav, settoTop]);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector(nav);
    if (navbar) {
      const rect = navbar.getBoundingClientRect();
      setstickyNav(rect.height);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nav, handleScroll]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  /* Logout Modal */
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  /* Logout Function to remove token */
  const handleLogout = async () => {
    const element = document.querySelectorAll(".modal-backdrop");
    element.forEach((item) => {
      item.style.display = "none";
    });
    localStorage.removeItem("token");
    localStorage.removeItem("uerToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("organizationUrl");
    localStorage.removeItem("isPageRefreshed");
    localStorage.removeItem("sessionTime");
    window.location.reload();
    window.location.href = "/login";
  };

  const handleUpdateLang = async (lang) => {
    try {
      const apiUrl = `${USER_PROFILE_URL}/set-language/`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ language: lang.toUpperCase() }),
      });

      localStorage.setItem("userLanguage", lang.toUpperCase());
      setLangChange(lang.toUpperCase());
      console.log(location.pathname);
      if (location.pathname !== "/InterviewQs") {
        // window.location.reload();
      }

      if (!response.ok) {
        if (location.pathname !== "/InterviewQs") {
          // window.location.reload();
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (location.pathname !== "/InterviewQs") {
        // window.location.reload();
      }
    }
  };

  /* Language change dropdown function */
  const changeLanguageAuth = (lang) => {
    console.log(lang.toUpperCase(), "in changeAuth");
    let flagImage;
    switch (lang) {
      case "en":
        flagImage = usflagImg;
        break;
      case "sp":
        flagImage = spanishflagImg;
        break;
      case "du":
        flagImage = dutchflagImg;
        break;
      case "pa":
        flagImage = popiaflagImg;
        break;
      default:
        flagImage = usflagImg;
    }
    setLangChange(lang);
    localStorage.setItem("userLanguage", lang.toUpperCase());
    setDisplayedFlag(flagImage);
    i18n.changeLanguage(lang);
    handleUpdateLang(lang);
  };

  useEffect(() => {
    console.log("pathname", location.pathname);
    const storedLanguage = localStorage.getItem("userLanguage");
    const userLanguage = storedLanguage ? storedLanguage.toLowerCase() : "en";

    setLangChange(userLanguage);
    switch (userLanguage) {
      case "en":
        setDisplayedFlag(usflagImg);
        break;
      case "sp":
        setDisplayedFlag(spanishflagImg);
        break;
      case "du":
        setDisplayedFlag(dutchflagImg);
        break;
      case "pa":
        setDisplayedFlag(popiaflagImg);
        break;
      default:
        setDisplayedFlag(usflagImg);
    }
    i18n.changeLanguage(userLanguage);
  }, []);

  const isHomePage = () => {
    return location.pathname === "/Main" || location.pathname === "/main";
  };

  const handleChangeSteps = (method) => {
    console.log("method", method);

    //language is spanish
    if (method === "sp") {
      setSteps([
        {
          selector: '[data-tour="1"]',
          content: (
            <>
              <h3 className="step-heading">Paso 1:</h3>
              <p>Su primer paso es cargar su CV para comenzar.</p>
            </>
          ),
          highlightedSelectors: [".ReactModal__Content"],
          mutationObservables: [".ReactModal__Overlay"],
        },
        {
          selector: '[data-tour="2"]',
          content: (
            <>
              <h3 className="step-heading">Paso 2:</h3>
              <p>Elija una prueba y demuestre sus habilidades lingüísticas.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="3"]',
          content: (
            <>
              <h3 className="step-heading">Paso 3:</h3>
              <p>Realice la evaluación para conocer su personalidad y preferencias laborales.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="4"]',
          content: (
            <>
              <h3 className="step-heading">Paso 4:</h3>
              <p>Acceda a sus informes de evaluación en la pestaña "Informes" de este menú.</p>
            </>
          ),
        },
      ]);
    }

    // language is dutch
    else if (method === "du") {
      setSteps([
        {
          selector: '[data-tour="1"]',
          content: (
            <>
              <h3 className="step-heading">Stap 1:</h3>
              <p>Uw eerste stap is om uw CV te uploaden om te beginnen.</p>
            </>
          ),
          highlightedSelectors: [".ReactModal__Content"],
          mutationObservables: [".ReactModal__Overlay"],
        },
        {
          selector: '[data-tour="2"]',
          content: (
            <>
              <h3 className="step-heading">Stap 2:</h3>
              <p>Kies een test en demonstreer uw taalvaardigheden.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="3"]',
          content: (
            <>
              <h3 className="step-heading">Stap 3:</h3>
              <p>
                Doe de beoordeling om meer te weten te komen over uw persoonlijkheid en
                werkvoorkeuren.
              </p>
            </>
          ),
        },
        {
          selector: '[data-tour="4"]',
          content: (
            <>
              <h3 className="step-heading">Stap 4:</h3>
              <p>Toegang tot uw beoordelingsrapporten in het tabblad "Rapporten" van dit menu.</p>
            </>
          ),
        },
      ]);
    }

    //language is english
    else if (method === "en") {
      setSteps([
        {
          selector: '[data-tour="1"]',
          content: (
            <>
              <h3 className="step-heading">Step 1:</h3>
              <p>Your first step is to upload your CV to get started.</p>
            </>
          ),
          highlightedSelectors: [".ReactModal__Content"],
          mutationObservables: [".ReactModal__Overlay"],
        },
        {
          selector: '[data-tour="2"]',
          content: (
            <>
              <h3 className="step-heading">Step 2:</h3>
              <p>Choose a test and demonstrate your language skills.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="3"]',
          content: (
            <>
              <h3 className="step-heading">Step 3:</h3>
              <p>Take the assessment to learn about your personality and work preferences.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="4"]',
          content: (
            <>
              <h3 className="step-heading">Step 4:</h3>
              <p>Access your assessment reports in the "Reports" tab from this menu.</p>
            </>
          ),
        },
      ]);
    }

    //language is pa
    else {
      setSteps([
        {
          selector: '[data-tour="1"]',
          content: (
            <>
              <h3 className="step-heading">Paso 1:</h3>
              <p>Bo prome paso ta subi bo CV pa cuminsa.</p>
            </>
          ),
          highlightedSelectors: [".ReactModal__Content"],
          mutationObservables: [".ReactModal__Overlay"],
        },
        {
          selector: '[data-tour="2"]',
          content: (
            <>
              <h3 className="step-heading">Paso 2:</h3>
              <p>Scoge un test y demostra bo habilidadnan di idioma.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="3"]',
          content: (
            <>
              <h3 className="step-heading">Paso 3:</h3>
              <p>Haci e evaluacion pa conoce bo personalidad y preferencia di trabao.</p>
            </>
          ),
        },
        {
          selector: '[data-tour="4"]',
          content: (
            <>
              <h3 className="step-heading">Paso 4:</h3>
              <p>Acces bo raportnan di evaluacion den e tab "Rapportnan" for di e menu aki.</p>
            </>
          ),
        },
      ]);
    }
  };

  return (
    <>
      {/* Button show when scroll */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {isHomePage() && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              className="navigate-tour"
              style={{ display: "flex", flex: 1 }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <i className="bi bi-question-circle"></i>
            </button>
            <button
              type="button"
              className="navigate-up"
              style={{ display: toTop ? "flex" : "none", flex: 1 }}
              onClick={scrollToTop}
            >
              <PiCaretUpBold />
            </button>
          </div>
        )}
      </div>

      {/* Navbar Menu to show items when screen size smaller */}
      <ul
        className={`navbar-nav mobile-nav d-xl-none d-lg-none d-flex flex-column align-items-center ${
          nav && "open-mobile-nav"
        } gap-xl-4 gap-lg-4 gap-0 mt-xl-0 mt-lg-0 d-flex`}
      >
        <div className="d-flex justify-content-end align-items-center w-100">
          <button type="button" className="close-btn">
            <MdOutlineClose className="close-icon" onClick={() => setNav(false)} />
          </button>
        </div>

        {/* logo img */}
        <div
          onClick={() => {
            navigation("/Main");
          }}
          className="mt-3"
        >
          <img
            src={logo}
            style={{ cursor: "pointer", width: "100px" }}
            alt="CDS"
            className="navbar-onb-logo"
          />
        </div>

        <li className="nav-items" onClick={() => setNav(false)} style={{ marginBottom: "15px" }}>
          <Link
            className="dropdown-item mt-4 small-nav-items"
            to="/Reports"
            onClick={() => setNav(false)}
          >
            {t("nav-oral-report-text")}
          </Link>
        </li>
        <li className="nav-items" onClick={() => setNav(false)} style={{ marginBottom: "15px" }}>
          <Link
            className="dropdown-item small-nav-items"
            to="/predictive_report"
            onClick={() => setNav(false)}
          >
            {t("nav-pi-report-text")}
          </Link>
        </li>

        <li onClick={() => setNav(false)} style={{ marginBottom: "15px" }}>
          <Link to="/FAQScreen" className="small-nav-items">
            {t("nav-faqs")}{" "}
          </Link>
        </li>
        {!isHomePage() && (
          <li>
            <Link className="dropdown-item mb-3 small-nav-items" to="/Main">
              {t("nav-progress")}
            </Link>
          </li>
        )}

        {!isHomePage() && (
          <li onClick={() => setNav(false)} style={{ marginBottom: "15px" }}>
            <Link
              className="dropdown-item small-nav-items"
              to="/Main"
              onClick={() => setNav(false)}
            >
              {t("nav-interview")}
            </Link>
          </li>
        )}
        <li onClick={() => setNav(false)} style={{ marginBottom: "25px" }}>
          <Link
            className="dropdown-item small-nav-items"
            to="/Contact"
            onClick={() => setNav(false)}
          >
            {t("nav-contact")}
          </Link>
        </li>
        <li onClick={() => setNav(false)} style={{ marginBottom: "15px" }}>
          <Link
            onClick={() => {
              setNav(false);
              setModalOpen(true);
            }}
            className="dropdown-item small-nav-items"
            style={{ color: "red" }}
          >
            {t("nav-logout")}
          </Link>
        </li>
      </ul>

      {/* Navbar Menu to bigger screen */}
      <nav
        className={`navbar  py-2 px-md-0 px-3 px-xl-5 px-lg-5 px-md-3 px-2 navbar-expand-lg navbar-light d-flex justify-content-between bg-white`}
      >
        <div
          onClick={() => {
            navigation("/");
          }}
        >
          <img
            src={logo}
            className="login-form-logo navbar-onb-logo"
            style={{ cursor: "pointer" }}
            alt="CDS"
          />
        </div>
        <ul className="navbar-nav d-xl-flex d-lg-flex d-none gap-xl-4 gap-lg-4 gap-0 mt-xl-0 mt-lg-0 mt-4">
          <li className="nav-items">
            <Link
              to="/Main"
              className={`nav-link ${location.pathname === "/Main" ? "active" : ""}`}
            >
              <span>{t("nav-home")}</span>
            </Link>
          </li>
          <li className="nav-items" data-tour={!isScreenSmall ? "4" : ""}>
            <div className="dropdown d-flex gap-2 align-items-center">
              <Link
                data-bs-toggle="dropdown"
                aria-expanded="false"
                to="/Reports"
                className={`nav-link ${location.pathname === "/Reports" ? "active" : ""}`}
              >
                <span>
                  {t("nav-report")}
                  <IoMdArrowDropdown size={20} color="#A9A9A9" />
                </span>
              </Link>
              <ul
                className="dropdown-menu lang-dropdown-menu my-3"
                style={{
                  marginTop: "20%",
                  // left: "-220px",
                  width: "270px",
                  height: "150px",
                }}
              >
                &nbsp;
                <li>
                  <Link className="dropdown-item" to="/Reports">
                    {t("nav-oral-report-text")}
                  </Link>
                  &nbsp;
                </li>
                <li>
                  <Link className="dropdown-item" to="/predictive_report">
                    {t("nav-pi-report-text")}
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-items">
            <Link
              to="/FAQScreen"
              className={`nav-link ${location.pathname === "/FAQScreen" ? "active" : ""}`}
            >
              <span>{t("nav-faqs")}</span>
            </Link>
          </li>
        </ul>

        <div className=" d-flex gap-2 align-items-center">
          <div className="dropdown" data-tour="5">
            <Link
              className={`nav-link d-flex gap-1 align-items-center ${
                location.pathname === "/lang" ? "active" : ""
              }`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              width={25}
            >
              <img src={displayedFlag} alt="" width={25} />
              <p className="m-0 fw-normal d-sm-block d-none">language</p>
              <span style={{ textTransform: "uppercase" }}>{langChange}</span>
              <IoMdArrowDropdown size={20} color="#A9A9A9" />
            </Link>

            {/* Language Dropdown */}
            <ul className="dropdown-menu dropdown-lang-menu mt-4">
              <li>
                <Link class="dropdown-item mt-2" onClick={() => {changeLanguageAuth("en"); handleChangeSteps("en");}}>
                  <img src={usflagImg} width={25} alt="" /> English (EN)
                </Link>
              </li>
              <li>
                <Link
                  class="dropdown-item lang-dropdown mt-2"
                  onClick={() => {changeLanguageAuth("sp"); handleChangeSteps("sp");}}
                >
                  <img src={spanishflagImg} width={25} alt="" /> Spanish (SP)
                </Link>
              </li>

              <li>
                <Link
                  class="dropdown-item lang-dropdown mt-2"
                  onClick={() => {changeLanguageAuth("du"); handleChangeSteps("du");}}
                >
                  <img src={dutchflagImg} width={25} alt="" /> Dutch (DU)
                </Link>
              </li>
              <li>
                <Link
                  class="dropdown-item lang-dropdown mt-2"
                  onClick={() => {changeLanguageAuth("pa"); handleChangeSteps("pa");}}
                >
                  <img src={popiaflagImg} width={25} alt="" /> Papiamentu (P)
                </Link>
              </li>
            </ul>
          </div>

          {/* profile icon */}
          <div
            className="dropdown d-xl-flex d-lg-flex d-md-flex d-none"
            style={{ display: "flex" }}
          >
            <Link
              to="/"
              className="nav-large-namelink px-2 nav-link-home"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-large-username d-flex align-items-center gap-2">
                <img
                  src={profileIcon}
                  alt=""
                  style={{
                    height: "45px",
                    padding: "8px",
                    backgroundColor: " rgba(86, 54, 127, 1)",
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <p className="p-0 m-0">
                    {typeof name === "string" && name.length > 0
                      ? name.charAt(0).toUpperCase() + name.slice(1)
                      : "User"}
                  </p>
                  <p className="m-0 p-0 text-secondary">{t("account_settings")}</p>
                </div>
                &nbsp;
              </div>
            </Link>

            <ul className="dropdown-menu dropdown-profile-menu my-3 ms-2">
              {!isHomePage() && (
                <li>
                  <Link className="dropdown-item" to="/Main">
                    {t("nav-progress")}
                  </Link>
                </li>
              )}

              {!isHomePage() && (
                <li onClick={() => setNav(false)} style={{ marginBottom: "10px" }}>
                  <Link className="dropdown-item mt-2" to="/Main" onClick={() => setNav(false)}>
                    {t("nav-interview")}
                  </Link>
                </li>
              )}
              <li>
                <Link className="dropdown-item mb-2" to="/Contact">
                  {t("nav-contact")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="dropdown-item"
                  style={{ color: "red" }}
                >
                  {t("nav-logout")}
                </Link>
              </li>
            </ul>
          </div>

          <button
            type="button"
            className="toggle-btn d-xl-none d-lg-none d-block mb-1 p-0"
            onClick={onOpen}
            data-tour={isScreenSmall ? "4" : ""}
          >
            <FaBars />
          </button>
        </div>
      </nav>

      {/* Logout Modal */}
      <div
        className={`modal fade ${modalOpen ? "show" : ""}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!modalOpen}
        style={{
          display: modalOpen ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "300px" }}
        >
          <div className="modal-content">
            <div className="modal-body" style={{ textAlign: "center" }}>
              <div className="d-flex justify-content-end">
                <button className="btn-close-logout" onClick={toggleModal} aria-label="Close">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <img src={logout} alt="" width={100} className="my-3 ms-2" />
              <p className="fw-bold fs-5">{t("logout-modal-text")}</p>
              <div className="mt-4">
                <button
                  type="button"
                  className="login-form-button shadow-none disabled fw-normal py-2 px-3"
                  style={{ fontSize: "14px" }}
                  data-bs-dismiss="modal"
                  onClick={toggleModal}
                >
                  {t("logout-modal-btn-cancel")}
                </button>
                &nbsp;
                <button
                  type="button"
                  className="login-form-button shadow-none fw-normal py-2 px-3"
                  style={{ fontSize: "14px" }}
                  onClick={handleLogout}
                >
                  {t("nav-logout")}
                </button>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
