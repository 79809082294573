import { useState } from "react";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import tcBlocks from "../../assets/img/tcBlocks.svg";
import tcPolygonBottomLeft from "../../assets/img/tcPolygonBottomLeft.svg";
import tcPolygonTopLeft from "../../assets/img/tcPolygonTopLeft.svg";
import tcRightPolygon from "../../assets/img/tcRightPolygon.svg";
import tcCells from "../../assets/img/tcCells.png";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

function FAQScreen() {
  const { t } = useTranslation();

  const faqWork = [
    {
      question: `${t("faq-qs-one")}`,
      answer: `${t("faq-ans-one")}`,
    },
    {
      question: `${t("faq-qs-two")}`,
      answer: `${t("faq-ans-two")}`,
    },
    {
      question: `${t("faq-qs-three")}`,
      answer: `${t("faq-ans-three")}`,
    },

    {
      question: `${t("faq-qs-four")}`,
      answer: `${t("faq-ans-four")}`,
    },
    {
      question: `${t("faq-qs-five")}`,
      answer: `${t("faq-ans-five")}`,
    },
    {
      question: `${t("faq-qs-six")}`,
      answer: `${t("faq-ans-six")}`,
    },
    {
      question: `${t("faq-qs-seven")}`,
      answer: `${t("faq-ans-seven")}`,
    },

    {
      question: `${t("faq-qs-eight")}`,
      answer: `${t("faq-ans-eight")}`,
    },
    {
      question: `${t("faq-qs-nine")}`,
      answer: `${t("faq-ans-nine")}`,
    },
  ];
  const [showStates, setShowStates] = useState(Array(faqWork.length).fill(false));

  const toggleQuestion = (index) => {
    console.log("index: ", index);
    const updatedStates = showStates.map((state, i) => (i === index ? !state : false));
    setShowStates(updatedStates);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-column align-items-center terms-page">
        <div class="terms-header-container col-12 d-flex flex-column gap-2 align-items-center justify-content-center">
          <h1 className="fw-bold text-center text-white">FAQ's</h1>
          <p className="text-center">{t("last-updated")}</p>
          <img src={tcPolygonTopLeft} alt="" className="tc-topleft-poly" />
          <img src={tcBlocks} alt="" className="tc-blocks" />
          <img src={tcCells} alt="" className="tc-cells" />
          <img src={tcRightPolygon} alt="" className="tc-right-poly" />
          <img src={tcPolygonBottomLeft} alt="" className="tc-bottomleft-poly" />
        </div>
        <svg width="0" height="0">
          <defs>
            <clipPath id="rounded-bottom-clip" clipPathUnits="objectBoundingBox">
              <path d="M0,0 H1 V0.8 C0.5,1 0.5,1 0,0.8 Z" />
            </clipPath>
            <clipPath id="rounded-bottom-clip-mobile" clipPathUnits="objectBoundingBox">
              <path d="M0,0 H1 V0.9 C0.5,1 0.5,1 0,0.9 Z" />
            </clipPath>
          </defs>
        </svg>

        {/* FAQ Work Section */}
        <div className="d-flex justify-content-start col-sm-10 col-11 my-4">
          <div className="d-flex  align-items-center col-12 d-flex flex-lg-row flex-column-reverse justify-content-center">
            <div className="col-lg-8 col-10 d-flex flex-column align-items-md-start align-items-center">
              {faqWork.map((q, index) => (
                <div className="question-section my-1 col-12" key={index}>
                  <div
                    key={index}
                    className={`collapse-area  ${showStates[index] && "collapse-show"}`}
                    onClick={() => toggleQuestion(index)}
                  >
                    <div
                      className={`d-flex cv-faq-header ${
                        showStates[index] && "active"
                      } align-items-center gap-5 justify-content-between px-4 py-3`}
                    >
                      <h2 className={`question ${showStates[index] && "question-active"}`}>
                        {q.question}
                      </h2>
                      {showStates[index] ? (
                        <ArrowUpIcon sx={{ color: "white" }} />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </div>
                    {showStates[index] && (
                      <p className="answer bg-white col-12 my-0 p-3">{q.answer}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default FAQScreen;
