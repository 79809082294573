import React, { useState, useEffect,useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { UserLogin } from "../../context/AuthContext";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import InQsSection1 from "./InterviewQSSections/InQsSection1";
import InQsSection2 from "./InterviewQSSections/InQsSection2";
import InQsSection3 from "./InterviewQSSections/InQsSection3";
import Copyright from "../Home/HomeSections/Section5/Copyright";
import Joyride, { STATUS } from "react-joyride";
import "../../App.css";
import { useTranslation } from "react-i18next";


export default function InterviewQs() {
  const {
    recordedAudio,
    run,
    setRun,
    setIsNextClicked,
    steps,
    spanishSteps,
    dutchSteps,
    papiamentoSteps,
    modalOpen,
    toggleModal,
    isNextDisabled,
    langChange,
  } = UserLogin();

  const { t } = useTranslation();
  const [autoStart, setAutoStart] = useState(false);

  const getInitialSteps = useCallback(() => {
    console.log("langChange in interview", langChange.toUpperCase());
    switch (langChange.toUpperCase()) {
      case "SP":
        return spanishSteps;
      case "DU":
        return dutchSteps;
      case "PA":
        return papiamentoSteps;
      case "EN":
        return steps;
      default:
        return steps;
    }
  },[langChange]);

  const finalSteps = useMemo(() => getInitialSteps(), [getInitialSteps]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setAutoStart(true);
    }, 2000);
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    if (autoStart) {
      setRun(true);
    }
  }, [autoStart, setRun]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      // setIsTimerRunning(true)
    }
  };

  const handleCloseModal = () => {
    if (isNextDisabled) {
      return;
    }

    setIsNextClicked(true);
    toggleModal();
  };

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-column justify-content-between gap-3"
    >
      <Navbar />
      <div>
        <button className="interview-guide-button" onClick={() => setRun(true)}>
          {t("guide")}
        </button>
        <div className="interview-qs-container my-3 px-3">
          <Joyride
            continuous
            run={run}
            scrollToFirstStep
            showSkipButton
            steps={finalSteps}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                primaryColor: "red",
                zIndex: 1000,
                showCloseButton: false,
              },
              buttonNext: {
                backgroundColor: "#5cb3cf",
                borderRadius: "30px",
                padding: "12px 20px",
              },
              buttonBack: {
                backgroundColor: "rgb(174, 167, 167)",
                color: "white",
                borderRadius: "30px",
                padding: "12px 20px",
              },
              buttonSkip: {
                backgroundColor: "gray",
                borderRadius: "30px",
                padding: "12px 20px",
                color: "white",
              },
            }}
          />
          <Box className=" p-0">
            <Paper elevation={3} className="interview-card">
              {/* Qs Navbar */}
              <InQsSection1 />

              {/* Interview Qs */}
              <InQsSection2 />

              {/* Pause Resume Next Submit Record Buttons */}
              <InQsSection3 />
            </Paper>
          </Box>

          {recordedAudio && (
            <audio className="audio-tab" controls>
              <source src={recordedAudio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          )}

          {/* Modal Timer for Interview Questions.. bottom*/}
          <div
            className={`modal fade ${modalOpen ? "show" : ""}`}
            id="exampleModal3"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden={!modalOpen}
            style={{
              display: modalOpen ? "block" : "none",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
              style={{ borderRadius: "10px" }}
            >
              <div className="modal-content">
                <div className="modal-body" style={{ textAlign: "center" }}>
                  <div className="" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    ></button>
                  </div>
                  <h5 className="my-3">
                    <i
                      className="fa fa-info-circle fa-2xl my-3"
                      style={{ color: "#61dafb" }}
                      aria-hidden="true"
                    ></i>{" "}
                  </h5>
                  <br />
                  <p>
                    {t("qs-timer-desc")} <br />
                    {t("qs-timer-limit")}
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
}
