import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { UserLogin } from "../../context/AuthContext";
import { AUTH_API_URL } from "../../Auth_API";

export default function OrganizationModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const { showToast, setIsErrorOpen, selectedOrganizationId, setSelectedOrganizationId } =
    UserLogin();

  useEffect(() => {
    setIsModalOpen(true);
    const fetchOrgURL = async () => {
      try {
        const response = await fetch(`${AUTH_API_URL}/get_organization_list/`, { method: "GET" });
        if (response.ok) {
          const data = await response.json();
          setOrganizations(data.organizations);
        } else {
          console.error("Failed to fetch organization data");
        }
      } catch (error) {
        console.error("Error while fetching organization data:", error);
      }
    };
    fetchOrgURL();
  }, []);

  const handleModalClose = () => {
    if (selectedOrganizationId === "") {
      setSelectedOrganizationId("59");
    }
    setIsModalOpen(false);
    showToast(`You will register with CDS.`);
  };

  const onChangeUrl = (event) => {
    setSelectedOrganizationId(event.target.value);
  };

  const handleSubmitOrgani = async (e) => {
    e.preventDefault();
    if (!selectedOrganizationId) {
      setIsErrorOpen(true);
      showToast("Please select an organization.");
      return;
    }
    // localStorage.setItem("organizationUrl", selectedOrganizationId);
    setIsModalOpen(false);
    showToast("You can now register to portal.");
  };

  return (
    <>
      <Dialog open={isModalOpen} onClose={handleModalClose} className="org-modal d">
        <DialogTitle>Welcome to Registration! <br/>Select an organization to continue.</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal" required>
            <Select
              labelId="org-url-select-label"
              id="org-url-select"
              value={selectedOrganizationId}
              onChange={onChangeUrl}
              style={{ borderRadius: "5px" }}
            >
              {organizations.map((org) => (
                <MenuItem key={org.organization_id} value={org.organization_id}>
                  {org.organization_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="col-12  d-flex justify-content-center align-items-center">
          <div className="p-0 d-flex px-3 justify-content-between align-items-center col-12">
            <div className="d-flex align-items-center">
              <button className="login-form-button px-3" onClick={handleModalClose}>
                Cancel
              </button>
            </div>
            <div className="d-flex align-items-center">
              <button className="login-form-button px-3" onClick={handleSubmitOrgani}>
                Submit
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
