import React from "react";
import "./aboutonboardiq.css";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router";

const AboutOnboardiq = () => {
  const navigate = useNavigate();
  return (
    <div className="container-fluid" id="about">
      <Zoom appear>
        <div className=" container overlay-container">
          <div className="overlay-content">
            <h1>About OnboardIQ</h1>
            <p>
              Join us on our mission to revolutionize recruitment and ensure that every hire is not
              just a fit, but a perfect match for your team.
            </p>
            <button  onClick={() => navigate("/register")}>Learn More About OnboardIQ</button>
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default AboutOnboardiq;
