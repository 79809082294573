import React, { useEffect, useState } from "react";
import interview from "../../../assets/img/onbLogoPng.png";
import { UserLogin } from "../../../context/AuthContext";
import { INTERVIEW_IMG_QS, USER_PROFILE_URL } from "../../../Auth_API";
import Progress from "../../Loader/Progress";
import { useNavigate } from "react-router";

function InQsSection2() {
  let navigate = useNavigate();
  const {
    userData,
    isPaused,
    selectedInterview,
    currentQuestionIndex,
    interviewQuestions,
    setInterviewQuestions,
    isLoading,
    isRecordingClicked,
    questionTimers,
    setQuestionTimers,
    showToast,
    setIsErrorOpen,
    formatTimer,
  } = UserLogin();
  const { token } = userData || {};
  const [loadingQuestions, setLoadingQuestions] = useState(true);

  /* Interview Qs fetch from backend */
  useEffect(() => {
    setQuestionTimers({});
    const fetchInterviewQuestions = async () => {
      try {
        const response = await fetch(`${USER_PROFILE_URL}/test_questions/${selectedInterview}/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data, "hvjfkhkgjdf");
          if (data.length === 0) {
            navigate("/InterviewHome");
            showToast(
              "There was no interview questions available fot this test. Please select different test."
            );
            setIsErrorOpen(true);
          } else {
            setInterviewQuestions(data);
            setLoadingQuestions(false);
          }
        } else {
          throw new Error("Failed to fetch interview questions");
        }
      } catch (error) {
        console.error("Error fetching interview questions:", error);
      }
    };
    fetchInterviewQuestions();
  }, [selectedInterview, navigate, token]);

  // Start timer for the current question when currentQuestionIndex changes
  useEffect(() => {
    if (
      currentQuestionIndex >= 0 &&
      currentQuestionIndex < interviewQuestions.length &&
      isRecordingClicked &&
      !isPaused
    ) {
      const intervalId = setInterval(() => {
        setQuestionTimers((prevTimers) => ({
          ...prevTimers,
          [currentQuestionIndex]: (prevTimers[currentQuestionIndex] || 0) + 1,
        }));
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, interviewQuestions, isRecordingClicked, isPaused]);

  useEffect(() => {
    if (!selectedInterview) {
      navigate("/main");
      showToast("You have lost all your recorded questions.");
      setIsErrorOpen(true);
    }
  }, [navigate, selectedInterview]);

  return (
    <>
      {loadingQuestions ? (
        <Progress />
      ) : (
        <div
          className="container my-5"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            className="card interview-warmup-card"
            style={{
              padding: "0px",
            }}
          >
            <div className="mt-4 intrviewqs" style={{ textAlign: "right" }}>
              {questionTimers[currentQuestionIndex] !== undefined && (
                <>{formatTimer(questionTimers[currentQuestionIndex])}</>
              )}
            </div>
            <div className="card-body pb-5 tour-step-three">
              <div style={{ textAlign: "center", padding: "0 20px" }}>
                <img src={interview} alt="interview img" className="mb-2 interview-img-logo" style={{ width: "200px" }} />
                <br />

                {interviewQuestions?.[currentQuestionIndex] && (
                  <div className="d-flex flex-column align-items-center" key={interviewQuestions[currentQuestionIndex].id}>
                    {interviewQuestions[currentQuestionIndex].question_type.toLowerCase() ===
                      "txt" && (
                      <h3 className="mt-3">
                        <b>Title: {interviewQuestions[currentQuestionIndex].text}</b>
                      </h3>
                    )}

                    {interviewQuestions[currentQuestionIndex].question_type.toLowerCase() ===
                      "img" && (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img
                        src={`${INTERVIEW_IMG_QS}/${interviewQuestions[currentQuestionIndex].image_url}`}
                        alt="Image Question"
                        className="interview-qs-image my-4"
                      />
                    )}
                    <p className="m-0 intrviewqs col-sm-10 col-12">
                      <b>Question:</b> {interviewQuestions[currentQuestionIndex].description}
                    </p>
                  </div>
                )}
              </div>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Progress />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InQsSection2;
