import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { AUTH_API_URL } from "../../../Auth_API";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import signupGirl from "../../../assets/img/signup-girl.png";
import signupBlocks from "../../../assets/img/signup-blocks-svg.svg";

export default function FP_Screen2() {
  let navigation = useNavigate();
  const [countdown, setCountdown] = useState(600);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const { isLoading, setIsLoading, error, showToast, setIsErrorOpen } = UserLogin();
  const location = useLocation();
  const { forget_pass_email } = location.state || {};
  console.log(forget_pass_email, "forget_pass_email");

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  // Format the remaining time in MM:SS
  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Function for otp fields
  const digitValidate = (index, value) => {
    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value.replace(/[^0-9]/g, "");
      return newValues;
    });
  };

  const tabChange = (index) => {
    const inputFields = document.querySelectorAll(".otp");
    if (inputFields.length > index) {
      if (inputFields[index - 1].value !== "") {
        inputFields[index].focus();
      } else if (index > 1 && inputFields[index - 1].value === "") {
        inputFields[index - 2].focus();
      }
    }
  };

  /*** Function to handle verify otp ***/
  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await fetch(`${AUTH_API_URL}/verify_otp_forget_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: otpValues.join(""),
          email: forget_pass_email,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        showToast("OTP has been verified. Please set a new secure password.");
        navigation("/reset_password", {
          state: { reset_pass_email: forget_pass_email },
          replace: true,
        });
        setIsLoading(false);
        console.log("OTP message:", data);
      } else {
        console.error("OTP not receive!", error);
        showToast("Failed to verify OTP!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to login!", error);
      showToast("Failed to verify OTP!");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };

  /*** Function to handle resend password ***/
  const handleResendOTP = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${AUTH_API_URL}/initiate_otp_verification/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: forget_pass_email,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        showToast("OTP has been sent to your email!");
        showToast("If you did not receive an OTP, please check your spam!");
        setIsLoading(false);
        setCountdown(600);
        console.log("OTP message:", data);
      } else {
        console.error("OTP not receive!", error);
        showToast("Failed to send OTP!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to login!", error);
      showToast("Failed to send OTP!");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };

  // Function to handle paste event
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (/^[0-9]*$/.test(pastedData) && pastedData.length <= 6) {
      const newValues = Array.from(pastedData.padEnd(6, "0").slice(0, 6));
      setOtpValues(newValues);
    }
  };

  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-md-start align-items-center">
              <div className="col-9 ps-4">
                <img src={onbLogo} alt="logo" className="login-form-logo" />
              </div>
              <div className="login-form-container col-sm-9 col-11 mt-1 shadow-none">
                <div className="login-form-body">
                  <div className="d-flex align-items-center mb-2">
                    <ArrowBack onClick={() => navigation("/")} style={{ cursor: "pointer" }} />
                    <p
                      className="m-0 p-0"
                      onClick={() => navigation("/")}
                      style={{ cursor: "pointer", color: "grey" }}
                    >
                      {" "}
                      Back to log in
                    </p>
                  </div>
                  <h4 className="fw-bold text-capitalize mt-3">Verify your authentication</h4>
                  <p className="m-0 p-0 text-secondary">
                    Enter a code in the app to make sure everything works fine. <br />
                    Please check your email for 6-digits OTP code.
                  </p>
                  <div className="col-md-10 col-12 align-items-md-center align-items-start d-flex gap-2 flex-column mt-2 flex-md-row gap-0">
                    <form className="verify-otp-form d-flex gap-2">
                      {otpValues.slice(0, 3).map((value, index) => (
                        <input
                          key={index}
                          className="otp"
                          name="otp"
                          type="text"
                          value={value}
                          onChange={(e) => digitValidate(index, e.target.value)}
                          onKeyUp={() => tabChange(index + 1)}
                          maxLength={1}
                          onPaste={handlePaste}
                        />
                      ))}
                    </form>
                    <form className="verify-otp-form d-flex gap-2">
                      {otpValues.slice(3, 6).map((value, index) => (
                        <input
                          key={index + 3}
                          className="otp"
                          name="otp"
                          type="text"
                          value={value}
                          onChange={(e) => digitValidate(index + 3, e.target.value)}
                          onKeyUp={() => tabChange(index + 4)}
                          maxLength={1}
                          onPaste={handlePaste}
                        />
                      ))}
                    </form>
                  </div>
                  <p className="mt-3 m-0 d-flex gap-2">
                    Didn't receive code?
                    {countdown > 0 ? (
                      <p className="fw-bold text-dark">Resend code {formatTime()}</p>
                    ) : (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleResendOTP}
                        className="fw-bold text-dark mt-2 m-0 p-0 text-decoration-none"
                      >
                        Resend code
                      </p>
                    )}
                  </p>
                  <button
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    disabled={isLoading}
                    onClick={handleVerify}
                  >
                    Verify now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="login-page-right d-flex align-items-center flex-column  my-3 col-md-5 d-md-block d-none">
            <div className="d-flex col-12 mt-5 flex-column align-items-center">
              <h4 className="text-capitalize text-center text-white fw-bold fs-2 col-10">
                Transform Your Hiring Process With Onboard.IQ{" "}
              </h4>
              <p className="text-white text-center mt-1 col-10">
                Forget the old way of manually going through endless CVs. OnboardIQ transforms your
                hiring process into an enlightening journey of discovery. Our advanced platform
                doesn’t just evaluate; it uncovers the hidden potential in each candidate, ensuring
                you see more than just qualifications you see the future contributors to your
                company’s success.
              </p>
            </div>
            <img
              src={loginSearchImg}
              alt="login-search-img"
              className="login-search-img"
              style={{ opacity: "0" }}
            />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={signupBlocks} alt="polygon" className="signup-blocks" />
            <img src={signupGirl} alt="polygon" className="signup-right-girl" />
          </div>
        </div>
      </div>
    </>
  );
}
