import React, { useEffect, useState } from "react";
import onbLogo from "../../assets/img/onbLogoPng.png";
import polygonTL from "../../assets/img/PolygonTopLeft.svg";
import { UserLogin } from "../../context/AuthContext";

import polygonBL from "../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../assets/img/loginSearchImg.png";
import signupGirl from "../../assets/img/signup-girl.png";
import signupBlocks from "../../assets/img/signup-blocks-svg.svg";
import { useNavigate } from "react-router";

import PhoneInput from "react-phone-input-2";

const RegisterPhone = () => {
  const navigation = useNavigate();
  const { isLoading, setIsLoading, error, showToast, setIsErrorOpen } = UserLogin();
  const [phone, setPhone] = useState("");

  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-md-start align-items-center">
              <div className="col-9 ps-4">
                <img
                  onClick={() => navigation("/")}
                  src={onbLogo}
                  alt="logo"
                  className="login-form-logo"
                />
              </div>
              <div className="login-form-container col-9 mt-1 shadow-none">
                <div className="login-form-body">
                  <h4 className="fw-bold text-capitalize">Enter your phone number</h4>
                  <p className="m-0 p-0 text-secondary">
                    Please enter your phone number to get registered.
                  </p>
                  <hr/>
                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Enter your phone</label>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(value) => setPhone(value)}
                      className="phone_input"
                    />
                  </div>

                  <button
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    // disabled={isLoading}
                    // onClick={handleVerify}
                  >
                    Register phone number
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="login-page-right d-flex align-items-center flex-column  my-3 col-md-5 d-md-block d-none">
            <div className="d-flex col-12 mt-5 flex-column align-items-center">
              <h4 className="text-capitalize text-center text-white fw-bold fs-2 col-10">
                Transform Your Hiring Process With Onboard.IQ{" "}
              </h4>
              <p className="text-white text-center mt-1 col-10">
                Forget the old way of manually going through endless CVs. OnboardIQ transforms your
                hiring process into an enlightening journey of discovery. Our advanced platform
                doesn’t just evaluate; it uncovers the hidden potential in each candidate, ensuring
                you see more than just qualifications you see the future contributors to your
                company’s success.
              </p>
            </div>
            <img
              src={loginSearchImg}
              alt="login-search-img"
              className="login-search-img"
              style={{ opacity: "0" }}
            />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={signupBlocks} alt="polygon" className="signup-blocks" />
            <img src={signupGirl} alt="polygon" className="signup-right-girl" />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPhone;
