import React from "react";

import error from "../../assets/img/404.svg";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function ErrorPage() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div
        className="login-bg"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="error-card card my-5">
          <div className="card-body">
            <div className="resend-email-top">
              <img className="error-img" src={error} alt="resend email" />
            </div>
            <div className="resend-email-second">
              <p className="account-created-first">{t("error-heading")}</p>
            
              <p className="resend-email-description">
                <span className="error-desc-text-two">
                  Oops! Looks like you took a wrong turn. Don't worry; we're here to guide you back on track. We appreciate your patience, and we're excited to get you back on track.
                </span>
              </p>
            </div>

            <form className="margin-container form-container">
              <button className="error-btn" onClick={() => {
                navigate("/");
              }}>
                <span>Take me 🏠</span>
              </button>
              <p className="error-guide mt-5">Need assistance? Our support team is here to help. <br /> Contact us at <a href="/Contact">support@onboardiq.io</a></p>
            </form>
          </div>
        </div>
      </div>

    </>
  );
}

export default ErrorPage;
