import React from 'react';
import ReportSection5_1 from './ReportSection5_1';
import ReportSection5_2 from './ReportSection5_2';
import ReportSection5_3 from './ReportSection5_3';

function ReportSection5() {
    return (
        <>
            {/* Prouncation Scale */}
            < ReportSection5_1 />

            {/* Prouncation Content */}
            < ReportSection5_2 />

            {/* Prouncation Mistakes Table*/}
            <ReportSection5_3 />
        </>
    )
} 

export default ReportSection5