import React,{useEffect} from "react";
import Navbar from "./Navbar/Navbar";
import Hero from "./Hero/Hero";
import HeroSection2 from "./HeroSection2/HeroSection2";
import Features from "./Features/Features";
import Stats from "./Stats/Stats";
import Graph from "./Graphs/Graph";
import PricingPlan from "./PricingPlan/PricingPlan";
import Testimonials from "./Testimonials/Testimonials";
import Demo from "./Demo/Demo";
import FAQs from "./FAQs/FAQs";
import AboutOnboardiq from "./AboutOnboardiq/AboutOnboardiq";
import Footer from "./Footer/Footer";
import { useNavigate } from "react-router";
import { UserLogin } from "../../context/AuthContext";

const MainRoute = () => {
const navigate = useNavigate();
const {setToken } = UserLogin();

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const sessionTime = localStorage.getItem("sessionTime");
    const currentTime = new Date().getTime();

    // Check if session exists and if it's still valid
    if (userToken && sessionTime) {
      const timeElapsed = currentTime - sessionTime;
      if (timeElapsed > 86400000) { // 24 hours in milliseconds
        // Session expired, remove session data
        localStorage.removeItem("token");
        localStorage.removeItem("sessionTime");
        setToken(null);
      } else {
        // Session is valid, redirect to Home or any protected route
        navigate("/");
      }
    }
  }, [navigate,setToken]);

  return (
    <div style={{ background: "#101237" }}>
      <Navbar />
      <Hero />
      <HeroSection2 />
      <Features />
      <Stats />
      <Graph />
      <PricingPlan />
      <Testimonials />
      <Demo />
      <FAQs />
      <AboutOnboardiq />
      <Footer />
    </div>
  );
};

export default MainRoute;
