import React, { useEffect } from "react";
import robot from "../../../../assets/img/robot.png";
import cvImg from "../../../../assets/img/cv-upload.png";
import cvChart from "../../../../assets/img/cvChart.png";
import { Link } from "react-router-dom";
import { UserLogin } from "../../../../context/AuthContext";
import ProgressBar from "../Section2/ProgressBar";
import { useTranslation } from "react-i18next";
import { useTour } from "@reactour/tour";

const Hero = () => {
  const { t } = useTranslation();
  const { stickyNav, barStatus, langChange } = UserLogin();
  const { isOpen, setIsOpen } = useTour();

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    };

    handleBodyScroll();

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div id="hero" className="container-fluid" style={{ marginTop: stickyNav && "6rem" }}>
      <ProgressBar />
      <div className="mt-2 d-flex flex-lg-row flex-column gap-lg-3 gap-md-1 gap-3 px-md-5 px-3 justify-content-center align-items-lg-end align-items-center pt-xl-4 pt-lg-4 pt-md-3 pt-0">
        {/* CV upload Div */}
        <div
          className={`speak-test col-lg-4 col-md-10 col-sm-11 px-4 pt-4 ${
            langChange !== "EN" && "lang-change"
          }`}
          data-tour="1"
        >
          <img src={cvImg} className="cvUpload" alt="" />
          <div className="px-xl-4 px-lg-4 px-md-4 px-sm-2 px-1 py-4 ">
            <h2 className="test-title">{t("hero-upload-cv")}</h2>
            <p className="upload-content mt-3" style={{ fontSize: "16px" }}>
              {t("hero-first-div-p")}{" "}
            </p>
            <div className="d-flex upload-content-btn flex-column col-12 justify-content-between ">
              {barStatus === 1 || barStatus === 3 || barStatus === 2 ? (
                <>
                  <Link
                    className="test-start"
                    style={{ background: "gray", color: "white", cursor: "default" }}
                    disabled
                  >
                    {/* {t("upload-cv-btn")} */}
                    {t("hero-start")}
                  </Link>
                </>
              ) : (
                <>
                  <Link onClick={() => setIsOpen(false)} to="/CVForm" className="test-start">
                    {/* {t("hero-upload-cv-btn")} */}
                    {t("hero-start")}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Speaking Level Test Div */}
        <div
          className={`speak-test col-lg-4 col-md-10 col-sm-11 px-4 pt-4 ${
            langChange !== "EN" && "lang-change"
          }`}
          data-tour="2"
        >
          <img src={cvChart} className="cvUpload" alt="" />

          <div className="px-xl-4 px-lg-4 px-md-4 px-sm-2 px-1 py-4">
            <h2 className="test-title">{t("hero-oral-heading")}</h2>
            <p className="upload-content mt-3" style={{ fontSize: "16px" }}>
              {t("hero-oral-p1")}
            </p>
            <div className="d-flex upload-content-btn flex-column col-12 justify-content-between">
              {barStatus >= 1 ? (
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/interview_org_table"
                  className="test-start"
                >
                  {t("hero-start")}
                </Link>
              ) : (
                <Link
                  className="test-start"
                  style={{ background: "gray", color: "white" }}
                  disabled
                >
                  {t("hero-start")}
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Predictive Index Div */}
        <div
          className={`predictive col-lg-4 col-md-10 col-sm-11 px-4 pt-4 ${
            langChange !== "EN" && "lang-change"
          }`}
          data-tour="3"
        >
          <img src={robot} className="robot" alt="" />

          <div className="px-xl-4 px-lg-4 px-md-4 px-sm-2 px-1 py-4">
            <h2 className="test-title"> {t("hero-pi-heading")} </h2>
            <p className="upload-content mt-3" style={{ fontSize: "16px" }}>
              {t("hero-pi-p")}
            </p>
            <div className="d-flex upload-content-btn flex-column col-12 justify-content-between gap-3">
              {barStatus === 2 ? (
                <Link to="/PISecondScreen" onClick={() => setIsOpen(false)} className="test-start">
                  {t("hero-start")}
                </Link>
              ) : (
                <Link
                  className="test-start"
                  style={{ background: "gray", color: "white", cursor: "default" }}
                  disabled
                >
                  {/* {t("hero-upload-cv-btn")} */}
                  {t("hero-start")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
